import { Button, Grid, Text } from '@farmersdog/corgi-x';
import { Card } from '../../../components';

import * as pagePaths from '../../../constants';

import { EligibleModal } from './EligibleModal';
import { LightboxId, useGlobalLightbox } from '../../../hooks';

import styles from './VetDiscount.module.css';

import DiscountGiftSvg from '../assets/discountGift.svg?react';

import {
  trackClickedActivateDiscount,
  trackClickedDiscountEligibility,
} from '../../../analytics/events';

export interface VetDiscountProps {
  hasDiscountRequests: boolean;
}

export const VetDiscount = ({ hasDiscountRequests }: VetDiscountProps) => {
  const { open } = useGlobalLightbox({
    id: LightboxId.WhoIsEligible,
  });

  const handleEligibleClick = () => {
    open();
    trackClickedDiscountEligibility();
  };

  if (hasDiscountRequests) return null;

  return (
    <>
      <Card className={styles.card}>
        <Grid alignItems="center" className={styles.container}>
          <DiscountGiftSvg className={styles.discountGiftSvg} />

          <Text
            as="p"
            color="white"
            topSpacing="none"
            bottomSpacing="none"
            variant="heading-16"
            className={styles.text}
          >
            You can receive a{' '}
            <strong>free trial box and ongoing 50% discount</strong> for your
            personal subscription to The Farmer’s Dog!{' '}
            <Button
              onClick={handleEligibleClick}
              className={styles.eligible}
              variant="plain-text"
              color="white"
              underline
              weight="normal"
            >
              See who’s eligible
            </Button>
          </Text>

          <Button
            variant="secondary-mini-kale-experimental"
            type="link"
            to={pagePaths.PATH_TRIAL_DISCOUNTS}
            onClick={trackClickedActivateDiscount}
          >
            Activate Discount
          </Button>
        </Grid>
      </Card>
      <EligibleModal />
    </>
  );
};
