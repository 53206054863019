import { useEffect, useState } from 'react';
import type { IndicationsFilterProps } from './IndicationsFilter';
import { IndicationsFilter } from './IndicationsFilter';
import { Button, Callout, Grid, Text } from '@farmersdog/corgi-x';
import { Modal } from '@farmersdog/corgi';
import { LightboxId, useGlobalLightbox } from '../../../hooks';
import styles from './IndicationsFilter.module.css';
import FilterSvg from '../assets/filter.svg?react';
import CalloutExtensionSvg from '../assets/callout-extension.svg?react';

export const IndicationsFilterSmallScreenModal = ({
  indicationsToFilter,
  selectedFilters,
  setSelectedFilters,
}: IndicationsFilterProps) => {
  const [previewSelectedFilters, setPreviewSelectedFilters] =
    useState(selectedFilters);

  useEffect(() => {
    setPreviewSelectedFilters(selectedFilters);
  }, [selectedFilters]);

  const { open: openFiltersModal, close: closeFiltersModal } =
    useGlobalLightbox({
      id: LightboxId.FormulasFilter,
    });

  const handleClose = () => {
    setPreviewSelectedFilters(selectedFilters);
    closeFiltersModal();
  };

  const { rootProps, labelProps } = useGlobalLightbox({
    id: LightboxId.FormulasFilter,
  });

  const handleApplyFilters = () => {
    setSelectedFilters(previewSelectedFilters);
    closeFiltersModal();
  };

  return (
    <Grid>
      {/* @ts-expect-error Types are incorrect */}
      <Modal {...rootProps} className={styles.filtersModal} close={handleClose}>
        <Text
          bold
          as="h3"
          {...labelProps}
          bottomSpacing="md"
          color="blueberry-3"
          variant="heading-22"
          className={styles.modalHeading}
        >
          Filter by clinical & therapeutic indications
        </Text>
        <IndicationsFilter
          isMobile
          indicationsToFilter={indicationsToFilter}
          selectedFilters={previewSelectedFilters}
          setSelectedFilters={setPreviewSelectedFilters}
        />
        <div className={styles.applyFiltersButtonContainer}>
          <Button
            variant="primary-mini-kale-experimental"
            onClick={handleApplyFilters}
            className={styles.applyFiltersButton}
          >
            Apply Filters
          </Button>
        </div>
      </Modal>
      {
        <>
          <Button
            variant="secondary-mini-kale-experimental"
            onClick={() => openFiltersModal()}
            className={styles.selectFiltersButton}
          >
            <Grid
              alignItems={'center'}
              flexDirection="row"
              justifyContent={'space-between'}
            >
              <Text variant="heading-16" bold as="div">
                Select Indication Filters
              </Text>

              <FilterSvg width={20} height={20} />
            </Grid>
          </Button>

          <Callout variant="positive" className={styles.filterCallout}>
            <CalloutExtensionSvg
              width={24}
              height={18}
              className={styles.filterCalloutExtensionSvg}
            />
            <Text variant="heading-12" as="p">
              For allergies and dermatology, gastrointestinal disorders, urinary
              disorders, weight management, wellness, and more.
            </Text>
          </Callout>
        </>
      }
    </Grid>
  );
};
