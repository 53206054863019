import { track } from '../track';
import { set_new_password_after_successful_login } from './eventNames';

/**
 * Send a tracking event for when a user attempts to set up a new password after they have already done so succssfully
 */

export interface TrackSetNewPasswordAfterSuccessfulLoginArgs {
  email: string;
}

export function trackSetNewPasswordAfterSuccessfulLogin({
  email,
}: TrackSetNewPasswordAfterSuccessfulLoginArgs) {
  track({
    eventName: set_new_password_after_successful_login,
    eventProps: {
      email,
    },
  });
}
