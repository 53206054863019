import { Button, Grid, GridItem, Text } from '@farmersdog/corgi-x';
import { Modal } from '@farmersdog/corgi';

import * as pagePaths from '../../constants/pagePaths';

import { useVetDetails } from '../../graphql/hooks/useVetDetails';
import { AccountPage } from '../../components/AccountPage';
import styles from './Account.module.css';
import { AttestationPrompt } from './AttestationPrompt';
import type { ResponsiveProps } from '../../constants';
import { AccountLegacy } from './AccountLegacy';
import { Education } from './Education';
import { Referrals } from './Referrals';
import { Support } from './Support';
import { TeamSupport } from './TeamSupport/TeamSupport';
import { usePublicPageFeatures } from '../../abTesting/PublicPageFeatures';
import { ResourcePreview } from '../Resources/ResourcesContent/ResourcePreview/ResourcePreview';
import { usePdfRedirects } from '../../abTesting/PublicPageFeatures/featureHooks/usePdfRedirects';
import { AccountBanner } from './AccountBanner';
import { DiscountRequested } from './DiscountRequested/DiscountRequested';
import { VetDiscount } from './VetDiscount';
import type { CoreTenetType } from '../../components/CoreTenet';
import { CORE_TENETS, CoreTenetIdentifier } from '../../components/CoreTenet';
import { useCoreTenetModal } from '../../hooks';
import { FormulaOptions } from '../../components/FormulaOptions';
import { CoreTenetGrid } from '../../components/CoreTenet/CoreTenetGrid';
import { trackClickedCoreTenets } from '../../analytics/events';

interface AccountResponsiveProps extends ResponsiveProps {
  onClickCoreTenet: (tenet: CoreTenetType) => void;
}

const getToKnowOurFoodHeadingId = 'get-to-know-our-food-heading';
export const AccountResponsive = ({
  isMobile,
  onClickCoreTenet,
}: AccountResponsiveProps) => {
  const { showPortalUXOverhaul } = usePublicPageFeatures();
  const { isPdfRedirectsActive } = usePdfRedirects();
  const { data, loading } = useVetDetails();
  const { showAllFormulasDiscovery } = usePublicPageFeatures();

  const vetData = data?.vetDetails?.vet;

  const firstName = vetData?.firstName || '';
  const discountRequests = vetData?.discountRequests || [];
  const hasDiscountRequests = discountRequests.length > 0;

  if (!showPortalUXOverhaul) {
    return <AccountLegacy />;
  }
  if (loading) {
    return null;
  }

  if (vetData && !vetData.attestedAtSignup) {
    return <AttestationPrompt vetDetails={data.vetDetails} />;
  }

  return (
    <>
      <AccountPage accountBanner={<AccountBanner />}>
        <Grid>
          <GridItem>
            <Text
              as="p"
              variant={isMobile ? 'article-16' : 'article-20'}
              color="blueberry-3"
              topSpacing="none"
              bottomSpacing="xs"
            >
              Welcome{firstName ? `, ${firstName}!` : '!'}
            </Text>
            <Text
              as="h2"
              variant="heading-40"
              bold
              color="blueberry-3"
              topSpacing="none"
              bottomSpacing="none"
            >
              The Farmer’s Dog Vet Team Portal
            </Text>
          </GridItem>
          {showAllFormulasDiscovery && (
            <>
              <GridItem>
                <Text
                  variant="heading-28"
                  color="blueberry-3"
                  as="h3"
                  bold
                  id={getToKnowOurFoodHeadingId}
                >
                  Get to know our food
                </Text>
                <section aria-labelledby={getToKnowOurFoodHeadingId}>
                  <div className={styles.coreTenets}>
                    {CORE_TENETS.map(tenet => {
                      return (
                        <CoreTenetGrid
                          key={tenet.title}
                          icon={tenet.icon}
                          title={tenet.title}
                          onClick={() => onClickCoreTenet(tenet)}
                        />
                      );
                    })}
                  </div>
                </section>
              </GridItem>
              <GridItem>
                <FormulaOptions isMobile={isMobile} />
              </GridItem>
            </>
          )}
          <Grid
            className={styles.container}
            rowGap="xl"
            topSpacing={hasDiscountRequests ? 'none' : 'md'}
          >
            {hasDiscountRequests && (
              <GridItem className={styles.discountRequested}>
                <DiscountRequested />
              </GridItem>
            )}
            {!hasDiscountRequests && (
              <GridItem>
                <VetDiscount hasDiscountRequests={hasDiscountRequests} />
              </GridItem>
            )}
            {isPdfRedirectsActive && <ResourcePreview isMobile={isMobile} />}
            <GridItem>
              <Education />
            </GridItem>
            <GridItem>
              <Referrals />
            </GridItem>
            <GridItem>
              <Support />
            </GridItem>
            <GridItem>
              <TeamSupport />
            </GridItem>
          </Grid>
        </Grid>
      </AccountPage>
    </>
  );
};

export const Account = () => {
  const {
    onClickCoreTenet: onOpenCoreTenetModal,
    onCloseTenetModal,
    rootProps,
    labelProps,
    selectedTenet,
  } = useCoreTenetModal();

  const onClickTenet = (coreTenet: CoreTenetType) => {
    trackClickedCoreTenets({ title: coreTenet.title });
    onOpenCoreTenetModal(coreTenet);
  };

  return (
    <>
      <div className={styles.mobile}>
        <AccountResponsive isMobile={true} onClickCoreTenet={onClickTenet} />
      </div>
      <div className={styles.desktop}>
        <AccountResponsive isMobile={false} onClickCoreTenet={onClickTenet} />
      </div>
      {/* the modal should not be rendered inside each AccountResponsive component to make sure it is only rendered once */}
      {/* @ts-expect-error Types are incorrect */}
      <Modal {...rootProps} close={onCloseTenetModal}>
        {selectedTenet && (
          <div className={styles.tenetContainer}>
            <div className={styles.tenetIcon}>{selectedTenet.icon}</div>
            <Text
              bold
              as="h3"
              {...labelProps}
              bottomSpacing="sm"
              color="blueberry-3"
              variant="heading-28"
            >
              {selectedTenet.title}
            </Text>
            <Text
              as="p"
              bottomSpacing="md"
              color="charcoal-3"
              variant="heading-16"
            >
              {selectedTenet.description}
            </Text>
            {selectedTenet.id === CoreTenetIdentifier.STAFF && (
              <Button
                onClick={onCloseTenetModal}
                variant="plain-text"
                type="link"
                to={pagePaths.PATH_OUR_TEAM}
                color="kale"
                underline
              >
                Meet Our Team
              </Button>
            )}
          </div>
        )}
      </Modal>
    </>
  );
};
