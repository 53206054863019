import { FormulaType } from '../../constants';
import type { ClinicalFormula } from '../../graphql/types';

export function formatDisplayName(formula: ClinicalFormula): {
  formattedDisplayName: string;
  appendix: string;
} {
  const displayName = formula.basicInfo?.displayName;
  const type = formula.type;

  let formattedDisplayName = displayName;
  let appendix = '';

  if (!formattedDisplayName) {
    return { formattedDisplayName: '', appendix };
  }

  if (type === FormulaType.DIY) {
    const recipeInfo = formula.basicInfo?.recipe;
    formattedDisplayName = formattedDisplayName.replace(/low fat/i, '');

    if (recipeInfo && !recipeInfo.isPuppy) {
      if (!recipeInfo.isLowFat) {
        appendix = 'Adults';
      }
    }
  }

  return { formattedDisplayName, appendix };
}
