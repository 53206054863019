import { Redirect, useLocation } from 'react-router-dom';
import type { FC } from 'react';
import { vanityUrls } from '../../constants/vanityUrls';

export const VanityUrl: FC = () => {
  const vanityRouteDerived = useLocation().pathname;

  const vanityObj = vanityUrls.find(
    ({ vanityRoute }) => vanityRoute === vanityRouteDerived
  );

  if (!vanityObj) {
    throw new Error(`Vanity URL not found for ${vanityRouteDerived}`);
  }

  const { redirectsTo } = vanityObj;

  return <Redirect to={redirectsTo} />;
};
