import { track } from '../track';
import { clicked_see_formulas_details } from './eventNames';

/**
 * Send a tracking event for when a user clicks on see details on formula card.
 */

interface TrackClickedSeeFormulasDetailsValues {
  formulaName: string;
  view: 'list' | 'tile';
}

export function trackClickedSeeFormulasDetails({
  formulaName,
  view,
}: TrackClickedSeeFormulasDetailsValues) {
  track({
    eventName: clicked_see_formulas_details,
    eventProps: {
      formulaName,
      view,
    },
  });
}
