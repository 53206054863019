import { Text } from '@farmersdog/corgi-x';
import styles from './CoreTenetGrid.module.css';
import type { ReactElement } from 'react';
import ChevronSvg from '../../assets/icons/chevron.svg?react';

export interface CoreTenetGridProps {
  icon: ReactElement;
  title: string;
  onClick: () => void;
}
export function CoreTenetGrid({ title, icon, onClick }: CoreTenetGridProps) {
  return (
    <div className={styles.container} onClick={onClick} role="button">
      <div className={styles.svgContainerExtraSmall}>{icon}</div>
      <div className={styles.titleContainer}>
        <Text
          variant="heading-16"
          bold
          color="Charcoal3"
          as="h4"
          topSpacing="sm"
          bottomSpacing="none"
        >
          {title}
        </Text>
        <ChevronSvg />
      </div>
    </div>
  );
}
