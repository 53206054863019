export type IndicationCategoriesMap = {
  [key: string]: string[];
};

const allergiesAndDermatology = [
  'Adverse Food Reaction',
  'Atopy',
  'Novel Protein',
];

const gastrointestinalDisorders = [
  'Acute Colitis',
  'Chronic Colitis',
  'Acute Gastroenteritis',
  'Chronic Gastroenteritis',
  'IBD/Elimination',
];

const urinaryDisorders = [
  'Calcium Oxalate Control',
  'Struvite Control',
  'Struvite Dissolution',
];

const weightManagement = ['Weight Gain', 'Weight Loss'];

const wellness = ['Adult', 'Puppy'];

const other = [
  'Anorexia/Inappetence',
  'Cancer',
  'Cardiac Support',
  'Fat Intolerance',
];

export const indicationCategoriesMap: IndicationCategoriesMap = {
  'Allergies & Dermatology': allergiesAndDermatology,
  'Gastrointestinal Disorders': gastrointestinalDisorders,
  'Urinary Disorders': urinaryDisorders,
  'Weight Management': weightManagement,
  Wellness: wellness,
  Other: other,
};

export const indicationFilters = [
  ...allergiesAndDermatology,
  ...gastrointestinalDisorders,
  ...urinaryDisorders,
  ...weightManagement,
  ...wellness,
  ...other,
];
