import { Grid, Text } from '@farmersdog/corgi-x';
import { FormulaType } from '../../../constants';
import { InstructionCard } from './InstructionCard';
import { diy, fresh } from './assets';
import styles from './HowItWorks.module.css';
interface HowItWorksProps {
  formulaType: FormulaType;
}

const diyData = [
  {
    image: diy.step1,
    title: 'Nutrient Mix Delivery',
    step: 1,
    content:
      'We deliver a box of nutrient-mix packets to your client’s home. Each batch of food calls for 1 packet.',
  },
  {
    image: diy.step2,
    title: 'Recipe Access',
    step: 2,
    content:
      'Your client gets free access to complete and balanced recipes developed by board-certified nutritionists.',
  },
  {
    image: diy.step3,
    title: 'Ingredient Shopping',
    step: 3,
    content:
      'Your client shops for recipe ingredients according to recommended portions for  their dog’s profile (including weight and activity level).',
  },
  {
    image: diy.step4,
    title: 'Cooking',
    step: 4,
    content:
      'Your client cooks the recipe and stirs in a nutrient-mix packet before serving or storing.',
  },
];

const freshData = [
  {
    image: fresh.step1,
    title: 'Custom Portions',
    step: 1,
    content:
      'Portions are customized to the patient, using factors like weight and activity level.',
  },
  {
    image: fresh.step2,
    title: 'Home Delivery',
    step: 2,
    content:
      'Orders arrive frozen on a recurring schedule that works for your client.',
  },
  {
    image: fresh.step3,
    title: 'Easy Serving',
    step: 3,
    content:
      'Your client serves complete and balanced, ready-to-eat meals in seconds.',
  },
  {
    image: fresh.step4,
    title: '24/7 Support ',
    step: 4,
    content:
      'Your clients can reach us anytime by phone, text, or email with any questions or concerns.',
  },
];
export const HowItWorks = ({ formulaType }: HowItWorksProps) => {
  const isDIY = formulaType === FormulaType.DIY;
  const howItWorksData = isDIY ? diyData : freshData;

  const itemsToShow = howItWorksData.map(item => (
    <InstructionCard
      step={item.step}
      key={item.step}
      sources={item.image}
      alt={item.title}
      title={item.title}
      description={item.content}
    />
  ));
  return (
    <div>
      <Text
        variant="heading-28"
        color="Blueberry3"
        bold
        as="h2"
        topSpacing="none"
        bottomSpacing="lg"
      >
        How it works for your client
      </Text>
      <Grid className={styles.items}>{itemsToShow}</Grid>
    </div>
  );
};
