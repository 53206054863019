import type { ReactNode } from 'react';
import { Fragment } from 'react';
import {
  useLoggedOutPage,
  useSignUpBanner,
  useTermsChangesBanner,
  useVetPortalBanner,
  usePortalUXOverhaul,
  useConferenceSignUpPasswordRevamp,
  useFormulasDiscovery,
} from './featureHooks';
import { PublicPageFeaturesContext } from './PublicPageFeaturesContext';

interface PublicPageFeaturesProvider {
  children: ReactNode;
}

export function PublicPageFeaturesProvider({
  children,
}: PublicPageFeaturesProvider) {
  const { showPortalBanner, bannerConfig } = useVetPortalBanner();
  const { showSignUpBanner } = useSignUpBanner();
  const { showTermsChangesBanner } = useTermsChangesBanner();
  const { showImprovedLoggedOutPage } = useLoggedOutPage();
  const { showPortalUXOverhaul } = usePortalUXOverhaul();
  const { showConferenceSignUpPasswordRevamp } =
    useConferenceSignUpPasswordRevamp();
  const {
    showAllFormulasDiscovery,
    showFormulasDiscoveryPagesOnly,
    formulasDiscoverySplitLoaded,
  } = useFormulasDiscovery();

  return (
    // Use a key to force a re-render of children when the  value changes
    <Fragment
      key={String(
        `${showPortalBanner}${showTermsChangesBanner}${showAllFormulasDiscovery}${showFormulasDiscoveryPagesOnly}${formulasDiscoverySplitLoaded}`
      )}
    >
      <PublicPageFeaturesContext.Provider
        value={{
          showPortalBanner,
          bannerConfig,
          showSignUpBanner,
          showTermsChangesBanner,
          showImprovedLoggedOutPage,
          showPortalUXOverhaul,
          showConferenceSignUpPasswordRevamp,
          showAllFormulasDiscovery,
          showFormulasDiscoveryPagesOnly,
          formulasDiscoverySplitLoaded,
        }}
      >
        {children}
      </PublicPageFeaturesContext.Provider>
    </Fragment>
  );
}
