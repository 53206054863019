import { Grid, Text } from '@farmersdog/corgi-x';
import styles from './CalculatedCalorieContent.module.css';

interface CalculatedCalorieContentProps {
  children: string;
}

export const CalculatedCalorieContent = ({
  children,
}: CalculatedCalorieContentProps) => (
  <Grid flexDirection="column">
    <Text variant="heading-22" bold as="h3" spacing="none">
      Calculated Calorie Content
    </Text>
    <Text variant="heading-16" topSpacing="md" className={styles.content}>
      {children}
    </Text>
  </Grid>
);
