import { useMemo } from 'react';

import { Modal } from '@farmersdog/corgi';
import { Text, Button, Grid, GridItem } from '@farmersdog/corgi-x';
import { FormulaType } from '../../constants';
import { FormulaCard } from '../FormulaCard';
import { useScreenSizeContext } from '../../context/ScreenSizeContext';
import {
  CoreTenet,
  CORE_TENETS,
  CoreTenetIdentifier,
} from '../CoreTenet/CoreTenet';
import { useCoreTenetModal } from '../../hooks';
import * as pagePaths from '../../constants/pagePaths';
import styles from './OtherFormulas.module.css';
import type { ClinicalFormula } from '../../graphql/types';
import {
  trackClickedCoreTenets,
  trackDownloadableInfo,
} from '../../analytics/events';
import type { CoreTenetType } from '../../components/CoreTenet/CoreTenet';
import VeterinaryGuide from '../../../static/pdf/VeterinaryGuide.pdf';
import PdfSvg from './assets/pdf.svg?react';
import { BROWSE_FORMULAS_ID } from '../../pages/Formulas';
import ChevronSvg from '../../assets/icons/chevron.svg?react';

interface OtherFormulasProps {
  formulaType: string;
  otherFormulas: ClinicalFormula[];
}

const FORMULA_SPECIFICS: Record<string, { headingTitle: string }> = {
  [FormulaType.Fresh]: {
    headingTitle: 'fresh',
  },
  [FormulaType.Treats]: {
    headingTitle: 'treats',
  },
  [FormulaType.DIY]: {
    headingTitle: 'nutrient mix',
  },
};

const otherFormulasHeadingId = 'other-formulas-section';
const aboutOurFormulasHeadingId = 'about-our-formulas-section';

const MAX_OTHER_FORMULAS = 2;

export function OtherFormulas({
  otherFormulas,
  formulaType,
}: OtherFormulasProps) {
  const { isMobile } = useScreenSizeContext();

  const {
    onClickCoreTenet: onOpenCoreTenetModal,
    onCloseTenetModal,
    rootProps,
    labelProps,
    selectedTenet,
  } = useCoreTenetModal();

  const otherFormulasShuffled = useMemo(() => {
    return [...otherFormulas]
      .sort(() => 0.5 - Math.random())
      .slice(0, MAX_OTHER_FORMULAS);
    // We don't want to re-shuffled on every re-render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [otherFormulas]);

  const onClickTenet = (coreTenet: CoreTenetType) => {
    trackClickedCoreTenets({ title: coreTenet.title });
    onOpenCoreTenetModal(coreTenet);
  };

  return (
    <>
      <div>
        <section aria-labelledby={otherFormulasHeadingId}>
          <div className={styles.heading}>
            <Text
              color="Blueberry3"
              variant="heading-28"
              bold
              as="h3"
              id={otherFormulasHeadingId}
            >
              Other {FORMULA_SPECIFICS[formulaType].headingTitle} formulas
            </Text>
            {!isMobile && <BrowseMoreOptions />}
          </div>
          <div className={styles.otherFormulaCardsContainer}>
            {otherFormulasShuffled.map(formula => {
              if (!formula) {
                return null;
              }
              return (
                <div
                  key={formula.name}
                  className={styles.otherFormulaCardContainer}
                >
                  <FormulaCard formula={formula} />
                </div>
              );
            })}
          </div>
          {isMobile && <BrowseMoreOptions />}
        </section>
        <section aria-labelledby={aboutOurFormulasHeadingId}>
          <Grid rowGap="md" className={styles.aboutOurFormulas}>
            <GridItem className={styles.heading}>
              <Text
                color="Blueberry3"
                variant="heading-28"
                bold
                as="h3"
                id={aboutOurFormulasHeadingId}
              >
                About our formulas
              </Text>
              {!isMobile && <ViewVeterinaryGuide />}
            </GridItem>
            <div className={styles.tenetsContainer}>
              {CORE_TENETS.map(coreTenet => {
                const { title, icon } = coreTenet;
                return (
                  <CoreTenet
                    shouldShowIcon={true}
                    icon={icon}
                    title={title}
                    key={title}
                    onClick={() => onClickTenet(coreTenet)}
                    titleVariant="heading-16"
                    headingLevel="h4"
                    arrowSize={20}
                  />
                );
              })}
            </div>
          </Grid>
          {isMobile && <ViewVeterinaryGuide />}
        </section>
      </div>
      {/* @ts-expect-error Types are incorrect */}
      <Modal
        {...rootProps}
        className={isMobile ? styles.tenetModal : undefined}
        close={onCloseTenetModal}
      >
        {selectedTenet && (
          <div className={styles.tenetContainer}>
            <div className={styles.tenetIcon}>{selectedTenet.icon}</div>
            <Text
              bold
              as="h3"
              {...labelProps}
              bottomSpacing="sm"
              color="blueberry-3"
              variant="heading-28"
              className={styles.modalHeading}
            >
              {selectedTenet.title}
            </Text>
            <Text
              as="p"
              bottomSpacing="md"
              color="charcoal-3"
              variant="heading-16"
              className={styles.modalHeading}
            >
              {selectedTenet.description}
            </Text>
            {selectedTenet.id === CoreTenetIdentifier.STAFF && (
              <Button
                onClick={onCloseTenetModal}
                variant="plain-text"
                type="link"
                to={pagePaths.PATH_OUR_TEAM}
                color="kale"
                underline
              >
                Meet Our Team
              </Button>
            )}
          </div>
        )}
      </Modal>
    </>
  );
}

function BrowseMoreOptions() {
  return (
    <Button
      variant="plain-text"
      type="link"
      to={{
        pathname: pagePaths.PATH_FORMULAS,
        hash: BROWSE_FORMULAS_ID,
      }}
      color="kale"
      weight="bold"
      underline
      className={styles.browseMoreOptionsBottom}
    >
      <Text variant="heading-16" bold>
        Browse More Options
      </Text>
      <ChevronSvg height={20} />
    </Button>
  );
}

function ViewVeterinaryGuide() {
  return (
    <Button
      variant="plain-text"
      color="kale"
      underline
      type="anchor"
      className={styles.guide}
      href={VeterinaryGuide}
      target="_blank"
      onClick={() => trackDownloadableInfo({ title: '2025 Veterinary Guide' })}
    >
      <Text variant="heading-16" bold>
        View Veterinary Guide
      </Text>
      <PdfSvg className={styles.svg} />
    </Button>
  );
}
