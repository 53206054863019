import { Button, Text } from '@farmersdog/corgi-x';
import * as pagePaths from '../../../constants';

export const PasswordAlreadySetMessage = (
  <Text>
    You have already set a new password. Please log in with your new password
    <Button
      weight="normal"
      type="link"
      to={pagePaths.PATH_LOGIN}
      variant="plain-text"
      color="kale"
      underline
    >
      here
    </Button>
  </Text>
);
