import { AppNav, Text, Logo, Grid, GridItem } from '@farmersdog/corgi-x';
import { navigationLeftLinks, navigationRightLinks } from './navigation-items';

import styles from './AccountHeader.module.css';
import type { DiscountRequest } from '../../graphql/types';
import classNames from 'classnames';
import { useRouteMatch } from 'react-router';
import { PATH_DASHBOARD } from '../../constants';
import { usePublicPageFeatures } from '../../abTesting/PublicPageFeatures';

interface AccountHeaderProps {
  discountRequests: DiscountRequest[];
  showAttestationPrompt?: boolean;
  isMobile?: boolean;
}

export function AccountHeader({
  discountRequests,
  showAttestationPrompt = false,
  isMobile,
}: AccountHeaderProps) {
  const route = useRouteMatch();
  const { showAllFormulasDiscovery } = usePublicPageFeatures();
  return (
    <>
      <div
        className={classNames(
          styles.navContainer,
          showAttestationPrompt ? styles.hideMenuButton : ''
        )}
      >
        <AppNav
          {...navigationLeftLinks({
            noLeftitems: showAttestationPrompt,
            discountRequests,
            currentPath: route.path,
            showAllFormulasDiscovery,
          })}
          className={styles.nav}
          {...navigationRightLinks({
            discountRequests,
            isMobile,
          })}
          logoTo={PATH_DASHBOARD}
          logo={<VetTeamPortalLogo />}
        />
      </div>
    </>
  );
}

const VetTeamPortalLogo = () => (
  <Grid alignItems="center" className={styles.logoContainer}>
    <GridItem xs={5}>
      <Logo size="48" variant="product" className={styles.logo} />
    </GridItem>
    <GridItem xs={7} growContent>
      <Text
        variant="heading-12"
        className={styles.title}
        color="blueberry-3"
        bold
      >
        Vet Team <br />
        Portal
      </Text>
    </GridItem>
  </Grid>
);
