import {
  type NativeWistiaOptions,
  Text,
  VideoSection,
} from '@farmersdog/corgi-x';

import styles from './SampleOwnerInstructions.module.css';

const VIDEO_ID = '1c6j1p8suy';
const defaultWistiaOptions: NativeWistiaOptions = {
  autoPlay: false,
  controlsVisibleOnLoad: true,
  fitStrategy: 'cover',
  popover: true,
  playsinline: true,
  playbar: true,
  playButton: true,
  settingsControl: true,
  videoFoam: true,
  thumbnailAltText: 'Sample owner instructions',
};

export const SampleOwnerInstructions = () => {
  return (
    <div>
      <Text
        variant="heading-28"
        color="Blueberry3"
        bold
        as="h2"
        topSpacing="none"
        bottomSpacing="sm"
      >
        Sample owner instructions
      </Text>

      <Text variant="heading-16" as="p" topSpacing="none" bottomSpacing="lg">
        <strong>Estimated cook time: 40 mins.</strong> We tailor ingredient
        quantities and cooking instructions for this recipe to each dog’s
        caloric needs. Those specifics can be found in the customer’s account.
      </Text>

      <VideoSection
        className={styles.videoClass}
        wistiaOptions={defaultWistiaOptions}
        wistiaId={VIDEO_ID}
      />
    </div>
  );
};
