import { track } from '../track';
import { clicked_recommend_to_client } from './eventNames';

/**
 * Send a tracking event for when a user clicks on Recommended to Client button
 */

export function trackRecommendToClientCTA() {
  track({
    eventName: clicked_recommend_to_client,
  });
}
