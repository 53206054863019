import { Text, Button } from '@farmersdog/corgi-x';
import styles from './FormulaOptions.module.css';
import * as paths from '../../constants/pagePaths';
import DogNextToBoxSvg from './assets/dog_next_to_box_outlined.svg?react';
import type { ResponsiveProps } from '../../constants';
import {
  trackClickedBrowseFormulas,
  trackDownloadableInfo,
} from '../../analytics/events';
import VeterinaryGuide from '../../../static/pdf/VeterinaryGuide.pdf';

const formulaOptionsHeadingId = 'formula-options';

export function FormulaOptions({ isMobile }: ResponsiveProps) {
  const onClickViewVeterinaryGuide = () => {
    trackDownloadableInfo({ title: '2025 Veterinary Guide' });
  };

  return (
    <section
      className={styles.container}
      aria-labelledby={formulaOptionsHeadingId}
    >
      <div className={styles.left}>
        <Text
          variant="heading-22"
          color="Blueberry3"
          bold
          as="h4"
          topSpacing="none"
          bottomSpacing="xs"
          id={formulaOptionsHeadingId}
        >
          Formula Options
        </Text>
        <Text
          variant="article-16"
          color="Charcoal3"
          as="p"
          topSpacing="none"
          bottomSpacing="md"
        >
          Get our comprehensive veterinary guide, clinical resources, and
          research, plus relevant literature from other experts.
        </Text>
        <div className={styles.linksAndIcon}>
          <div className={styles.links}>
            <Button
              type="link"
              to={paths.PATH_FORMULAS}
              onClick={trackClickedBrowseFormulas}
              variant="primary-mini-kale-experimental"
              className={styles.browseFormulasButton}
            >
              Browse Formulas
            </Button>
            <Button
              variant="plain-text"
              color="kale"
              underline
              type="anchor"
              className={styles.guide}
              href={VeterinaryGuide}
              target="_blank"
              onClick={onClickViewVeterinaryGuide}
            >
              <Text variant="heading-16" bold>
                View Veterinary Guide
              </Text>
            </Button>
          </div>
          {isMobile && <DogNextToBoxSvg className={styles.dogNextToBox} />}
        </div>
      </div>
      {!isMobile && <DogNextToBoxSvg className={styles.dogNextToBox} />}
    </section>
  );
}
