import { AccordionPanel, Text } from '@farmersdog/corgi-x';
import { type NutritionData } from '../../../../graphql/types';
import { NutritionTable } from '../../NutritionTable';
import { useMemo } from 'react';
import { transformData } from './utils/transformData';

interface RSSValuesPanelProps {
  nutritionData: NutritionData[];
  onToggle: (expanded: boolean) => void;
}

export const RSSValuesPanel = ({
  nutritionData,
  onToggle,
}: RSSValuesPanelProps) => {
  const normalizedData = useMemo(
    () => transformData(nutritionData),
    [nutritionData]
  );
  return (
    <AccordionPanel
      id="rss-values"
      expanded
      onToggle={onToggle}
      heading={
        <Text variant="heading-22" bold>
          RSS values
        </Text>
      }
    >
      <NutritionTable rows={normalizedData} />
    </AccordionPanel>
  );
};
