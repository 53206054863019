type ErrorWithNameAndMessage = {
  name: string;
  message: string;
};

export function isErrorWithNameAndMessage(
  error: unknown
): error is ErrorWithNameAndMessage {
  return Boolean(
    error &&
      Object.prototype.hasOwnProperty.call(error, 'name') &&
      Object.prototype.hasOwnProperty.call(error, 'message')
  );
}
