import { useClinicalFormulas } from '../../graphql/hooks';
import { AccountPage } from '../../components/AccountPage';
import { OtherFormulas } from '../../components/OtherFormulas';
import { useQueryParameters } from '../../hooks';
import { ActivityIndicator, Grid, PageWidth } from '@farmersdog/corgi-x';
import { Breadcrumb } from './Breadcrumb';
import { HeroImages } from './HeroImages';
import { Redirect } from 'react-router-dom';
import * as pagePaths from '../../constants/pagePaths';
import { useMemo } from 'react';
import styles from './FormulaDetails.module.css';
import { Fresh } from './Fresh';
import { type ClinicalFormula } from '../../graphql/types';
import { FormulaType } from '../../constants';
import { HowItWorks } from './HowItWorks';
import { DIY } from './DIY';
import { Treat } from './Treat';
import { WhyVetsLove } from './WhyVetsLove';
import { SampleOwnerInstructions } from './SampleOwnerInstructions';
import { MoreDetails } from './MoreDetails';
import { ShipToClientInfoBanner } from '../../components/ShipToClientInfoBanner';
import { usePublicPageFeatures } from '../../abTesting/PublicPageFeatures';

export const FormulaDetails = () => {
  const {
    showFormulasDiscoveryPagesOnly,
    showAllFormulasDiscovery,
    formulasDiscoverySplitLoaded,
  } = usePublicPageFeatures();

  const { data, loading } = useClinicalFormulas();
  const query = useQueryParameters();
  const formula = query.get('formula');

  const formulaData = useMemo(() => {
    const result = data?.clinicalFormulas?.formulas?.find(
      formulaItem => formulaItem?.name === formula
    );
    return result ? (result as ClinicalFormula) : null;
  }, [data, formula]);

  if (!formulasDiscoverySplitLoaded) {
    return (
      <AccountPage fullWidth backgroundOverride="white">
        <Grid alignItems="center" justifyContent="center">
          <ActivityIndicator mode="dark" />
        </Grid>
      </AccountPage>
    );
  }

  if (!showFormulasDiscoveryPagesOnly && !showAllFormulasDiscovery) {
    return <Redirect to={pagePaths.PATH_DASHBOARD} />;
  }

  const otherFormulas = (data?.clinicalFormulas?.formulas?.filter(
    formulaItem =>
      formulaItem?.name !== formula && formulaItem?.type === formulaData?.type
  ) ?? []) as ClinicalFormula[];

  if (loading) {
    return (
      <AccountPage fullWidth backgroundOverride="white">
        <Grid alignItems="center" justifyContent="center">
          <ActivityIndicator mode="dark" />
        </Grid>
      </AccountPage>
    );
  }

  if (!formula || !formulaData) {
    return (
      <Redirect
        to={{
          pathname: pagePaths.PATH_NOT_FOUND,
          state: { from: location },
        }}
      />
    );
  }

  return (
    <AccountPage
      fullWidth
      skipPageWidthConstraint
      backgroundOverride="white"
      skipBottomPadding
    >
      <PageWidth>
        <Grid
          topSpacing="md"
          className={styles.container}
          rowGap="xl"
          bottomSpacing={{ xs: 'xl', lg: 'xxl' }}
        >
          <div className={styles.fixedSection}>
            <div className={styles.hero}>
              <Breadcrumb
                formulaName={formulaData.basicInfo?.displayName ?? ''}
              />
              <HeroImages
                formula={formula}
                displayName={formulaData.basicInfo?.displayName ?? ''}
                type={formulaData.type}
              />
            </div>
            <div className={styles.details}>
              {formulaData.type === FormulaType.Fresh && (
                <Fresh {...formulaData} />
              )}
              {formulaData.type === FormulaType.DIY && <DIY {...formulaData} />}
              {formulaData.type === FormulaType.Treats && (
                <Treat {...formulaData} />
              )}
            </div>
          </div>
          {formulaData.type !== FormulaType.Treats && (
            <HowItWorks formulaType={formulaData.type as FormulaType} />
          )}
          <WhyVetsLove formulaType={formulaData.type as FormulaType} />
          {formulaData.type === FormulaType.DIY && <SampleOwnerInstructions />}
          <MoreDetails clinicalNutrition={formulaData.clinicalNutrition} />
        </Grid>
      </PageWidth>
      <div className={styles.otherFormulasContainer}>
        <PageWidth>
          <div className={styles.container}>
            <OtherFormulas
              otherFormulas={otherFormulas}
              formulaType={formulaData.type}
            />
          </div>
        </PageWidth>
      </div>
      <ShipToClientInfoBanner />
    </AccountPage>
  );
};
