import { Grid, GridItem, Text, Picture, Button } from '@farmersdog/corgi-x';
import styles from './TopSection.module.css';
import TfdPackSvg from '../assets/tfd-pack.svg?react';
import CookingPotSvg from '../assets/cooking-pot.svg?react';
import { useScreenSizeContext } from '../../../context/ScreenSizeContext';
import GreenArrow from '../assets/green-arrow.svg?react';
import { DescriptionCardsSources } from '../assets/images/descriptionCards/descriptionCardsSources';
import {
  freshHeadingId,
  diyHeadingId,
} from '../../../components/FilteredFormulas';

interface CardButtonProps {
  text: string;
  anchorId: string;
}

const CardButton = ({ text, anchorId }: CardButtonProps) => (
  <Button
    variant="plain-text"
    color="kale"
    underline
    type="anchor"
    href={`#${anchorId}`}
    className={styles.cardButton}
  >
    {text}
    <GreenArrow />
  </Button>
);

const cards = [
  {
    title: 'Ready-to-eat formulas',
    description:
      'We prepare, cook, and freeze meals in custom portions, then deliver them in eco-friendly, refrigerated boxes. Low-calorie treats can be included in the same package.',
    Svg: TfdPackSvg,
    cta: 'Browse Ready-to-Eat',
    sources: DescriptionCardsSources.readyToEat,
    anchorId: freshHeadingId,
    alt: 'The Farmer’s Dog ready-to-eat formulas',
  },
  {
    title: 'Home-cooking formulas',
    description:
      'We ship the nutrient mix to your clients, who access recipes in their account. They shop for the other ingredients, cook, then add the mix before serving or storing.',
    Svg: CookingPotSvg,
    cta: 'Browse Home-Cooking',
    sources: DescriptionCardsSources.homeCooking,
    anchorId: diyHeadingId,
    alt: 'The Farmer’s Dog nutrient mix',
  },
];
export const DescriptionCards = () => {
  const { isMobile } = useScreenSizeContext();
  const renderLargeScreenCards = cards.map(
    ({ title, description, cta, sources, anchorId, alt }) => (
      <GridItem md={6} key={title}>
        <div className={styles.card}>
          <Grid>
            <GridItem className={styles.cardTextContainer} xs={8}>
              <Text
                variant="heading-22"
                bold
                bottomSpacing="sm"
                as="h3"
                topSpacing="none"
              >
                {title}
              </Text>
              <Text variant="heading-16" as="p">
                {description}
              </Text>
              <CardButton anchorId={anchorId} text={cta} />
            </GridItem>
            <GridItem className={styles.cardImageContainer} xs={4}>
              <Picture sources={sources.lg} alt={alt} />
            </GridItem>
          </Grid>
        </div>
      </GridItem>
    )
  );

  const renderSmallScreenCards = cards.map(
    ({ title, description, cta, sources, anchorId, alt }) => (
      <GridItem xs={12} key={title}>
        <div className={styles.card}>
          <Picture sources={sources.sm} alt={alt} />
          <div className={styles.cardTextContainer}>
            <Text
              variant="heading-22"
              bold
              as="h3"
              bottomSpacing="sm"
              topSpacing="none"
            >
              {title}
            </Text>
            <Text variant="heading-16" as="p">
              {description}
            </Text>
            <CardButton text={cta} anchorId={anchorId} />
          </div>
        </div>
      </GridItem>
    )
  );
  return (
    <Grid alignItems="stretch" bottomSpacing="xl" gap="md">
      {isMobile ? renderSmallScreenCards : renderLargeScreenCards}
    </Grid>
  );
};
