import Step1Webp from './step_1.webp';
import Step1Jp2 from './step_1.jp2';
import Step1Png from './step_1.png';
import Step2Webp from './step_2.webp';
import Step2Jp2 from './step_2.jp2';
import Step2Png from './step_2.png';
import Step3Webp from './step_3.webp';
import Step3Jp2 from './step_3.jp2';
import Step3Png from './step_3.png';
import Step4Webp from './step_4.webp';
import Step4Jp2 from './step_4.jp2';
import Step4Png from './step_4.png';

export const fresh = {
  step1: [Step1Webp, Step1Jp2, Step1Png],
  step2: [Step2Webp, Step2Jp2, Step2Png],
  step3: [Step3Webp, Step3Jp2, Step3Png],
  step4: [Step4Webp, Step4Jp2, Step4Png],
};
