import { track } from '../track';
import { set_up_new_password_to_log_in } from './eventNames';

/**
 * Send a tracking event for when a user successfully sets up a new password to log in after conference signup
 */

export interface TrackSetUpNewPasswordToLogInArgs {
  email: string;
}

export function trackSetUpNewPasswordToLogIn({
  email,
}: TrackSetUpNewPasswordToLogInArgs) {
  track({
    eventName: set_up_new_password_to_log_in,
    eventProps: {
      email,
    },
  });
}
