import type { TextVariant } from '@farmersdog/corgi-x';
import { Text } from '@farmersdog/corgi-x';

import { useScreenSizeContext } from '../../context/ScreenSizeContext';

import styles from './CoreTenet.module.css';

import StethoscopeSvg from '../../assets/icons/stethoscope.svg?react';
import ChevronSvg from '../../assets/icons/chevron.svg?react';
import CheckmarkSvg from '../../assets/icons/checkmark.svg?react';
import CookingSvg from '../../assets/icons/cooking.svg?react';
import BadgeSvg from '../../assets/icons/badge.svg?react';
import type { ReactElement } from 'react';
import classNames from 'classnames';

export enum CoreTenetIdentifier {
  STAFF = 'STAFF',
  FACILITIES = 'FACILITIES',
  COOKED = 'COOKED',
  COMPLIANT = 'COMPLIANT',
}

export interface CoreTenetType {
  id: CoreTenetIdentifier;
  title: string;
  description: string;
  icon: ReactElement;
}

export const STAFF_TENET_TITLE =
  'Developed by on-staff, board-certified nutritionists';

export const CORE_TENETS: CoreTenetType[] = [
  {
    id: CoreTenetIdentifier.STAFF,
    title: STAFF_TENET_TITLE,
    icon: <StethoscopeSvg />,
    description:
      'Our formulas are rooted in decades of nutrition science and the expertise of board-certified nutritionists, who have informed every decision and every product we’ve made. From the start, our food has been developed by board-certified nutritionists to offer dogs a complete and balanced diet—in the form of fresh, whole, minimally processed food.',
  },
  {
    id: CoreTenetIdentifier.FACILITIES,
    title: 'Made and tested in FDA- and USDA-compliant facilities',
    icon: <CheckmarkSvg />,
    description:
      'Our formulas are 100% human-grade, because we take manufacturing and food safety seriously—every step of our sourcing, cooking, packaging, and storage processes meets the same standards as food made for humans. We have a team of credentialed food safety experts, including a full-time, dedicated microbiologist, focused on screening, monitoring, and verifying the safety and quality of all our products. They oversee every ingredient, manufacturer, and final product.',
  },
  {
    id: CoreTenetIdentifier.COOKED,
    title: 'Fully cooked (not raw), with a single protein source',
    icon: <CookingSvg />,
    description:
      'Our formulas are safe for humans to handle and dogs to eat, and more digestible than most kibble. They are made from whole meats and vegetables, and fully cooked to destroy potential pathogens while still retaining the nutritional value and moisture of the ingredients. Our food is proven to be highly digestible and palatable—dogs prefer it over popular dry options by more than a 2-to-1 margin.',
  },
  {
    id: CoreTenetIdentifier.COMPLIANT,
    title: 'Compliant with AAFCO and WSAVA guidelines',
    icon: <BadgeSvg />,
    description:
      'Our formulas are complete and balanced, which means that they meet the nutritional levels established by the AAFCO Dog Food Nutrient Profiles for all life stages. This is verified through formulation guidelines or feeding trials. Our formulas also meet or exceed WSAVA’s guidelines for selecting pet food, and The Farmer’s Dog is a Diamond Partner of WSAVA, lending support to the association’s key clinical committees and initiatives.',
  },
];

interface CoreTenetProps {
  shouldShowIcon: boolean;
  icon: ReactElement;
  title: string;
  onClick: () => void;
  headingLevel: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  titleVariant?: TextVariant;
  arrowSize?: number;
}

export function CoreTenet({
  shouldShowIcon,
  icon,
  title,
  onClick,
  titleVariant,
  headingLevel,
  arrowSize = 30,
}: CoreTenetProps) {
  const { isMobile } = useScreenSizeContext();

  const titleLeftSpacing = !isMobile && !shouldShowIcon ? 'none' : 'sm';

  const textVariantClassName = classNames({ [styles.text]: !titleVariant });

  return (
    <div className={styles.headingsContainer} onClick={onClick} role="button">
      {shouldShowIcon && (
        <div className={styles.svgContainerExtraSmall}>{icon}</div>
      )}

      <Text
        className={textVariantClassName}
        bold
        color="blueberry-3"
        leftSpacing={titleLeftSpacing}
        rightSpacing="sm"
        as={headingLevel}
        topSpacing="none"
        bottomSpacing="none"
        {...(titleVariant ? { variant: titleVariant } : {})}
      >
        {title}
      </Text>

      <ChevronSvg width={arrowSize} height={arrowSize} />
    </div>
  );
}
