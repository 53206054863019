import { Button, Text } from '@farmersdog/corgi-x';
import * as pagePaths from '../../constants/pagePaths';
import styles from './ContactForAlternativeFormulas.module.css';
import SupportIcon from '../../pages/Formulas/assets/support.svg?react';
import { trackContactForAlternativeFormulas } from '../../analytics/events';
import { radioButtonValues } from '../../pages/ContactUs/ContactUsResponsive';

export const CONTACT_REASON_QUERY_PARAM = 'contactReason';

interface ContactForAlternativeFormulasProps {
  formulaType: 'fresh' | 'diy' | 'treats' | 'all';
}

export function ContactForAlternativeFormulas({
  formulaType,
}: ContactForAlternativeFormulasProps) {
  const contactUsPath = `${pagePaths.PATH_CONTACT_US}?${CONTACT_REASON_QUERY_PARAM}=${radioButtonValues.question}`;

  return (
    <div className={styles.container}>
      <div>{<SupportIcon className={styles.icon} title="support-icon" />}</div>
      <div>
        <Text variant="heading-22" color="Charcoal3" as="h6" bold>
          Don’t see what you’re looking for?
        </Text>
        <Text variant="heading-16" color="Charcoal3" as="p">
          We are committed to supporting your clients’ unique needs. If you
          don’t see a formula that works, we can help you find an alternative in
          our portfolio.{' '}
        </Text>
      </div>
      <div>
        <Button
          type="link"
          to={contactUsPath}
          variant="secondary-mini-kale-experimental"
          onClick={() => trackContactForAlternativeFormulas({ formulaType })}
        >
          Contact Our Vets
        </Button>
      </div>
    </div>
  );
}
