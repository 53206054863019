import ReadyToEatSmallJpg from './ready-to-eat-small.jpg';
import ReadyToEatLargeJpg from './ready-to-eat-large.jpg';

import ReadyToEatSmallWebp from './ready-to-eat-small.webp';
import ReadyToEatLargeWebp from './ready-to-eat-large.webp';

import HomeCookingSmallJpg from './home-cooking-small.jpg';
import HomeCookingLargeJpg from './home-cooking-large.jpg';

import HomeCookingSmallWebp from './home-cooking-small.webp';
import HomeCookingLargeWebp from './home-cooking-large.webp';

export const DescriptionCardsSources = {
  homeCooking: {
    sm: [HomeCookingSmallWebp, HomeCookingSmallJpg],
    lg: [HomeCookingLargeWebp, HomeCookingLargeJpg],
  },
  readyToEat: {
    sm: [ReadyToEatSmallWebp, ReadyToEatSmallJpg],
    lg: [ReadyToEatLargeWebp, ReadyToEatLargeJpg],
  },
};
