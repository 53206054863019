import { Button, Grid, Text } from '@farmersdog/corgi-x';
import type { Indication } from '../../pages/Formulas/IndicationsFilter/types';
import { Close } from '@farmersdog/corgi-x/icons';
import UpArrowSvg from '../../assets/icons/upArrow.svg?react';
import { BROWSE_FORMULAS_ID } from '../../pages';
import { useScreenSizeContext } from '../../context/ScreenSizeContext';
import { LightboxId, useGlobalLightbox } from '../../hooks';
import { browserOnly } from '@farmersdog/utils';
import { formatIndicationDisplayName } from '../../pages/Formulas/IndicationsFilter/utils/formatIndicationDisplayName';

import styles from './FilteredFormulas.module.css';

interface FiltersActionsProps {
  selectedFilters: Indication[];
  setSelectedFilters: (filters: Indication[]) => void;
}

export const FiltersActions = ({
  selectedFilters,
  setSelectedFilters,
}: FiltersActionsProps) => {
  const { isMobile } = useScreenSizeContext();

  const { open: openSmallScreenModal } = useGlobalLightbox({
    id: LightboxId.FormulasFilter,
  });

  const handleRemoveFilter = (filter: Indication) => {
    setSelectedFilters(
      selectedFilters.filter(selectedFilter => selectedFilter !== filter)
    );
  };

  const handleSelectMoreFilters = () => {
    if (isMobile) {
      openSmallScreenModal();
    }

    if (!isMobile) {
      const element = document.getElementById(BROWSE_FORMULAS_ID);

      browserOnly(() => {
        element?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      });
    }
  };

  return (
    <Grid
      flexDirection={'row'}
      gap={'sm'}
      alignItems={'center'}
      bottomSpacing={'lg'}
    >
      {selectedFilters.length > 0 && (
        <>
          {' '}
          <Text
            className={styles.filterActionHeading}
            variant="heading-16"
            bold
            color="charcoal-2"
            topSpacing={'none'}
            bottomSpacing={'none'}
          >
            Filtered for:{' '}
          </Text>
          <ul className={styles.filterList}>
            {selectedFilters.map(filter => (
              <li key={filter.displayName}>
                <Button
                  variant="plain-text"
                  color="kale-dark"
                  onClick={() => handleRemoveFilter(filter)}
                  aria-label={`unfilter ${filter.displayName}`}
                >
                  <Grid alignItems={'center'}>
                    <Text as="span" color="kale-3" variant="heading-16" bold>
                      {formatIndicationDisplayName(filter.displayName)}
                    </Text>
                    <Close />
                  </Grid>
                </Button>
              </li>
            ))}
          </ul>
        </>
      )}

      <Button
        variant="plain-text"
        color="kale"
        className={styles.filterActionHeading}
        onClick={() => handleSelectMoreFilters()}
        aria-label="edit filters"
      >
        <Grid alignItems={'center'}>
          <Text as="span" color="kale-2" variant="heading-16" bold underline>
            Edit Filters
          </Text>
          {!isMobile && <UpArrowSvg width={20} height={20} />}
        </Grid>
      </Button>
    </Grid>
  );
};
