// TODO - Replace the pdf file with the new one. Keep the file name the same as below.
// So that means we shouldn't need a code change here except to remove this comment. We should only see the diff of the pdf file on github
import veterinaryGuideFile from '../../../../../static/pdf/VeterinaryGuide.pdf';
import vetNutritionGuideFile from '../../../../../static/pdf/TFD_Vet_Nutrition_Guide_2024.pdf';
import feedingTrialAffidavitsFile from '../../../../../static/pdf/Feeding-Trial-Affidavits.pdf';
import decodingCanineDCMFile from '../../../../../static/pdf/DecodingCanineDCM.pdf';
import digestibilityOnesheetFile from '../../../../../static/pdf/TFD_Digestibility-Onesheet.pdf';
import urinaryHealthFile from '../../../../../static/pdf/TFD_Understanding-RSS.pdf';
import understandingFreshNutritionFile from '../../../../../static/pdf/TFD_Understanding-Fresh-Nutrition.pdf';
import manufacturingandFoodSafety from '../../../../../static/pdf/ManufacturingandFoodSafety.pdf';
import clinicalIndicationsChart from '../../../../../static/pdf/ClinicalIndicationsChart.pdf';
import performEliminationDietTrialsFile from '../../../../../static/pdf/PerformEliminationDietTrials.pdf';

export interface DownloadListData {
  title: string;
  description: string;
  file: string;
}

export const nutritionGuide: DownloadListData[] = [
  {
    title: '2024 Nutrition Guide',
    description:
      'A comprehensive review of The Farmer’s Dog nutritional info, safety standards, and feeding instructions. For the use of veterinary professionals.',
    file: vetNutritionGuideFile,
  },
];

export const veterinaryGuide: DownloadListData[] = [
  {
    title: '2025 Veterinary Guide',
    description:
      'An introduction to The Farmer’s Dog: who we are, how our fresh food works, and the nutrition, safety, and research behind it.',
    file: veterinaryGuideFile,
  },
];

export const downloadListData: DownloadListData[] = [
  {
    title: 'Clinical Indications',
    description:
      "Learn more about how The Farmer's Dog can be used therapeutically to support your patients with medical conditions.",
    file: clinicalIndicationsChart,
  },
  {
    title: 'Feeding Study Affidavits',
    description:
      'Affidavits from veterinarians who oversaw our feeding studies.',
    file: feedingTrialAffidavitsFile,
  },
  {
    title: 'The Farmer’s Dog Manufacturing and Food Safety',
    description:
      'The Farmer’s Dog is raising the bar on every aspect of pet food, with a particular focus on manufacturing and food safety. Learn more about our production and safety methods methods.',
    file: manufacturingandFoodSafety,
  },
  {
    title: 'Understanding Fresh Nutrition',
    description:
      'Learn how to evaluate the nutritional content of fresh diets. Because key characteristics of the food are different, fresh can’t be compared like for like with kibble.',
    file: understandingFreshNutritionFile,
  },
  {
    title: 'Digestibility: A Core Nutritional Metric',
    description:
      "A key measure of a pet food’s quality, affecting not just how much stool is produced each day but a dog’s long-term well-being. The Farmer's Dog has been clinically proven through multiple third-party studies to be more digestible than the average extruded kibble.",
    file: digestibilityOnesheetFile,
  },
  {
    title:
      'Urinary Health: Understanding Urinary Relative Supersaturation (RSS)',
    description:
      "Relative supersaturation (RSS) is considered the gold standard for risk assessment of crystal formation (struvite and oxalate) in urine for dogs. We've done RSS studies on all our formulas so you can have peace of mind with your patients.",
    file: urinaryHealthFile,
  },
  {
    title: 'Decoding Canine DCM: The Truth Behind the Headlines',
    description:
      'In order to address the most persistent misunderstandings, we’ve compiled statements from the FDA along with the latest peer-reviewed research, and commentary from board-certified nutritionists.',
    file: decodingCanineDCMFile,
  },
  {
    title:
      "How to Perform Elimination Diet Trials Using The Farmer's Dog Recipes",
    description:
      'Our human-grade manufacturing process avoid the risk of cross-contamination and introduction of unwanted allergens, making our recipes great options for dogs with food sensitivities.',
    file: performEliminationDietTrialsFile,
  },
];
