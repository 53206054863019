import { track } from '../track';
import { clicked_nutrition_mix_details } from './eventNames';

/**
 * Send a tracking event for when a user clicks on the link to open the nutrition mix details modal
 */

export function trackClickNutrientMixDetails() {
  track({
    eventName: clicked_nutrition_mix_details,
  });
}
