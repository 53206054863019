import * as pagePaths from '../../constants/pagePaths';
import type { DiscountRequest } from 'src/graphql/types';
import type { AppNavItem, AppNavProps } from '@farmersdog/corgi-x';
import { trackClickedFAQNavbar } from '../../analytics/events';
import styles from './AccountHeader.module.css';
import { getAbsoluteLink } from '../../utils';
import { paths } from '@farmersdog/constants';

interface ConditionalRoutes {
  noLeftitems?: boolean;
  discountRequests: DiscountRequest[];
  currentPath: string;
  showAllFormulasDiscovery?: boolean;
}

const discountRoute: AppNavItem = {
  label: 'Activate 50% Discount',
  type: 'link',
  variant: 'plain-text',
  color: 'carrot',
  to: pagePaths.PATH_TRIAL_DISCOUNTS,
  className: styles.discountRequestLink,
};

const getConditionalRoutes = ({
  discountRequests,
  currentPath,
  showAllFormulasDiscovery,
}: ConditionalRoutes) => {
  const isDiscountRequestsEmpty = discountRequests.length === 0;

  const resultItems = [
    ...(isDiscountRequestsEmpty ? [discountRoute] : []),

    ...getNavigationItems({
      currentPath,
      showAllFormulasDiscovery,
    }),
  ];

  return resultItems;
};

export const getNavigationItems = ({
  currentPath,
  showAllFormulasDiscovery,
}: Pick<ConditionalRoutes, 'currentPath' | 'showAllFormulasDiscovery'>) =>
  [
    { label: 'Home', to: pagePaths.PATH_DASHBOARD },
    ...(showAllFormulasDiscovery
      ? [{ label: 'Formulas', to: pagePaths.PATH_FORMULAS }]
      : []),
    {
      label: 'Resources',
      to: pagePaths.PATH_DOWNLOADS,
    },
    ...(!showAllFormulasDiscovery
      ? [
          {
            label: 'Events & Recordings',
            to: pagePaths.PATH_EVENTS_AND_RECORDINGS,
          },
        ]
      : []),
    {
      label: 'FAQs',
      to: pagePaths.PATH_FAQ,
      onClick: () => {
        trackClickedFAQNavbar();
      },
    },
    { label: 'Contact', to: pagePaths.PATH_CONTACT_US },
    ...(!showAllFormulasDiscovery
      ? [{ label: 'Our Team', to: pagePaths.PATH_OUR_TEAM }]
      : []),
    { label: 'Profile', to: pagePaths.PATH_PROFILE },
    ...(showAllFormulasDiscovery
      ? [
          {
            label: 'Customer Log In',
            href: getAbsoluteLink(paths.PATH_LOGIN),
            type: 'anchor',
            target: '_blank',
            rel: 'noopener',
            className: styles.customerLoginLink,
            variant: 'special-kale-experimental',
            menuPosition: 'last',
          } satisfies AppNavItem,
        ]
      : []),
  ].map(item => {
    if (item.to === currentPath) {
      return { ...item, active: true };
    }
    return item;
  });

export const navigationLeftLinks = ({
  noLeftitems = false,
  discountRequests,
  currentPath,
  showAllFormulasDiscovery,
}: ConditionalRoutes): AppNavProps => {
  return {
    leftItems: noLeftitems
      ? []
      : getConditionalRoutes({
          discountRequests,
          currentPath,
          showAllFormulasDiscovery,
        }),
    logoTo: pagePaths.PATH_HOME,
  };
};

interface AppRightNavBar {
  discountRequests: DiscountRequest[];
  isMobile?: boolean;
}

const discountButton: AppNavItem = {
  type: 'link',
  label: 'Get 50% discount',
  variant: 'primary-mini',
  to: pagePaths.PATH_TRIAL_DISCOUNTS,
};

export const navigationRightLinks = ({
  discountRequests,
  isMobile,
}: AppRightNavBar): AppNavProps => {
  const isDiscountRequestsEmpty = discountRequests.length === 0;

  return {
    rightItems: [
      ...(isDiscountRequestsEmpty && !isMobile ? [discountButton] : []),

      {
        label: 'Log out',
        to: pagePaths.PATH_LOGOUT,
        alwaysVisible: true,
      },
    ],
  };
};
