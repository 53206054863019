import type { Indication } from '../types';
import { indicationCategoriesMap } from './indicationCategoriesMap';

export type MappedIndications = {
  [key: string]: Indication[];
};

export const mapIndicationsToCategory = (indications: Indication[]) => {
  const mappedIndications: MappedIndications = {};

  Object.entries(indicationCategoriesMap).forEach(
    ([category, filtersToDisplay]) => {
      for (const filter of filtersToDisplay) {
        const mappedIndication = indications.find(
          indication => indication.displayName === filter
        );
        if (mappedIndication) {
          if (mappedIndications[category]) {
            mappedIndications[category].push(mappedIndication);
          } else {
            mappedIndications[category] = [mappedIndication];
          }
        }
      }
    }
  );

  return mappedIndications;
};
