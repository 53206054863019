import { track } from '../track';
import { forgot_password_requested_in_temp_password_state } from './eventNames';

/**
 * Send a tracking event for when a user sends a forgot password request with an email that is still in the temp password state
 */

export interface TrackForgotPasswordRequestInTempPasswordStateArgs {
  email: string;
}

export function trackForgotPasswordRequestInTempPasswordState({
  email,
}: TrackForgotPasswordRequestInTempPasswordStateArgs) {
  track({
    eventName: forgot_password_requested_in_temp_password_state,
    eventProps: {
      email,
    },
  });
}
