import { PageWidth } from '@farmersdog/corgi-x';
import styles from './TopSection.module.css';

import { DescriptionCards } from './DescriptionCards';
import { ShipToClientInfoBanner } from '../../../components/ShipToClientInfoBanner';
import { MainSection } from './MainSection';
import { ViewVeterinaryGuide } from './ViewVeterinaryGuide';

export const TopSection = () => {
  return (
    <div className={styles.container}>
      <PageWidth>
        <MainSection />
        <DescriptionCards />
        <ViewVeterinaryGuide />
      </PageWidth>

      <ShipToClientInfoBanner />
    </div>
  );
};
