import type { SVGProps, ReactElement, FunctionComponent } from 'react';

import { Nowrap } from '@farmersdog/corgi-x';

import WeightManagementSVG from '../assets/weight-management.svg?react';
import GIProblemsSVG from '../assets/gi-problems.svg?react';
import AllergiesSVG from '../assets/allergies.svg?react';
import UrinaryHealth from '../assets/urinary-health.svg?react';

interface Solutions {
  image: FunctionComponent<
    SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  title: ReactElement | string;
  arialLabel?: string;
  content: ReactElement;
}
interface GetSolutionDataArgs {
  showVeterinaryGuide: boolean;
}

export const getSolutionData = ({
  showVeterinaryGuide,
}: GetSolutionDataArgs): Solutions[] => {
  return [
    {
      image: GIProblemsSVG,
      title: (
        <>
          Supports <br /> GI Health
        </>
      ),
      arialLabel: 'Supports GI Health Icon',
      content: (
        <>
          Digestibility is the cornerstone of an effective GI diet. We&apos;ve
          conducted digestibility studies on all our recipes and our food has
          been clinically proven highly digestible (93% protein digestibility
          vs. 64-91% <Nowrap>for kibble).</Nowrap>
        </>
      ),
    },
    {
      image: AllergiesSVG,
      title: (
        <>
          Promotes Healthy <br /> Skin & Coat
        </>
      ),
      arialLabel: 'Promotes Healthy Skin & Coat Icon',
      content: (
        <>
          Knowing exactly what a dog is eating is key to addressing allergies.
          Our food is held to the same standards as human food to avoid the risk
          of cross-contamination and unwanted ingredients—from ingredient
          sourcing to cooking, handling <Nowrap> and packaging.</Nowrap>
        </>
      ),
    },
    {
      image: UrinaryHealth,
      title: (
        <>
          Maintains <br /> Urinary Health
        </>
      ),
      arialLabel: 'Maintains Urinary Health Icon',
      content: (
        <>
          We&apos;ve done RSS studies on all our recipes so you can have peace
          of mind with your patients. Our{' '}
          {showVeterinaryGuide ? 'veterinary guide' : 'nutrition guide'} and vet
          team portal make all of that available to you so you can make easy,
          fast
          <Nowrap> clinical decisions.</Nowrap>
        </>
      ),
    },
    {
      image: WeightManagementSVG,
      title: (
        <>
          Makes Weight <br /> Management Easy
        </>
      ),
      arialLabel: 'Makes Weight Management Easy Icon',
      content: (
        <>
          Getting portions right is critical to maintaining a dog’s ideal weight
          and body condition. We work with our board-certified nutritionists to
          determine a dog’s optimal calorie needs based on breed, age, activity
          level and other factors. We’ve done the calorie calculation work,
          making it easy to adjust portions as patients’ needs change, so they
          can achieve and maintain their<Nowrap> ideal BCS.</Nowrap>
        </>
      ),
    },
  ];
};
