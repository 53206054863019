import { useFeature } from '@farmersdog/ab-testing';
import type { AnonymousFeatures, UserFeatures } from '../../features';
import { FeatureName, FormulasDiscoveryTreatments } from '../../features';

export function useFormulasDiscovery() {
  const formulasDiscovery = useFeature<
    AnonymousFeatures,
    UserFeatures,
    FeatureName
  >(FeatureName.HEALTH_FORMULAS_DISCOVERY, {});

  const showAll =
    formulasDiscovery.treatment === FormulasDiscoveryTreatments.on;
  const showPagesOnly =
    formulasDiscovery.treatment === FormulasDiscoveryTreatments.show_pages_only;

  return {
    showAllFormulasDiscovery: showAll,
    showFormulasDiscoveryPagesOnly: showPagesOnly,
    formulasDiscoverySplitLoaded: formulasDiscovery.isReady,
  };
}
