import { useState } from 'react';
import { LightboxId, useGlobalLightbox } from './useGlobalLightbox';
import type { CoreTenetType } from '../components/CoreTenet/CoreTenet';

export function useCoreTenetModal() {
  const [selectedTenet, setSelectedTenet] = useState<CoreTenetType | null>(
    null
  );
  const {
    open: openTenetModal,
    close: closeTenetModal,
    rootProps,
    labelProps,
  } = useGlobalLightbox({
    id: LightboxId.CoreTenet,
  });

  const onClickCoreTenet = (coreTenet: CoreTenetType) => {
    setSelectedTenet(coreTenet);
    openTenetModal();
  };

  const onCloseTenetModal = () => {
    closeTenetModal();
    setSelectedTenet(null);
  };

  return {
    onClickCoreTenet,
    onCloseTenetModal,
    rootProps,
    labelProps,
    selectedTenet,
  };
}
