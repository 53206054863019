import { Logger } from '@farmersdog/logger';

interface handleTempPasswordErrorArgs {
  context: string;
}

export function handleTempPasswordError({
  context,
}: handleTempPasswordErrorArgs) {
  const logger = new Logger('auth:temporaryPassword');
  logger.warn(context);
}
