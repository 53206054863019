import topWebp from './top.webp';
import topJp2 from './top.jp2';
import topPng from './top.png';
import leftWebp from './left.webp';
import leftJp2 from './left.jp2';
import leftPng from './left.png';
import rightWebp from './right.webp';
import rightJp2 from './right.jp2';
import rightPng from './right.png';

export const CHICKENLSources = {
  top: [topWebp, topJp2, topPng],
  left: [leftWebp, leftJp2, leftPng],
  right: [rightWebp, rightJp2, rightPng],
};
