import { useState, useEffect } from 'react';
import { Logger } from '@farmersdog/logger';

interface EnsureIndicationFiltersMatchArgs {
  filtersToDisplay: string[];
  indicationsData: string[];
}

export function useEnsureIndicationFiltersMatch({
  filtersToDisplay,
  indicationsData,
}: EnsureIndicationFiltersMatchArgs) {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const missingFilters: string[] = [];
    const missingIndications: string[] = [];

    if (!checked && filtersToDisplay.length > 0 && indicationsData.length > 0) {
      setChecked(true);
      const logger = new Logger('formulas:ensureIndicationFiltersMatch');

      indicationsData.forEach(indication => {
        if (!filtersToDisplay.includes(indication)) {
          missingFilters.push(indication);
        }
      });

      filtersToDisplay.forEach(filter => {
        if (!indicationsData.includes(filter)) {
          missingIndications.push(filter);
        }
      });

      if (missingFilters.length > 0) {
        logger.warn('Missing filters', {
          filters: missingFilters,
        });
      }

      if (missingIndications.length > 0) {
        logger.warn('Missing indications', {
          indications: missingIndications,
        });
      }
    }
  }, [checked, filtersToDisplay, indicationsData]);
}
