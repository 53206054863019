import { track } from '../track';
import { login_in_temp_password_state } from './eventNames';

/**
 * Send a tracking event for when a user attempts to log in with an email that is still in the temp password state
 */

export interface TrackLoginInTempPasswordStateArgs {
  email: string;
}

export function trackLoginInTempPasswordState({
  email,
}: TrackLoginInTempPasswordStateArgs) {
  track({
    eventName: login_in_temp_password_state,
    eventProps: {
      email,
    },
  });
}
