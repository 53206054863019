import {
  beef,
  chicken,
  chickenAndGrain,
  pork,
  turkey,
  chickenTreats,
  porkTreats,
  turkeyTreats,
  diyNutrientMix,
} from '../../pages/Formulas/assets/sources';

interface Asset {
  alt: string;
  images: string[];
}

export interface FormulaAsset {
  [displayName: string]: Asset;
}

export const freshFormulaAssets: FormulaAsset = {
  'Chicken & Grain': {
    alt: ' Chicken and Grain Fresh Formula',
    images: chickenAndGrain,
  },
  Chicken: {
    alt: 'Chicken Fresh Formula',
    images: chicken,
  },
  Beef: {
    alt: 'Beef Fresh Formula',
    images: beef,
  },
  Turkey: {
    alt: 'Turkey Fresh Formula',
    images: turkey,
  },
  Pork: {
    alt: 'Pork Fresh Formula',
    images: pork,
  },
};

export const treatsFormulaAssets: FormulaAsset = {
  'Chicken Snap Sticks': {
    alt: 'Chicken Limited-ingredient Treats Formula',
    images: chickenTreats,
  },
  'Turkey Snap Sticks': {
    alt: 'Turkey Limited-ingredient Treats Formula',
    images: turkeyTreats,
  },
  'Pork Snap Sticks': {
    alt: 'Pork Limited-ingredient Treats Formula',
    images: porkTreats,
  },
};

export const diyFormulaAssets: FormulaAsset = {
  diy: {
    alt: 'Nutrient Mix Formula',
    images: diyNutrientMix,
  },
};
