import { Divider, Text } from '@farmersdog/corgi-x';
import { Card } from '../../../components/Card';

import styles from './Resources.module.css';
import { getResourcesData } from './resourcesData';
import React from 'react';
import { usePublicPageFeatures } from '../../../abTesting/PublicPageFeatures';

interface ResourcesProps {
  shouldShowDiscountSection: boolean;
}

export function Resources({ shouldShowDiscountSection }: ResourcesProps) {
  const { showAllFormulasDiscovery } = usePublicPageFeatures();
  const resources = getResourcesData({
    shouldShowDiscountSection,
    showVeterinaryGuide: Boolean(showAllFormulasDiscovery),
  });

  return (
    <Card className={styles.card}>
      {resources?.map(({ title, content }, index) => {
        return (
          <React.Fragment key={title}>
            <Text as="h3" variant="heading-22" color="Blueberry3" bold>
              {title}
            </Text>
            {content}
            {index !== resources.length - 1 && (
              <Divider color="Kale1" width={1} spacing={24} />
            )}
          </React.Fragment>
        );
      })}
    </Card>
  );
}
