import { track } from '../track';
import { clicked_contact_for_alternative_formulas } from './eventNames';

/**
 * Send a tracking event for when a user clicks on contact for alternative formulas.
 */

interface TrackContactForAlternativeFormulasValues {
  formulaType: string;
}

export function trackContactForAlternativeFormulas({
  formulaType,
}: TrackContactForAlternativeFormulasValues) {
  track({
    eventName: clicked_contact_for_alternative_formulas,
    eventProps: {
      formulaType,
    },
  });
}
