import { Text } from '@farmersdog/corgi-x';

import styles from './Pill.module.css';

export const Pill = ({ children }: { children: string | undefined }) => {
  return (
    <Text variant="heading-12" color="Charcoal3" className={styles.pill}>
      {children}
    </Text>
  );
};
