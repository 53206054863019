import foodBlowsPng from './food-bowls.png';
import foodBlowsWebp from './food-bowls.webp';
import foodBlowsJpg from './food-bowls.jpg';

import wsavaLogoPng from './wsava-logo.png';
import wsavaLogoWebp from './wsava-logo.webp';

import obesityPreventionPng from './obesity-prevention-logo.png';
import obesityPreventionWebp from './obesity-prevention-logo.webp';

export const foodBowlsAssets = [foodBlowsWebp, foodBlowsJpg, foodBlowsPng];
export const wsavaLogoAssets = [wsavaLogoWebp, wsavaLogoPng];
export const obesityPreventionAssets = [
  obesityPreventionWebp,
  obesityPreventionPng,
];
