import { type NutritionData } from '../../../../graphql/types';

export const transformData = (data: NutritionData[]) => {
  return data.reduce((prev: { [key: string]: string[][] }, curr) => {
    const key = curr.unit ?? '';
    if (!prev[key]) {
      prev[key] = [];
    }

    prev[key].push([curr.name, curr.displayValue, curr.unit ?? '']);

    return prev;
  }, {});
};
