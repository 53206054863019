import { AccordionPanel, Text } from '@farmersdog/corgi-x';
import { type NutritionData } from '../../../../graphql/types';
import { NutritionTable } from '../../NutritionTable';
import { useMemo } from 'react';
import { transformData } from './utils/transformData';

interface DigestibilityPanelProps {
  nutritionData: NutritionData[];
  onToggle: (expanded: boolean) => void;
}

export const DigestibilityPanel = ({
  nutritionData,
  onToggle,
}: DigestibilityPanelProps) => {
  const normalizedData = useMemo(
    () => transformData(nutritionData),
    [nutritionData]
  );
  return (
    <AccordionPanel
      id="digestibility"
      expanded
      onToggle={onToggle}
      heading={
        <Text variant="heading-22">
          <strong>Digestibility</strong> (average of third-party tests)
        </Text>
      }
    >
      <NutritionTable rows={normalizedData} />
    </AccordionPanel>
  );
};
