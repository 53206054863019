import { Grid, GridItem, PageWidth, Text } from '@farmersdog/corgi-x';
import styles from './ShipToClientInfoBanner.module.css';
import { useScreenSizeContext } from '../../context/ScreenSizeContext';

export const ShipToClientInfoBanner = () => {
  const { isMobile } = useScreenSizeContext();
  return (
    <div className={styles.infoBanner}>
      <PageWidth>
        <Grid
          topSpacing="lg"
          bottomSpacing="lg"
          justifyContent={'space-around'}
        >
          <GridItem xs={9} alignItems={'center'}>
            <Text
              variant={isMobile ? 'heading-16' : 'heading-22'}
              color="charcoal-3"
              align="center"
              as="p"
              topSpacing="none"
              bottomSpacing="none"
            >
              The Farmer&apos;s Dog is{' '}
              <Text
                bold
                variant={isMobile ? 'heading-16' : 'heading-22'}
                color="charcoal-3"
              >
                shipped directly to your client
              </Text>{' '}
              in portion-controlled packages with 24/7 customer support and full
              traceability from our supplier to each customer&apos;s home.
            </Text>
          </GridItem>
        </Grid>
      </PageWidth>
    </div>
  );
};
