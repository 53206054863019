import { useEffect, useState } from 'react';
import type { ClinicalFormula } from '../graphql/types';
import type { Indication } from '../pages/Formulas/IndicationsFilter/types';

interface UseFilteredFormulasArgs {
  formulas?: ClinicalFormula[];
  selectedFilters: Indication[];
}

export function useFilteredFormulas({
  formulas,
  selectedFilters,
}: UseFilteredFormulasArgs) {
  const [filteredFormulas, setFilteredFormulas] = useState<ClinicalFormula[]>(
    []
  );

  useEffect(() => {
    if (formulas) {
      if (selectedFilters.length > 0) {
        setFilteredFormulas(filterFormulas(formulas, selectedFilters));
      } else {
        setFilteredFormulas(formulas);
      }
    }
  }, [formulas, selectedFilters]);

  return filteredFormulas;
}

const filterFormulas = (
  formulas: ClinicalFormula[] | undefined,
  selectedFilters: Indication[]
): ClinicalFormula[] => {
  if (!formulas || formulas?.length === 0) {
    return [];
  }

  if (selectedFilters.length === 0) {
    return formulas;
  }

  return formulas?.filter(formula =>
    selectedFilters.every(selectedFilter =>
      formula?.indications?.some(
        indication =>
          selectedFilter.displayName === indication?.displayName &&
          selectedFilter.indicationType === indication?.indicationType
      )
    )
  );
};
