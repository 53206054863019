import { Text } from '@farmersdog/corgi-x';
import { getGeneralQuestions } from '../data';
import { QuestionDropdown } from '../QuestionDropdown';
import { usePublicPageFeatures } from '../../../abTesting/PublicPageFeatures';

export const GeneralSection = ({ menuItemRef }: { menuItemRef: string }) => {
  const { showAllFormulasDiscovery, showPortalUXOverhaul } =
    usePublicPageFeatures();
  return (
    <div id={menuItemRef}>
      <Text
        bold
        as="h3"
        topSpacing={{
          xs: showPortalUXOverhaul ? 'none' : 'xl',
          lg: 'none',
        }}
        bottomSpacing="md"
        color="blueberry-3"
        variant="heading-28"
      >
        General
      </Text>
      {getGeneralQuestions({
        showVeterinaryGuide: Boolean(showAllFormulasDiscovery),
      }).map(({ question, answer }) => (
        <QuestionDropdown key={question} question={question} answer={answer} />
      ))}
    </div>
  );
};
