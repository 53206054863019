import { Grid, GridItem, Text } from '@farmersdog/corgi-x';
import { Picture, type PictureProps } from '../../../../components';
import styles from './InstructionCard.module.css';
import { Pill } from './Pill';

interface InstructionCardProps extends Pick<PictureProps, 'sources' | 'alt'> {
  description: string;
  step: number;
  title: string;
}

export const InstructionCard = ({
  step,
  title,
  description,
  ...imageProps
}: InstructionCardProps) => {
  return (
    <Grid className={styles.container}>
      <GridItem className={styles.image}>
        <div className={styles.pill}>
          <Pill step={step} title={title} />
        </div>
        <Picture {...imageProps} className={styles.picture} />
      </GridItem>
      <Text variant="heading-16" spacing="none" as="p">
        {description}
      </Text>
    </Grid>
  );
};
