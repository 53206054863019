export enum LightboxId {
  WhoIsEligible = 'whoIsEligible',
  AreYouSubscribedTerms = 'areYouSubscribedTerms',
  ShareWebinar = 'shareWebinar',
  Biography = 'biography',
  FormulasFilter = 'formulasFilter',
  RecommendToClient = 'recommendToClient',
  NutrientMix = 'nutrientMix',
  CoreTenet = 'coreTenet',
}
