import { Grid, GridItem, Text } from '@farmersdog/corgi-x';
import { Pill } from '../../../components';
import styles from './RecommendedFor.module.css';
import { formatIndicationDisplayName } from '../../Formulas/IndicationsFilter/utils/formatIndicationDisplayName';

interface RecommendedForProps {
  indications: (string | undefined)[] | undefined;
}

export const RecommendedFor = ({ indications }: RecommendedForProps) => {
  return (
    <Grid gap="sm" flexDirection="column">
      <Text variant="heading-16">Recommended for:</Text>
      <GridItem className={styles.indications}>
        <ul className={styles.indicationList}>
          {indications?.map(indication => (
            <li key={indication}>
              <Pill>{formatIndicationDisplayName(indication ?? '')}</Pill>
            </li>
          ))}
        </ul>
      </GridItem>
    </Grid>
  );
};
