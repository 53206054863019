export const transformData = (value: string) => {
  if (value.length === 0) {
    return [];
  }
  const analysisItemList = value.split('\n').map(item => item.split(':'));
  return analysisItemList.map(item => {
    if (item.length < 2) {
      return { description: item[0] };
    }
    const matchPercentage = item[1].match(/(\d+(\.\d+)?)%/);
    const matchType = item[1].match(/\b(min|max)\b/);

    return {
      description: item[0],
      percentage: matchPercentage ? matchPercentage[0] : '',
      type: matchType ? matchType[0] : '',
    };
  });
};
