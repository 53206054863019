import { type ClinicalFormula } from '../../../graphql/types';

interface GetHasTypicalNutritionalsContentArgs {
  basicInfo: ClinicalFormula['basicInfo'];
  clinicalNutrition: ClinicalFormula['clinicalNutrition'];
}
export const getHasTypicalNutritionalsContent = ({
  basicInfo,
  clinicalNutrition,
}: GetHasTypicalNutritionalsContentArgs) =>
  basicInfo?.calculatedCalorieContent ||
  clinicalNutrition?.digestibility ||
  clinicalNutrition?.rss ||
  clinicalNutrition?.estimatedAnalysis;
