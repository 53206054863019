import type * as Apollo from '@apollo/client';

import type {
  ResendTempCredentialsMutation,
  ResendTempCredentialsMutationVariables,
} from '../types';
import resendTempCredentials from '../mutations/resend-temp-credentials.graphql';
import { useMutation } from '../../services/apollo';

type Options = Apollo.MutationHookOptions<
  ResendTempCredentialsMutation,
  ResendTempCredentialsMutationVariables
>;

export function useResendTempCredentials(options?: Options) {
  const [resendTempCredentialsMutation, { loading, error }] = useMutation<
    ResendTempCredentialsMutation,
    ResendTempCredentialsMutationVariables
  >(resendTempCredentials, options);

  return { resendTempCredentialsMutation, loading, error };
}
