import type {
  ClinicalNutrition,
  ClinicalFormula,
} from '../../../graphql/types';
import { Ingredients } from '../Ingredients/Ingredients';
import { Title } from '../Title';
import { FormulaType, IndicationType } from '../../../constants';
import { RecommendedFor } from '../RecommendedFor';
import { NotRecommendedFor } from '../NotRecommendedFor';
import { Grid } from '@farmersdog/corgi-x';
import { Information } from '../Information';
import { TypicalNutritionals } from '../TypicalNutritionals';
import { RecommendToClient } from '../RecommendToClient';
import { getHasTypicalNutritionalsContent } from '../utils/getHasTypicalNutritionalsContent';

export const DIY = ({
  basicInfo,
  indications,
  clinicalNutrition,
}: Pick<
  ClinicalFormula,
  'basicInfo' | 'indications' | 'clinicalNutrition'
>) => {
  const recommendedForItems = indications
    ?.filter(
      indication => indication?.indicationType === IndicationType.Clinical
    )
    .map(indication => indication?.displayName)
    .sort();

  const noRecommendedForItems = indications
    ?.filter(indication => indication?.indicationType === IndicationType.Contra)
    .map(indication => indication?.displayName);

  const hasTypicalNutritionalsContent = getHasTypicalNutritionalsContent({
    basicInfo,
    clinicalNutrition,
  });

  return (
    <>
      <Title>{basicInfo?.displayName ?? ''}</Title>
      <Grid rowGap="md">
        {basicInfo?.mainIngredients && (
          <Ingredients
            ingredients={basicInfo.mainIngredients}
            formulaType={FormulaType.DIY}
          />
        )}
        {recommendedForItems && recommendedForItems.length > 0 && (
          <RecommendedFor indications={recommendedForItems} />
        )}
        {noRecommendedForItems && noRecommendedForItems.length > 0 && (
          <NotRecommendedFor indications={noRecommendedForItems} />
        )}

        <Information variant="diy" />
      </Grid>
      <Grid topSpacing="lg" bottomSpacing="xl">
        <RecommendToClient />
      </Grid>
      {hasTypicalNutritionalsContent && (
        <Grid>
          <TypicalNutritionals
            calculatedCalorieContent={basicInfo?.calculatedCalorieContent ?? ''}
            clinicalNutrition={clinicalNutrition as ClinicalNutrition}
          />
        </Grid>
      )}
    </>
  );
};
