import { selected_indication_filter } from './eventNames';
import { track } from '../track';

/**
 * Send a tracking event for when a user selects/unselects an indication filter
 *
 * @param selected - Selected indications
 * @param deselected - Unselected indications
 */

export interface TrackIndicationFiltersSelectedValues {
  selected: string[];
  deselected?: string[] | null;
}

export function trackIndicationFiltersSelected({
  selected,
  deselected = null,
}: TrackIndicationFiltersSelectedValues) {
  track({
    eventName: selected_indication_filter,
    eventProps: {
      selected,
      deselected,
    },
  });
}
