import chickenAndGrainPng from './images/fresh/chickenAndGrain.png';
import chickenAndGrainWebp from './images/fresh/chickenAndGrain.webp';

import turkeyPng from './images/fresh/turkey.png';
import turkeyWebp from './images/fresh/turkey.webp';

import beefPng from './images/fresh/beef.png';
import beefWebp from './images/fresh/beef.webp';

import chickenPng from './images/fresh/chicken.png';
import chickenWebp from './images/fresh/chicken.webp';

import porkPng from './images/fresh/pork.png';
import porkWebp from './images/fresh/pork.webp';

import porkAndGrainPng from './images/fresh/porkAndGrain.png';
import porkAndGrainWebp from './images/fresh/porkAndGrain.webp';

import chickenTreatsWebp from './images/treats/chicken-snap-sticks.webp';
import chickenTreatsPng from './images/treats/chicken-snap-sticks.png';
import turkeyTreatsWebp from './images/treats/turkey-snap-sticks.webp';
import turkeyTreatsPng from './images/treats/turkey-snap-sticks.png';
import porkTreatsWebp from './images/treats/pork-snap-sticks.webp';
import porkTreatsPng from './images/treats/pork-snap-sticks.png';

import diyNutrientMixPng from './images/diy/diy-nutrient-mix.png';
import diyNutrientMixWebp from './images/diy/diy-nutrient-mix.webp';

export const chickenAndGrain = [chickenAndGrainWebp, chickenAndGrainPng];
export const turkey = [turkeyWebp, turkeyPng];
export const beef = [beefWebp, beefPng];
export const chicken = [chickenWebp, chickenPng];
export const pork = [porkWebp, porkPng];
export const porkAndGrain = [porkAndGrainWebp, porkAndGrainPng];

export const chickenTreats = [chickenTreatsWebp, chickenTreatsPng];
export const turkeyTreats = [turkeyTreatsWebp, turkeyTreatsPng];
export const porkTreats = [porkTreatsWebp, porkTreatsPng];

export const diyNutrientMix = [diyNutrientMixPng, diyNutrientMixWebp];
