import { trackToggleTable } from '../../../analytics/events';

interface OnToggleTableArgs {
  title: string;
  isExpanded: boolean;
}

export const onToggleTable = ({ isExpanded, title }: OnToggleTableArgs) => {
  trackToggleTable({ action: isExpanded ? 'expand' : 'collapse', title });
};
