import { track } from '../track';
import { toggled_formula_table } from './eventNames';

interface TrackToggleTableArgs {
  title: string;
  action: 'expand' | 'collapse';
}

/**
 * Send a tracking event for when a user toggle the indication/nutrition tables
 */

export function trackToggleTable({ title, action }: TrackToggleTableArgs) {
  track({
    eventName: toggled_formula_table,
    eventProps: {
      title,
      action,
    },
  });
}
