import { type NutritionData } from '../../../../../graphql/types';

const NUTRITION_PREFIX = 'RSSC';

export const transformData = (data: NutritionData[]) =>
  data.map(({ displayValue, name, unit }) => {
    let newDisplayValue = displayValue;
    if (unit !== '%') {
      newDisplayValue = newDisplayValue.replace(/'/g, '"');
      try {
        if (Array.isArray(JSON.parse(newDisplayValue))) {
          newDisplayValue = (JSON.parse(newDisplayValue) as string[])
            .map(display => `${NUTRITION_PREFIX}${display}`)
            .join(', ');
        } else {
          newDisplayValue = displayValue;
        }
      } catch {
        newDisplayValue = displayValue;
      }
    }
    return [name, newDisplayValue] as string[];
  });
