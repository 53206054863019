import { Text } from '@farmersdog/corgi-x';

import styles from './Information.module.css';

interface InformationProps {
  variant: 'diy' | 'fresh' | 'treat';
}

const VARIANT_TEXT = {
  diy: (
    <>
      This recipe has been formulated to meet AAFCO feeding standards when used
      as directed. For a complete and balanced meal, instruct clients to cook
      the DIY recipes exactly as provided without adding, removing, or
      substituting ingredients.
    </>
  ),
  fresh: (
    <>
      The Farmer’s Dog Beef Recipe for Dogs is formulated to meet the
      nutritional levels established by the{' '}
      <strong> AAFCO Dog Food Nutrient Profiles </strong>for all life stages,
      including growth of large-sized dogs (70 lbs. or more as an adult).
    </>
  ),
  treat: <>Intended for supplemental feeding only.</>,
};

export const Information = ({ variant }: InformationProps) => {
  return (
    <Text
      variant="heading-16"
      as="p"
      spacing="none"
      className={styles.information}
    >
      {VARIANT_TEXT[variant]}
    </Text>
  );
};
