import { Link } from '@farmersdog/corgi';
import { Text, Button } from '@farmersdog/corgi-x';
import { emails } from '@farmersdog/constants';
import { trackClickedReviewFAQs } from '../../../analytics/events';

import * as pagePaths from '../../../constants/pagePaths';
import * as externalLinks from '../../../constants/externalLinks';

import styles from './Resources.module.css';
import { trackLandingPageClickedLunchAndLearnRequest } from '../../../analytics/events/trackLandingPageClickedLunchAndLearnRequest';
import { trackLandingPageClickedUniversityVisitRequest } from '../../../analytics/events/trackLandingPageClickedUniversityVisitRequest';

const vetEmail = emails.EMAIL_VET;
const emailLinkHref = `mailto: ${vetEmail}`;

interface GetResourcesDataArgs {
  shouldShowDiscountSection: boolean;
  showVeterinaryGuide: boolean;
}

export const getResourcesData = ({
  shouldShowDiscountSection,
  showVeterinaryGuide,
}: GetResourcesDataArgs) => {
  const rows = [
    {
      ...(showVeterinaryGuide
        ? {
            title: 'Veterinary Guide, Research, and Clinical Resources',
            content: (
              <div className={styles.option}>
                <Text
                  as="p"
                  topSpacing="none"
                  variant="article-16"
                  color="charcoal-3"
                >
                  Get our comprehensive veterinary guide and more.
                </Text>
                <Button
                  variant="plain-text"
                  type="link"
                  to={pagePaths.PATH_DOWNLOADS}
                >
                  View and Download Assets
                </Button>
              </div>
            ),
          }
        : {
            title: 'Nutrition Guide, Research, and Clinical Resources',
            content: (
              <div className={styles.option}>
                <Text
                  as="p"
                  topSpacing="none"
                  variant="article-16"
                  color="charcoal-3"
                >
                  Get our comprehensive nutrition guide and more.
                </Text>
                <Button
                  variant="plain-text"
                  type="link"
                  to={pagePaths.PATH_DOWNLOADS}
                >
                  View and Download Assets
                </Button>
              </div>
            ),
          }),
    },
    {
      title: 'Events & Recordings',
      content: (
        <div className={styles.option}>
          <Text
            as="p"
            topSpacing="none"
            variant="article-16"
            color="charcoal-3"
          >
            Stay up to date on our latest in-person and virtual events. Watch
            recordings of previous CE courses and talks.
          </Text>
          <Button
            variant="plain-text"
            type="link"
            to={pagePaths.PATH_EVENTS_AND_RECORDINGS}
          >
            View Events
          </Button>
        </div>
      ),
    },
    {
      title: 'Lunch & Learns',
      content: (
        <div className={styles.option}>
          <Text
            as="p"
            topSpacing="none"
            variant="article-16"
            color="charcoal-3"
          >
            Our on-staff veterinary team can provide your clinic with the latest
            information about our products and answer your questions.
          </Text>
          <Button
            onClick={trackLandingPageClickedLunchAndLearnRequest}
            variant="plain-text"
            type="anchor"
            target="_blank"
            href={externalLinks.PATH_LUNCH_AND_LEARN_REQUEST}
          >
            Request a Lunch & Learn
          </Button>
        </div>
      ),
    },
    {
      title: 'University Visit',
      content: (
        <div className={styles.option}>
          <Text
            as="p"
            topSpacing="none"
            variant="article-16"
            color="charcoal-3"
          >
            We know how important it is for students to build a strong
            foundation. That&apos;s why we have a team of veterinarians visiting
            veterinary schools to educate, answer questions, and support faculty
            and educators.
          </Text>
          <Button
            onClick={trackLandingPageClickedUniversityVisitRequest}
            variant="plain-text"
            type="anchor"
            target="_blank"
            href={externalLinks.PATH_LUNCH_AND_LEARN_REQUEST}
          >
            Request a University Visit
          </Button>
        </div>
      ),
    },
    {
      title: 'Frequently Asked Questions',
      content: (
        <div className={styles.option}>
          <Text
            as="p"
            topSpacing="none"
            variant="article-16"
            color="charcoal-3"
          >
            Learn more about us by checking out what your fellow vet
            professionals commonly want to know.
          </Text>
          <Button
            onClick={trackClickedReviewFAQs}
            variant="plain-text"
            type="link"
            to={pagePaths.PATH_FAQ}
          >
            Review FAQs
          </Button>
        </div>
      ),
    },
    {
      hidden: !shouldShowDiscountSection,
      title: 'Free Trial/Discounts',
      content: (
        <div className={styles.option}>
          <Text
            as="p"
            topSpacing="none"
            variant="article-16"
            color="charcoal-3"
          >
            We’re offering veterinary professionals free trials and a 50%
            discount on subscriptions.
          </Text>
          <Button
            variant="plain-text"
            type="link"
            to={pagePaths.PATH_TRIAL_DISCOUNTS}
          >
            Sign Up
          </Button>
        </div>
      ),
    },
    {
      title: 'Contact Us',
      content: (
        <div className={styles.option}>
          <Text
            as="p"
            topSpacing="none"
            variant="article-16"
            color="charcoal-3"
          >
            Have questions or need more info?
          </Text>
          <Button
            variant="plain-text"
            type="link"
            to={pagePaths.PATH_CONTACT_US}
          >
            Get in Touch
          </Button>
        </div>
      ),
    },
    {
      title: 'Meet Our Team',
      content: (
        <div className={styles.option}>
          <Text
            as="p"
            topSpacing="none"
            variant="article-16"
            color="charcoal-3"
          >
            Our experts are here to answer any question you have about our
            product or pet nutrition.
          </Text>
          <Button variant="plain-text" type="link" to={pagePaths.PATH_OUR_TEAM}>
            Learn More
          </Button>
        </div>
      ),
    },
    {
      title: 'Submit Testimonials',
      content: (
        <div className={styles.option}>
          <Text
            as="p"
            topSpacing="none"
            variant="article-16"
            color="charcoal-3"
          >
            We love sharing stories and insights from veterinary professionals.
            If you or one of your clients have had a positive experience or
            outcome with our food, please contact us at{' '}
            <Link href={emailLinkHref} variant="primary">
              {vetEmail}
            </Link>
            .
          </Text>
        </div>
      ),
    },
  ];

  return rows;
};
