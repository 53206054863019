import * as pagePaths from '../../../constants/pagePaths';
import * as externalLinks from '../../../constants/externalLinks';
import { trackLandingPageClickedLunchAndLearnRequest } from '../../../analytics/events/trackLandingPageClickedLunchAndLearnRequest';
import { trackLandingPageClickedUniversityVisitRequest } from '../../../analytics/events/trackLandingPageClickedUniversityVisitRequest';

interface GetEducationItemsArgs {
  showVeterinaryGuide: boolean;
}

export const getEducationItems = ({
  showVeterinaryGuide,
}: GetEducationItemsArgs) => [
  {
    ...(showVeterinaryGuide
      ? {
          title: 'Veterinary Guide and More Resources',
          description:
            'Get our comprehensive veterinary guide, clinical resources, and research, plus relevant literature from other experts.',
          linkText: 'Dig Into the Science',
          linkUrl: pagePaths.PATH_DOWNLOADS,
          externalLink: false,
        }
      : {
          title: 'Nutrition Guide and More Resources',
          description:
            'Get our comprehensive nutrition guide, clinical resources, and research, plus relevant literature from other experts.',
          linkText: 'Dig Into the Science',
          linkUrl: pagePaths.PATH_DOWNLOADS,
          externalLink: false,
        }),
  },
  {
    title: 'Events & Recordings',
    description:
      'Stay up to date on our latest in-person and virtual events. Watch recordings of previous CE courses and receive complimentary RACE credits.',
    linkText: 'View Events and Replays',
    linkUrl: pagePaths.PATH_EVENTS_AND_RECORDINGS,
    externalLink: false,
  },
  {
    title: 'Lunch & Learns',
    description:
      'Our on-staff veterinary team can provide your clinic with the latest information about our products and answer your questions.',
    linkText: 'Request a Lunch & Learn',
    linkUrl: externalLinks.PATH_LUNCH_AND_LEARN_REQUEST,
    externalLink: true,
    onClick: trackLandingPageClickedLunchAndLearnRequest,
  },
  {
    title: 'University Visit',
    description:
      "We know how important it is for students to build a strong nutrition foundation. That's why we have a team of vets visiting veterinary schools to educate, answer questions, and support faculty.",
    linkText: 'Request a University Visit',
    linkUrl: externalLinks.PATH_LUNCH_AND_LEARN_REQUEST,
    externalLink: true,
    onClick: trackLandingPageClickedUniversityVisitRequest,
  },
];
