import { Text } from '@farmersdog/corgi-x';
import { useMemo, useCallback, useState } from 'react';
import styles from './Ingredients.module.css';
import { LightboxId, useGlobalLightbox } from '../../../hooks';
import { Modal } from '@farmersdog/corgi';
import { trackClickNutrientMixDetails } from '../../../analytics/events';

export const nutritionMixOptions = {
  fresh: 'TFD Nutrient Blend',
  diy: 'DIY Nutrient Mix',
};

type NutritionMixOptionsType = typeof nutritionMixOptions;
type NutritionMixKey = keyof NutritionMixOptionsType;

export const freshDescription =
  'Tricalcium Phosphate, Salt, Potassium Chloride, Choline Bitartrate, Taurine, Magnesium Amino Acid Chelate, Zinc Amino Acid Chelate, Iron Amino Acid Chelate, Vitamin E Supplement, Potassium Iodide, Selenium Yeast, Vitamin B12 Supplement, Copper Amino Acid Chelate, Manganese Amino Acid Chelate, Riboflavin Supplement (Vitamin B2), Thiamine Mononitrate (Vitamin B1), Vitamin D3 Supplement, Pyridoxine Hydrochloride (Vitamin B6), Folic Acid (Vitamin B9)';
export const diyDescription =
  'Tricalcium Phosphate, Potassium Chloride, Choline Bitartrate, Sodium Chloride, Taurine, Zinc Amino Acid Chelate, Ferrous Amino Acid Chelate, Vitamin E Supplement, Potassium Iodide, Copper Amino Acid Chelate, Vitamin B12 Supplement, Manganese Amino Acid Chelate, Thiamine Mononitrate (Vitamin B1), Riboflavin Supplement (Vitamin B2), Sodium Selenite, Vitamin D3 Supplement, Pyridoxine Hydrochloride (Vitamin B6), Folic Acid (Vitamin B9).';

interface IngredientsProps {
  ingredients: string;
  formulaType: string;
}

export const Ingredients = ({ ingredients, formulaType }: IngredientsProps) => {
  const modalProps = useGlobalLightbox({
    id: LightboxId.NutrientMix,
  });

  const { labelProps } = modalProps;

  const [activeMixOption, setActiveMixOption] = useState<NutritionMixKey | ''>(
    ''
  );

  const onClickNutritionMix = useCallback(
    (link: NutritionMixKey) => {
      modalProps.open();
      trackClickNutrientMixDetails();
      setActiveMixOption(link);
    },
    [modalProps, setActiveMixOption]
  );

  const ingredientsDescription = useMemo(() => {
    const textToLookUp =
      nutritionMixOptions[formulaType as keyof typeof nutritionMixOptions];
    if (!textToLookUp) {
      return ingredients;
    }

    const ingredientSections = ingredients.split(textToLookUp);
    if (ingredientSections.length === 1) {
      return ingredients;
    }

    return ingredientSections.map((ingredient, index) => (
      <span key={index}>
        {!!index && (
          <Text
            variant="heading-22"
            underline
            color="Kale2"
            role="button"
            className={styles.linkModal}
            onClick={() => onClickNutritionMix(formulaType as NutritionMixKey)}
          >
            {textToLookUp}
          </Text>
        )}
        {ingredient}
      </span>
    ));
  }, [ingredients, formulaType, onClickNutritionMix]);

  return (
    <>
      <Modal {...modalProps}>
        <Text
          bold
          as="h3"
          topSpacing="none"
          bottomSpacing="sm"
          color="blueberry-3"
          variant="heading-28"
          className={styles.title}
          {...labelProps}
        >
          {activeMixOption === 'diy' && nutritionMixOptions.diy}
          {activeMixOption === 'fresh' && nutritionMixOptions.fresh}
        </Text>
        <Text>
          {activeMixOption === 'diy' && diyDescription}
          {activeMixOption === 'fresh' && freshDescription}
        </Text>
      </Modal>
      <Text variant="heading-22" as="p" spacing="none">
        <strong>Ingredients: </strong>
        {ingredientsDescription}
      </Text>
    </>
  );
};
