import { Text } from '@farmersdog/corgi-x';
import styles from './Title.module.css';

export const Title = ({ children }: { children: JSX.Element | string }) => {
  return (
    <Text
      variant="heading-40"
      bold
      color="Blueberry3"
      as="h1"
      topSpacing="none"
      className={styles.title}
    >
      {children}
    </Text>
  );
};
