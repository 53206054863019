import { Grid, Text } from '@farmersdog/corgi-x';
import { joinText } from '../../../utils/joinText';

interface NoRecommendedForProps {
  indications: (string | undefined)[] | undefined;
}

export const NotRecommendedFor = ({ indications }: NoRecommendedForProps) => {
  return (
    <Grid gap="sm" flexDirection="column">
      <Text variant="heading-12" bold as="p" spacing="none">
        This formula is not recommended for patients with the following:{' '}
        {joinText({
          elements: indications as string[],
          separator: ', ',
          endSeparator: ' and/or ',
        })}
      </Text>
    </Grid>
  );
};
