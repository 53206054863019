import { Text } from '@farmersdog/corgi-x';
import styles from './NutritionTable.module.css';
interface NutritionTableProps {
  rows: string[][];
}

export const NutritionTable = ({ rows }: NutritionTableProps) => {
  return (
    <table className={styles.table}>
      <tbody>
        {rows.map((row, index) => (
          <tr key={index}>
            {row.map(item => (
              <td key={item}>
                <Text variant="heading-16"> {item}</Text>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
