import {
  FORMULAS_VANITY,
  PATH_APP,
  RESEARCH_VANITY,
  WSAVA_VANITY,
} from './pagePaths';

type VanityUrl = {
  name: string;
  vanityRoute: string;
  redirectsTo: string;
};

export const vanityUrls: VanityUrl[] = [
  {
    name: 'vanityFormulas',
    vanityRoute: FORMULAS_VANITY,
    redirectsTo: `${PATH_APP}/formulas`,
  },
  {
    name: 'vanityResearch',
    vanityRoute: RESEARCH_VANITY,
    redirectsTo: `${PATH_APP}/downloads`,
  },
  {
    name: 'vanityWsava',
    vanityRoute: WSAVA_VANITY,
    redirectsTo: `${PATH_APP}/downloads?resource=tfd-and-wsava-guidelines`,
  },
];
