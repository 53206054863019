import { track } from '../track';
import { clicked_browse_formulas } from './eventNames';

/**
 * Send a tracking event for when a user clicked the Start Browsing Formulas
 */

export function trackClickedBrowseFormulas() {
  track({
    eventName: clicked_browse_formulas,
  });
}
