import { Text } from '@farmersdog/corgi-x';

import CheckIconSVG from './assets/check-icon.svg?react';

import styles from './Hero.module.css';

const listItem = [
  'Developed by on-staff board-certified nutritionists',
  'Cooked (not raw) with a single protein source',
  'Made and tested in FDA-compliant facilities',
  'Compliant with AAFCO and WSAVA guidelines',
];

interface HeroListProps {
  isMobile?: boolean;
}

export const HeroList = ({ isMobile }: HeroListProps) => {
  return (
    <ul className={styles.list}>
      {listItem.map(item => (
        <li className={styles.listItem} key={item}>
          <CheckIconSVG
            className={styles.checkIcon}
            width={isMobile ? 24 : 36}
            aria-label={`${item} Icon`}
          />
          <Text
            as="p"
            color="white"
            bottomSpacing="none"
            className={styles.listItemText}
            variant={isMobile ? 'heading-16' : 'heading-22'}
          >
            {item}
          </Text>
        </li>
      ))}
    </ul>
  );
};
