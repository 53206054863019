import { Button, Grid, Text } from '@farmersdog/corgi-x';
import * as pagePaths from '../../../constants/pagePaths';
import styles from './Breadcrumb.module.css';
import { BROWSE_FORMULAS_ID } from '../../../pages/Formulas';
import ChevronSvg from '../../../assets/icons/chevron.svg?react';

interface BreadcrumbProps {
  formulaName: string | null;
}

export const Breadcrumb = ({ formulaName }: BreadcrumbProps) => {
  return (
    <Grid alignItems="baseline">
      <Button
        variant="plain-text"
        type="link"
        to={{
          pathname: pagePaths.PATH_FORMULAS,
          hash: BROWSE_FORMULAS_ID,
        }}
        className={styles.link}
        color="kale"
        weight="bold"
        underline
      >
        Browse Formulas
        <ChevronSvg height={20} />
      </Button>
      <Text color="Charcoal3" bold>
        {formulaName}
      </Text>
    </Grid>
  );
};
