import { Button, Grid, GridItem, Text } from '@farmersdog/corgi-x';

import styles from './BrowseFormulasBanner.module.css';
import { PATH_FORMULAS } from '../../../constants';
import { trackClickedBrowseFormulas } from '../../../analytics/events';

export const BrowseFormulasBanner = () => {
  return (
    <section className={styles.container}>
      <Grid
        columnGap="lg"
        rowGap="sm"
        className={styles.grid}
        justifyContent={{
          xs: 'center',
        }}
      >
        <GridItem lg={8}>
          <Text
            color="kale-3"
            variant="article-16"
            as="p"
            topSpacing="none"
            bottomSpacing="none"
          >
            We offer ready to eat meals and home cooking nutrient mix. All of
            our formulas are held to the same high safety standards.
          </Text>
        </GridItem>
        <GridItem lg={4}>
          <Button
            type="link"
            to={PATH_FORMULAS}
            className={styles.button}
            onClick={trackClickedBrowseFormulas}
            variant="secondary"
          >
            Browse Formulas
          </Button>
        </GridItem>
      </Grid>
    </section>
  );
};
