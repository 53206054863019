import { track } from '../track';
import { clicked_core_tenet } from './eventNames';

/**
 * Send a tracking event for when a user clicked the core tenet option
 *
 * @param title - Core Tenet title
 */

export interface TrackClickedCoreTenetsValues {
  title: string;
}

export function trackClickedCoreTenets({
  title,
}: TrackClickedCoreTenetsValues) {
  track({
    eventName: clicked_core_tenet,
    eventProps: {
      title,
    },
  });
}
