import { Link } from '@farmersdog/corgi-x';
import ManufacturingAndFoodSafety from '../../../../static/pdf/ManufacturingandFoodSafety.pdf';
import VeterinaryGuide from '../../../../static/pdf/VeterinaryGuide.pdf';
import NutritionGuide from '../../../../static/pdf/TFD_Vet_Nutrition_Guide_2024.pdf';
import ClinicalIndications from '../../../../static/pdf/ClinicalIndicationsChart.pdf';
import * as pagePaths from '../../../constants/pagePaths';
const FEEDING_TRIAL_URL =
  'https://www.thefarmersdog.com/digest/how-the-farmers-dog-redesigned-the-feeding-trial/';
const WSAVA_URL =
  'https://www.thefarmersdog.com/digest/the-farmers-dog-and-wsavas-recommendations-for-picking-a-dog-food/';

interface Question {
  question: string;
  answer: string | React.ReactElement;
}

interface GetGeneralQuestionsArgs {
  showVeterinaryGuide: boolean;
}

export const getGeneralQuestions = ({
  showVeterinaryGuide,
}: GetGeneralQuestionsArgs): Question[] => {
  return [
    {
      question: 'Do you have nutritionists formulating the food?',
      answer: (
        <p>
          Yes, The Farmer’s Dog food has been formulated by a team of{' '}
          <Link href={pagePaths.PATH_OUR_TEAM} target="_blank">
            board-certified nutritionists
          </Link>{' '}
          since day one. We employ both PhD, board-certified companion animal
          nutritionists and Board Certified Veterinary Nutritionists®, ensuring
          the very best care for your patients.
        </p>
      ),
    },
    {
      question: 'Do you have nutritionists and veterinarians on staff?',
      answer: (
        <p>
          Yes, we not only have on-staff board-certified nutritionists
          formulating our complete and balanced recipes but also have
          experienced veterinarians on our team to ensure the health and
          well-being of your pets. Our{' '}
          <Link href={pagePaths.PATH_OUR_TEAM} target="_blank">
            dedicated veterinary team
          </Link>{' '}
          includes veterinarians and veterinary technicians who spent years in
          clinical practice and now works hard to provide education and
          resources to support veterinary professionals and pet parents. You can
          learn more about our veterinary professionals team here.{' '}
        </p>
      ),
    },
    {
      question: 'Is The Farmer’s Dog cooked?',
      answer:
        'Our fresh food is completely cooked, not raw. This means our food is fully cooked to eliminate harmful pathogens while retaining the nutritional value and integrity of the ingredients. ',
    },
    {
      ...(showVeterinaryGuide
        ? {
            question:
              'Where can I see the ingredients, nutritional information, and calorie content?',
            answer: (
              <>
                <p>
                  Visit <Link to={pagePaths.PATH_FORMULAS}>Formulas</Link> to
                  find detailed ingredients, nutritional information. and
                  calorie content for each of our formulas. If you want to know
                  specifics about your patient’s subscription, your clients can
                  easily find those details in their account. Each dog’s
                  calories and recipe information can be quickly accessed under
                  the &quot;My Dogs&quot; page. Additionally, you can ask your
                  clients to bring in their paper Feeding Guide that’s included
                  in each delivery. Our{' '}
                  <Link target="_blank" href={VeterinaryGuide}>
                    Veterinary Guide
                  </Link>{' '}
                  is a great place to learn more about how we formulate our
                  food, our philosophy on nutrition, and our nutrition research.
                </p>
                <p>
                  <i>
                    Pro tip: A simple way to estimate how many calories your
                    patient is consuming is by looking at the portion sizes
                    printed directly on each pack’s label. The number on the
                    bottom right corner reflects the approximate calorie content
                    (e.g. C10 = 1,000 calories/pack; F7 = 700 calories/pack). To
                    understand the exact calorie target for your patient, see
                    your patient&rsquo;s subscription details.
                  </i>
                </p>
              </>
            ),
          }
        : {
            question:
              'Where can I see the ingredients, nutritional information, and calorie content?',
            answer: (
              <>
                <p>
                  Our{' '}
                  <Link target="_blank" href={NutritionGuide}>
                    Nutrition Guide
                  </Link>{' '}
                  is a great place to find detailed nutritional information. If
                  you want to know specifics on your patient’s subscription,
                  your clients can easily find those details in their account
                  and show you on their phone during their appointment. Each
                  dog’s calories and recipe information can be quickly accessed
                  under the “My Dogs” page. Additionally, you can ask your
                  clients to bring in their paper Feeding Guide that’s included
                  in each delivery.
                </p>
                <p>
                  <i>
                    Pro tip: A simple way to estimate how many calories your
                    patient is consuming is by looking at the portion sizes
                    printed directly on each pack’s label. The number on the
                    bottom right corner reflects the approximate calorie content
                    (e.g. C10 = 1,000 calories/pack; F7 = 700 calories/pack). To
                    understand the exact calorie target for your patient, see
                    your patient&rsquo;s subscription details.
                  </i>
                </p>
              </>
            ),
          }),
    },

    {
      question:
        'Can I use The Farmer’s Dog for my patients that need specific therapeutic diets?',
      answer: (
        <>
          <p>
            We currently offer recipes that can be beneficial for a wide variety
            of your clinical cases. Visit our{' '}
            <Link target="_blank" href={ClinicalIndications}>
              Clinical Indications
            </Link>{' '}
            quick reference chart to learn about the many different clinical
            challenges that can be supported by putting your patients on The
            Farmer’s Dog. Naturally, this is in addition to the options we have
            for healthy dogs.
          </p>
        </>
      ),
    },
    {
      question: 'Where is The Farmer’s Dog fresh food made?',
      answer: (
        <>
          <p>
            Our fresh food is cooked in human-grade manufacturing facilities. We
            require our facilities to not only be FDA registered, but also to
            have a USDA Grant of Inspection for human food and actively make
            human food. If a manufacturing location does not meet these strict
            requirements, it’s not used to produce our products.
          </p>
          <p>
            In addition to third-party inspections, our food safety team
            conducts regular Food Safety Assessments on our facilities. These
            internal assessments consist of both desktop audits and in-person
            facility visits—including time spent on the manufacturing floor to
            ensure compliance with all of our production standards.
          </p>
          <p>
            You can read more about our manufacturing and food safety standards{' '}
            <Link target="_blank" href={ManufacturingAndFoodSafety}>
              here
            </Link>
            .
          </p>
        </>
      ),
    },
    {
      question: 'What about The Farmer’s Cat?',
      answer:
        'We love cats, too! While we don’t currently have fresh food for cats, joining our veterinary community is the best way for you to know when we are expanding our product line.',
    },
  ];
};

export const researchAndSafetyStandardQuestions: Question[] = [
  {
    question: 'Do you have research behind your nutrition?',
    answer:
      'Absolutely! Our nutrition is backed by extensive research, including a multi-year feeding study. We’ve also conducted third party testing to demonstrate the clinically proven digestibility, palatability, and RSS (relative supersaturation) indications. We are also actively progressing the field of veterinary nutrition by partnering with multiple leading veterinary colleges in ongoing studies to ensure we are continually doing what is best for dogs.',
  },
  {
    question: 'Did you perform a feeding trial for your food?',
    answer: (
      <>
        <p>
          Yes, we designed a{' '}
          <Link target="_blank" href={FEEDING_TRIAL_URL}>
            rigorous multi-year feeding study
          </Link>
          , going above and beyond the industry standards. AAFCO feeding-study
          tests are only applied to a small number of dogs of a single breed,
          and conducted in a closed laboratory kennel setting. Most
          significantly, the testing time frame is likely too narrow to show,
          meaningfully, whether the food meets the requirements to sustain good
          health. In contrast, our multi-year feeding study included dogs of all
          different breeds and ran for up to 6 years. While AAFCO feeding trials
          only test 4 blood values (hemoglobin, packed cell volume, alkaline
          phosphatase, and albumin), ours evaluated 49 blood values (including
          the 4 tested by AAFCO).
        </p>
      </>
    ),
  },
  {
    question: 'Is The Farmer’s Dog AAFCO complete and balanced?',
    answer:
      'Yes, all of our recipes are formulated by multiple board-certified nutritionists to be complete and balanced, according to AAFCO standards. Our complete and balanced recipes are made from simple, high-quality ingredients, plus added vitamins and minerals. ',
  },
  {
    question: 'Does The Farmer’s Dog meet WSAVA’s guidelines for pet food?',
    answer: (
      <>
        <p>
          Yes! Our food and manufacturing processes meet or exceed WSAVA’s
          guidelines for selecting pet food. Review our answers to the WSAVA
          questions{' '}
          <Link target="_blank" href={WSAVA_URL}>
            here
          </Link>
          . Because we believe pet owners should make informed, educated
          decisions about their pets’ health, we are also a Diamond Partner for
          the World Small Animal Veterinary Association (WSAVA).
        </p>
        <p>
          Diamond Partnership with WSAVA allows us to support to support
          numerous critical committees that influence the lives of veterinary
          professionals. These include committees dedicated to day-to-day
          clinical practice standards and teamwork, professional wellbeing, and
          helping professionals navigate the many external challenges that can
          impede veterinary medicine. Through this collaboration, we’re excited
          to contribute to promoting the well-being of both animals and
          veterinary professionals worldwide.
        </p>
      </>
    ),
  },
  {
    question:
      'I thought “human-grade” was a buzzword. What does it mean that The Farmer’s Dog is human-grade?',
    answer: (
      <>
        <p>
          We choose to make our food to human-grade standards because we
          prioritize safety. AAFCO set clear standards and guidelines for what
          constitutes “human-grade”, ensuring that the whole product (not just
          the individual ingredients) meets the same rigorous quality and safety
          standards as food intended for human consumption. This means
          compliance with regulations for ready-to-eat human food products,
          proper storage, handling and transportation throughout the entire
          supply chain and licensing requirements for facilities involved in the
          production process. That’s why we’re committed to preparing and
          packaging all of our nutritionist developed recipes in USDA-inspected
          facilities across the U.S where only human-grade food is produced.
          Food cannot be labeled as human-grade unless it meets these strict
          standards.
        </p>
        <p>
          You can read more about our manufacturing and food safety standards{' '}
          <Link target="_blank" href={ManufacturingAndFoodSafety}>
            here
          </Link>
          .
        </p>
      </>
    ),
  },
  {
    question: 'What does it mean that The Farmer’s Dog is 100% traceable?',
    answer:
      'This means we can know exactly where a product is and which dog is eating it within hours. This is another example of our intense dedication to safety. We deliver our food in portioned packs directly to the pet owner’s door. That ensures we maintain a comprehensive ability to track our food from the early steps of preparing ingredients through the cooking and packaging process all the way to your patient’s food bowl. This traceability is a great example of how we’re raising the bar on pet food safety.',
  },
  {
    question:
      'What safety measures do you take when making The Farmer’s Dog food?',
    answer: (
      <>
        <p>
          Our dedicated manufacturing and food safety teams are full of experts
          in food production, including microbiologists. They monitor food
          safety and quality assurance programs to ensure they meet human-food
          industry standards. This starts with selecting and auditing our
          ingredient suppliers and manufacturing locations.
        </p>
        <p>
          Our food is then fully cooked to eliminate potential pathogens (
          <i>Listeria, E. coli</i>, and <i>Salmonella</i> spp.) which is
          verified by following the International Commission on Microbiological
          Specifications for Foods statistically significant sampling plans at
          an International Organization for Standardization–certified
          third-party laboratory.
        </p>
        <p>
          <strong>Every batch</strong> of food is tested several times to ensure
          it meets our guaranteed analysis standards. <strong>Every lot</strong>{' '}
          of product is held at the manufacturing facility on a positive-release
          program. In-house nutritional confirmation and third-party microbial
          testing are performed while the food is quarantined, and all test
          results must be returned showing appropriate nutritional content with
          no evidence of microbial contamination before the product can be moved
          from the manufacturer to shipping and customer delivery.
        </p>
        <p>
          You can read more about our manufacturing and food safety standards{' '}
          <Link target="_blank" href={ManufacturingAndFoodSafety}>
            here
          </Link>
          .
        </p>
      </>
    ),
  },
  {
    question: 'How often do you batch test your food?',
    answer:
      'Every batch of food is tested several times to ensure it meets our guaranteed analysis standards. Every lot of product is held at the manufacturing facility on a positive-release program. We don’t cherry-pick or randomly select batches. Our food doesn’t get delivered to your patients until each batch is tested.',
  },
];
