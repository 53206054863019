import { Accordion, Grid } from '@farmersdog/corgi-x';
import type { NutritionData, ClinicalNutrition } from '../../../graphql/types';
import { DigestibilityPanel } from './DigestibilityPanel';
import styles from './TypicalNutritionals.module.css';
import { RSSValuesPanel } from './RSSValuesPanel';
import { EstimatedAnalysisPanel } from './EstimatedAnalysisPanel';
import { CalculatedCalorieContent } from './CalculatedCalorieContent';
import { onToggleTable } from '../utils';

export const TYPICAL_NUTRITIONALS_TEST_ID = 'typical-nutritionals-container';

export interface TypicalNutritionalsProps {
  calculatedCalorieContent: string;
  clinicalNutrition: ClinicalNutrition;
}

export const TypicalNutritionals = ({
  calculatedCalorieContent,
  clinicalNutrition,
}: TypicalNutritionalsProps) => {
  const freshPanels = (
    <>
      {clinicalNutrition?.digestibility &&
        clinicalNutrition?.digestibility.length > 0 && (
          <DigestibilityPanel
            onToggle={(isExpanded: boolean) =>
              onToggleTable({ isExpanded, title: 'digestibility' })
            }
            nutritionData={clinicalNutrition.digestibility as NutritionData[]}
          />
        )}
      {clinicalNutrition?.rss && clinicalNutrition?.rss.length > 0 && (
        <RSSValuesPanel
          onToggle={(isExpanded: boolean) =>
            onToggleTable({ isExpanded, title: 'rss values' })
          }
          nutritionData={clinicalNutrition.rss as NutritionData[]}
        />
      )}
    </>
  );

  const diyPanels = (
    <>
      {clinicalNutrition?.estimatedAnalysis &&
        clinicalNutrition?.estimatedAnalysis.length > 0 && (
          <EstimatedAnalysisPanel
            onToggle={(isExpanded: boolean) =>
              onToggleTable({ isExpanded, title: 'estimated analysis' })
            }
            nutritionData={
              clinicalNutrition.estimatedAnalysis as NutritionData[]
            }
          />
        )}
    </>
  );
  return (
    <Grid
      rowGap="lg"
      className={styles.container}
      data-testid={TYPICAL_NUTRITIONALS_TEST_ID}
    >
      {calculatedCalorieContent && (
        <CalculatedCalorieContent>
          {calculatedCalorieContent.replaceAll('(calculated)', '')}
        </CalculatedCalorieContent>
      )}
      {(clinicalNutrition?.digestibility || clinicalNutrition?.rss) && (
        <Accordion className={styles.accordion}>{freshPanels}</Accordion>
      )}
      {clinicalNutrition?.estimatedAnalysis && (
        <Accordion className={styles.accordion}>{diyPanels}</Accordion>
      )}
    </Grid>
  );
};
