import { Text } from '@farmersdog/corgi-x';
import styles from './Item.module.css';

interface ItemProps {
  percentage: string;
  description: string;
  type: string;
}

export const Item = ({ description, percentage, type }: ItemProps) => {
  return (
    <div className={styles.container} role="listitem">
      <div className={styles.percentage}>
        <Text variant="heading-22" bold color="Charcoal3">
          {percentage}
        </Text>
      </div>
      <Text variant="heading-16" topSpacing="sm">
        {description} ({type})
      </Text>
    </div>
  );
};
