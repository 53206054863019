import { useAuthContext } from '../../context';
import { useFormError } from '../FormError';

import { FormFields } from '../FormFields';
import { Text, Button } from '@farmersdog/corgi-x';

import {
  getErrorMessage,
  defaultErrorMessage,
} from '../../services/auth/utils/errors';

import * as pagePaths from '../../constants';
import {
  initialFormValues,
  loginFormFieldsData,
  loginFormValidationSchema,
} from './constants';

import styles from './LoginForm.module.css';
import { useDeepLink, useFormikWithFocusOnError } from '../../hooks';
import { Logger } from '@farmersdog/logger';
import { handleUnexpectedAuthError } from '../../services/auth/utils/handleUnexpectedAuthError';
import { isErrorWithMessage } from '../../utils/is-error-with-message';
import { trackLoginInTempPasswordState } from '../../analytics/events';
import { handleTempPasswordError } from '../../services/auth/utils/handleTempPasswordError';

export function LoginForm() {
  const { login, logout, isAuthenticated } = useAuthContext();

  const deepLink = useDeepLink();
  const { FormError, setFormErrorMessage, clearFormErrorMessage } =
    useFormError();

  const { isSubmitting, ...formik } = useFormikWithFocusOnError({
    initialValues: initialFormValues,
    validationSchema: loginFormValidationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      clearFormErrorMessage();
      try {
        if (!login) {
          throw new Error('No login function provided');
        }
        if (!logout) {
          throw new Error('No logout function provided');
        }
        if (isAuthenticated) {
          await logout();
        }
        await login({
          email: values.email,
          password: values.password,
          persistSession: true,
        });

        deepLink.push({
          defaultRedirectPath: pagePaths.PATH_DASHBOARD,
        });
      } catch (error: unknown) {
        if (isErrorWithMessage(error) && error.message === 'needsNewPassword') {
          const email = encodeURIComponent(values.email);

          const search = `?email=${email}`;

          setFormErrorMessage(
            <>
              Please follow the instructions in your email and set up a new
              password using a temporary code{' '}
              <Button
                weight="normal"
                type="link"
                to={`${pagePaths.PATH_LOGIN_SET_NEW_PASSWORD}${search}`}
                variant="plain-text"
                underline
                color="kale"
              >
                here
              </Button>
            </>
          );
          trackLoginInTempPasswordState({ email: values.email });
          handleTempPasswordError({ context: 'needs new password' });
          return;
        }
        const message = getErrorMessage(error);

        setFormErrorMessage(message);

        // default error message indicates an unexpected error
        if (message === defaultErrorMessage) {
          const logger = new Logger('auth:login');
          handleUnexpectedAuthError({ error, logger });
        }

        return;
      }
      setSubmitting(false);
    },
  });

  return (
    <div className={styles.formContainer}>
      <form onSubmit={formik.handleSubmit} method="POST">
        <div className={styles.inputSection}>
          {/* @ts-expect-error https://github.com/jaredpalmer/formik/issues/2023 */}
          <FormFields fieldData={loginFormFieldsData} formik={formik} />
        </div>
        <div className={styles.messagesContainer}>
          <FormError />
        </div>
        <div className={styles.buttonContainer}>
          <Button
            disabled={isSubmitting}
            variant="primary-mini-kale-experimental"
            className={styles.submitButton}
            type="submit"
          >
            Log In
          </Button>

          <Text
            as="p"
            variant="heading-16"
            className={styles.forgotPasswordButton}
          >
            <Button
              type="link"
              to={pagePaths.PATH_FORGOT_PASSWORD}
              variant="plain-text"
              color="kale"
              weight="normal"
            >
              Forgot Password?
            </Button>
          </Text>
        </div>
      </form>
    </div>
  );
}
