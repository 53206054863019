import type { PropsWithChildren, FC } from 'react';
import { useContext, useEffect, useState, createContext } from 'react';

import { Authenticator } from '@aws-amplify/ui-react';
import uniqueId from 'lodash/uniqueId';
import { identify } from '../analytics';
import { isCognitoAuthenticatedUserResult } from './constants/types';

import * as AuthService from '../services/auth';

import type {
  AuthContextProps,
  CognitoAttributes,
  CognitoAttributesSendCode,
  CognitoAttributesResetPassword,
  CognitoLogInSetNewPassword,
  CognitoAuthenticatedUserResult,
} from './constants';
import type { ToastProps } from '@farmersdog/corgi-x';
import { Toast, ToastSentiment } from '@farmersdog/corgi-x';
import { useCheckAuth } from '../hooks';
import { useRouteMatch } from 'react-router';
import { PATH_APP } from '../constants';
import type { SignInOutput } from 'aws-amplify/auth';

export const AuthContext = createContext<Partial<AuthContextProps>>({});

export const SESSION_EXPIRED_MESSAGE = 'Session has expired.';

export const AuthContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isUpdatingPassword, setIsUpdatingPassword] = useState<boolean>(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [refreshToken, setRefreshToken] = useState<string>('');
  const isLoggedInPage = useRouteMatch(PATH_APP);

  const logout = async () => {
    try {
      await AuthService.signout();
      setIsAuthenticated(false);
    } catch {
      // Do Nothing
    }
  };
  const { updateToken } = useCheckAuth({
    isLoading,

    onLogOut: async () => {
      if (isLoggedInPage) {
        setToast({
          message: SESSION_EXPIRED_MESSAGE,
          sentiment: ToastSentiment.Neutral,
          isOpen: true,
        });
      }
      await logout();
    },
  });

  const [toast, setToast] = useState<ToastProps>({
    message: '',
    sentiment: ToastSentiment.Neutral,
    isOpen: false,
  });

  const loginSetNewPassword = async ({
    email,
    newPassword,
    temporaryPassword,
  }: CognitoLogInSetNewPassword): Promise<
    CognitoAuthenticatedUserResult | SignInOutput
  > => {
    setIsLoading(true);

    const result = await AuthService.loginSetNewPassword({
      email,
      temporaryPassword,
      newPassword,
    });

    if (isCognitoAuthenticatedUserResult(result)) {
      if (result?.hasAlreadySetNewPassword) {
        await AuthService.signout();
        throw new Error('alreadySetNewPassword');
      }

      const userId = result.id;
      const userEmail = result.email;
      identify({ userId, identityTraits: { email: userEmail } });
      setIsAuthenticated(true);
      setRefreshToken(uniqueId());
      updateToken();
    }
    // If a user has already signed into the portal and tries to change the password by using their current password as the temporary code,
    // they will be signed in automatically, as this is how the temporary code is being validated on Cognito. Then, if the user goes to the login page and reloads
    // the page, they will have access to the platform. So, that's why this validation is added, and in the case the user is logged in, we will log them out of the application again.
    else if (result.isSignedIn) {
      await AuthService.signout();
    }

    setIsLoading(false);
    return result;
  };

  const login = async ({
    email,
    password,
    persistSession,
  }: CognitoAttributes) => {
    if (persistSession) {
      setIsLoading(true);
      const result = await AuthService.login({ email, password });

      if (result) {
        if (result?.needsNewPassword) {
          throw new Error('needsNewPassword');
        } else {
          const userId = result.id;
          const userEmail = result.email;
          identify({ userId, identityTraits: { email: userEmail } });
          setIsAuthenticated(true);
          setRefreshToken(uniqueId());
          updateToken();
        }
      }
      setIsLoading(false);
    } else {
      const result = await AuthService.login({ email, password });

      if (result) {
        const userId = result.id;
        const userEmail = result.email;
        identify({ userId, identityTraits: { email: userEmail } });
      }
    }
  };

  const signup = async ({ email, password }: CognitoAttributes) => {
    setIsLoading(true);
    try {
      await AuthService.signup({ email, password });
      // eslint-disable-next-line no-useless-catch
    } catch (error) {
      throw error;
    } finally {
      setIsLoading(false);
    }
  };

  const updatePassword = async ({ email }: CognitoAttributesSendCode) => {
    setIsUpdatingPassword(true);
    try {
      await AuthService.forgotPassword({ email });
      // eslint-disable-next-line no-useless-catch
    } catch (error) {
      throw error;
    } finally {
      setIsUpdatingPassword(false);
    }
  };

  const forgotPassword = async ({ email }: CognitoAttributesSendCode) => {
    await AuthService.forgotPassword({ email });
  };

  const resetPassword = async ({
    code,
    email,
    password,
  }: CognitoAttributesResetPassword) => {
    await AuthService.resetPassword({ code, email, password });
  };

  const assertAuth = async () => {
    setIsLoading(true);
    const result = await AuthService.getCurrentAuthenticatedUser();

    if (result) {
      setIsAuthenticated(true);
      setRefreshToken(uniqueId());
    } else {
      setIsAuthenticated(false);
      setRefreshToken('');
    }

    setIsLoading(false);
  };

  const deleteUser = async () => {
    await AuthService.deleteUser();
    await logout();
  };

  useEffect(() => {
    void assertAuth();
  }, []);

  return (
    <Authenticator.Provider key={refreshToken}>
      <Toast {...toast} autoClose onClose={() => setToast({ isOpen: false })} />

      <AuthContext.Provider
        value={{
          assertAuth,
          login,
          loginSetNewPassword,
          signup,
          forgotPassword,
          logout,
          resetPassword,
          updatePassword,
          isLoading,
          isUpdatingPassword,
          isAuthenticated,
          deleteUser,
        }}
      >
        {children}
      </AuthContext.Provider>
    </Authenticator.Provider>
  );
};
export const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuthContext must be used with AuthContextProvider');
  } else {
    return context;
  }
};
