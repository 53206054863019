import type { ReactNode } from 'react';

import { Grid, GridItem, PageWidth } from '@farmersdog/corgi-x';
import { AccountHeader } from '../AccountHeader';
import { PortalBanner } from '../PortalBanner';
import { FullFooter } from '../FullFooter';
import { useVetDetails } from '../../graphql/hooks/useVetDetails';

import styles from './AccountPage.module.css';
import { usePublicPageFeatures } from '../../abTesting/PublicPageFeatures';
import classNames from 'classnames';
import { useScreenSizeContext } from '../../context/ScreenSizeContext';

interface PageProps {
  children: ReactNode;
  fullWidth?: boolean;
  accountBanner?: ReactNode;
  backgroundOverride?: 'kale' | 'white' | 'blueberry';
  skipPageWidthConstraint?: boolean;
  skipBottomPadding?: boolean;
}

export function AccountPage({
  accountBanner,
  children,
  fullWidth = false,
  backgroundOverride,
  skipPageWidthConstraint = false,
  skipBottomPadding = false,
}: PageProps) {
  const { data } = useVetDetails();

  const { isMobile } = useScreenSizeContext();

  const discountRequests = data?.vetDetails?.vet?.discountRequests || [];

  const {
    showPortalBanner,
    bannerConfig,
    showPortalUXOverhaul,
    showAllFormulasDiscovery,
  } = usePublicPageFeatures();

  const backgroundClassnames = backgroundOverride
    ? classNames({
        [styles[`${backgroundOverride}Background`]]: true,
      })
    : classNames({
        [styles.kaleBackground]: !fullWidth && !showPortalUXOverhaul,
        [styles.whiteBackground]: fullWidth && !showPortalUXOverhaul,
        [styles.blueberryBackground]: showPortalUXOverhaul,
      });

  return (
    <>
      {showPortalBanner && (
        <PortalBanner
          link={bannerConfig?.link ?? ''}
          buttonVerbiage={bannerConfig?.buttonVerbiage ?? ''}
          textVerbiage={bannerConfig?.textVerbiage ?? ''}
        />
      )}
      <AccountHeader discountRequests={discountRequests} isMobile={isMobile} />
      {!showAllFormulasDiscovery && accountBanner}
      <div className={backgroundClassnames}>
        <PageWidth enabled={!skipPageWidthConstraint}>
          <Grid
            justifyContent="center"
            bottomSpacing={skipBottomPadding ? 'none' : 'xxl'}
            topSpacing="xxl"
            className={classNames({
              [styles.content]: !fullWidth,
              [styles.verticalSpacingOverride]:
                !skipBottomPadding && showPortalUXOverhaul,
            })}
          >
            <GridItem
              xl={8}
              className={classNames({
                [styles.fullWidth]: fullWidth,
              })}
            >
              {children}
            </GridItem>
          </Grid>
        </PageWidth>
      </div>
      <FullFooter isAccountPage={true} />
    </>
  );
}
