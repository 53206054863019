import { pdfSectionData } from '../pages/Resources/ResourcesContent/PdfSection/pdfSectionData';
import { veterinaryGuideSectionData } from '../pages/Resources/ResourcesContent/VeterinaryGuideCard/VeterinaryGuideSectionData';
import { nutritionGuideSectionData } from '../pages/Resources/ResourcesContent/NutritionGuideCard/NutritionGuideSectionData';
import { useQueryParameters } from './useQueryParameters';
import { useHistory } from 'react-router';

type UseRequestResourcePreviewReturnType = {
  title: string;
  description: string;
  link: string;
  thumbnailSources: string[];
};

export const useRequestedResourcePreview =
  (): UseRequestResourcePreviewReturnType | null => {
    const search = useQueryParameters();
    const history = useHistory();
    const path = history.location.pathname;
    const requestedResource = search.get('resource');

    if (!requestedResource) {
      return null;
    }

    if (
      requestedResource === nutritionGuideSectionData.urlParam &&
      !path.includes('downloads')
    ) {
      return nutritionGuideSectionData;
    }

    if (
      requestedResource === veterinaryGuideSectionData.urlParam &&
      !path.includes('downloads')
    ) {
      return veterinaryGuideSectionData;
    }

    const resources = pdfSectionData.flatMap(pdf => pdf.pdfs);

    const resource = resources.find(
      pdf => pdf.urlParam.toLowerCase() === requestedResource.toLowerCase()
    );

    if (!resource) {
      return null;
    }

    return resource;
  };
