import { useState } from 'react';
import { FormError as FormErrorComponent } from '../FormError';

export function useFormError() {
  const [formErrorMessage, setFormErrorMessage] = useState<React.ReactNode>('');

  const FormError = () => <FormErrorComponent message={formErrorMessage} />;

  const clearFormErrorMessage = () => setFormErrorMessage('');

  return { FormError, setFormErrorMessage, clearFormErrorMessage };
}
