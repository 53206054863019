import topWebp from './top.webp';
import topJpg from './top.jpg';
import topJp2 from './top.jp2';
import leftWebp from './left.webp';
import leftJp2 from './left.jp2';
import leftJpg from './left.jpg';
import rightWebp from './right.webp';
import rightJp2 from './right.jp2';
import rightJpg from './right.jpg';

export const TreatsDefaultSources = {
  top: [topWebp, topJp2, topJpg],
  left: [leftWebp, leftJp2, leftJpg],
  right: [rightWebp, rightJp2, rightJpg],
};
