import tfdAndWSAVAThumbnailPng from '../assets/TfdAndWsavaThumbnail.png';
import tfdAndWSAVAThumbnailJpg from '../assets/TfdAndWsavaThumbnail.jpg';
import tfdAndWSAVAThumbnailWebp from '../assets/TfdAndWsavaThumbnail.webp';
import tfdAndWSAVA from '../../../../../static/pdf/TFD_WSAVA_Onesheet.pdf';

import wsavaThumbnailPng from '../assets/WsavaThumbnail.png';
import wsavaThumbnailJpg from '../assets/WsavaThumbnail.jp2';
import wsavaThumbnailWebp from '../assets/WsavaThumbnail.webp';
import tfdAndWSAVAGuidelines from '../../../../../static/pdf/TFD_WSAVA_MEETING_GUIDELINES.pdf';

import FoodAndSafetyThumbnailPng from '../assets/FoodSafetyThumbnail.png';
import FoodAndSafetyThumbnailJpg from '../assets/FoodSafetyThumbnail.jpg';
import FoodAndSafetyThumbnailWebp from '../assets/FoodSafetyThumbnail.webp';
import manufacturingandFoodSafety from '../../../../../static/pdf/ManufacturingandFoodSafety.pdf';

import ClinicalIndicationsThumbnailPng from '../assets/ClinicalIndicationsThumbnail.png';
import ClinicalIndicationsThumbnailJpg from '../assets/ClinicalIndicationsThumbnail.jpg';
import ClinicalIndicationsThumbnailWebp from '../assets/ClinicalIndicationsThumbnail.webp';
import clinicalIndicationsChart from '../../../../../static/pdf/ClinicalIndicationsChart.pdf';

import EliminationDietThumbnailPng from '../assets/EliminationDietThumbnail.png';
import EliminationDietThumbnailJpg from '../assets/EliminationDietThumbnail.jpg';
import EliminationDietThumbnailWebp from '../assets/EliminationDietThumbnail.webp';
import performEliminationDietTrialsFile from '../../../../../static/pdf/PerformEliminationDietTrials.pdf';

import UnderstandingFreshThumbnailPng from '../assets/UnderstandingFreshThumbnail.png';
import UnderstandingFreshThumbnailJpg from '../assets/UnderstandingFreshThumbnail.jpg';
import UnderstandingFreshThumbnailWebp from '../assets/UnderstandingFreshThumbnail.webp';
import understandingFreshNutritionFile from '../../../../../static/pdf/TFD_Understanding-Fresh-Nutrition.pdf';

import DcmThumbmnailPng from '../assets/DcmThumbnail.png';
import DcmThumbmnailJpg from '../assets/DcmThumbnail.jpg';
import DcmThumbmnailWebp from '../assets/DcmThumbnail.webp';
import decodingCanineDCMFile from '../../../../../static/pdf/DecodingCanineDCM.pdf';

import FeedingTrialThumbnailPng from '../assets/FeedingTrialThumbnail.png';
import FeedingTrialThumbnailJpg from '../assets/FeedingTrialThumbnail.jpg';
import FeedingTrialThumbnailWebp from '../assets/FeedingTrialThumbnail.webp';
import feedingTrialAffidavitsFile from '../../../../../static/pdf/Feeding-Trial-Affidavits.pdf';

import DigestibilityThumbnailPng from '../assets/DigestibilityThumbnail.png';
import DigestibilityThumbnailJpg from '../assets/DigestibilityThumbnail.jpg';
import DigestibilityThumbnailWebp from '../assets/DigestibilityThumbnail.webp';
import digestibilityCoreMetricFile from '../../../../../static/pdf/TFD_Digestibility-Onesheet.pdf';

import UrinarySaturationThumbnailPng from '../assets/UnderstandingRSSThumbnail.png';
import UrinarySaturationThumbnailJpg from '../assets/UnderstandingRSSThumbnail.jpg';
import UrinarySaturationThumbnailWebp from '../assets/UnderstandingRSSThumbnail.webp';
import urinaryHealthFile from '../../../../../static/pdf/TFD_Understanding-RSS.pdf';

import WSAVAThumbnailPaytonPng from '../assets/WSAVAThumbnailPayton.jp2';
import WSAVAThumbnailPaytonJpg from '../assets/WSAVAThumbnailPayton.png';
import WSAVAThumbnailPaytonWebp from '../assets/WSAVAThumbnailPayton.webp';
import WSAVAPaytonFile from '../../../../../static/pdf/TFD_VPAR_2025_CaseStudy_Payton.pdf';

import WSAVAThumbnailGuinnyPng from '../assets/WSAVAThumbnailGuinny.jp2';
import WSAVAThumbnailGuinnyJpg from '../assets/WSAVAThumbnailGuinny.png';
import WSAVAThumbnailGuinnyWebp from '../assets/WSAVAThumbnailGuinny.webp';
import WSAVAGuinnyFile from '../../../../../static/pdf/TFD_VPAR_2025_CaseStudy_Guinny.pdf';

import WSAVAThumbnailHatchettePng from '../assets/WSAVAThumbnailHatchette.jp2';
import WSAVAThumbnailHatchetteJpg from '../assets/WSAVAThumbnailHatchette.png';
import WSAVAThumbnailHatchetteWebp from '../assets/WSAVAThumbnailHatchette.webp';
import WSAVAHatchetteFile from '../../../../../static/pdf/TFD_VPAR_2025_CaseStudy_Hatchette.pdf';

export interface Pdf {
  title: string;
  description: string;
  link: string;
  thumbnailSources: string[];
  urlParam: string;
}
interface PdfData {
  defaultExpanded: boolean;
  sectionHeading: string;
  sectionDescription: string | null;
  pdfs: Pdf[];
}
export const pdfSectionData: PdfData[] = [
  {
    defaultExpanded: true,
    sectionHeading: 'Getting to Know Us',
    sectionDescription:
      'Our food is complete and balanced according to AAFCO standards and made in FDA-compliant facilities, to the same quality-control standards as human food.',
    pdfs: [
      {
        title: 'WSAVA and The Farmer’s Dog Partnership',
        description:
          'The Farmer’s Dog is a Diamond Partner of the World Small Animal Veterinary Association (WSAVA). Learn more about the ways we support key committees and initiatives.',
        link: tfdAndWSAVA,
        thumbnailSources: [
          tfdAndWSAVAThumbnailWebp,
          tfdAndWSAVAThumbnailJpg,
          tfdAndWSAVAThumbnailPng,
        ],
        urlParam: 'tfd-and-wsava',
      },
      {
        title: 'How The Farmer’s Dog Meets WSAVA Guidelines for Pet Food',
        description:
          'These guidelines focus on evaluating each company in relation to their nutritional expertise, food safety and quality control practices, and whether the company is active in nutrition research.',
        link: tfdAndWSAVAGuidelines,
        thumbnailSources: [
          wsavaThumbnailWebp,
          wsavaThumbnailJpg,
          wsavaThumbnailPng,
        ],
        urlParam: 'tfd-and-wsava-guidelines',
      },
      {
        title: 'The Farmer’s Dog Manufacturing and Food Safety',
        description:
          'The Farmer’s Dog is raising the bar on every aspect of pet food, with a particular focus on manufacturing and food safety. Learn more about our production and safety methods methods.',
        link: manufacturingandFoodSafety,
        thumbnailSources: [
          FoodAndSafetyThumbnailWebp,
          FoodAndSafetyThumbnailJpg,
          FoodAndSafetyThumbnailPng,
        ],
        urlParam: 'manufacturing-and-food-safety',
      },
    ],
  },
  {
    defaultExpanded: false,
    sectionHeading: 'Patient Care',
    sectionDescription: null,
    pdfs: [
      {
        title: 'Clinical Indications',
        description:
          "Learn more about how The Farmer's Dog can be used therapeutically to support your patients with medical conditions.",
        link: clinicalIndicationsChart,
        thumbnailSources: [
          ClinicalIndicationsThumbnailWebp,
          ClinicalIndicationsThumbnailJpg,
          ClinicalIndicationsThumbnailPng,
        ],
        urlParam: 'clinical-indications',
      },
      {
        title:
          "How to Perform Elimination Diet Trials Using The Farmer's Dog Recipes",
        description:
          'Our human-grade manufacturing process avoid the risk of cross-contamination and introduction of unwanted allergens, making our recipes great options for dogs with food sensitivities.',
        link: performEliminationDietTrialsFile,
        thumbnailSources: [
          EliminationDietThumbnailWebp,
          EliminationDietThumbnailJpg,
          EliminationDietThumbnailPng,
        ],
        urlParam: 'elimination-diet',
      },
      {
        title: 'Case Study:  A Refeeding Plan for a Parvo Dog',
        description:
          'Payton: How The Farmer’s Dog chicken formula was utilized in a refeeding plan  in a malnourished, hypokalemic dog with Parvovirus.',
        link: WSAVAPaytonFile,
        thumbnailSources: [
          WSAVAThumbnailPaytonWebp,
          WSAVAThumbnailPaytonJpg,
          WSAVAThumbnailPaytonPng,
        ],
        urlParam: 'Payton',
      },
      {
        title: 'Case Study: A Weight-Management Plan for a Brachycephalic Dog',
        description:
          'Guinny: How The Farmer’s Dog formulas were utilized for weight management for an overweight dog with brachycephalic airway syndrome and an abdominal wall hernia.',
        link: WSAVAGuinnyFile,
        thumbnailSources: [
          WSAVAThumbnailGuinnyWebp,
          WSAVAThumbnailGuinnyPng,
          WSAVAThumbnailGuinnyJpg,
        ],
        urlParam: 'Guinny',
      },
      {
        title: 'Case Study: A Fresh Approach to Managing Gastritis',
        description:
          'Hatchette: How The Farmer’s Dog turkey formula was utilized in a novel protein diet trial for a dog with chronic gastritis and hypotrichosis.',
        link: WSAVAHatchetteFile,
        thumbnailSources: [
          WSAVAThumbnailHatchetteWebp,
          WSAVAThumbnailHatchettePng,
          WSAVAThumbnailHatchetteJpg,
        ],
        urlParam: 'Hatchette',
      },
    ],
  },
  {
    defaultExpanded: false,
    sectionHeading: 'Research',
    sectionDescription: null,
    pdfs: [
      {
        title: 'Feeding Study Affidavits',
        description:
          'Affidavits from veterinarians who oversaw our feeding studies.',
        link: feedingTrialAffidavitsFile,
        thumbnailSources: [
          FeedingTrialThumbnailWebp,
          FeedingTrialThumbnailJpg,
          FeedingTrialThumbnailPng,
        ],
        urlParam: 'feeding-study-affidavits',
      },
      {
        title: 'Understanding Fresh Nutrition',
        description:
          'Learn how to evaluate the nutritional content of fresh diets. Because key characteristics of the food are different, fresh can’t be compared like for like with kibble.',
        link: understandingFreshNutritionFile,
        thumbnailSources: [
          UnderstandingFreshThumbnailWebp,
          UnderstandingFreshThumbnailJpg,
          UnderstandingFreshThumbnailPng,
        ],
        urlParam: 'understanding-fresh',
      },
      {
        title: 'Digestibility: A Core Nutritional Metric',
        description:
          "A key measure of a pet food’s quality, affecting not just how much stool is produced each day but a dog’s long-term well-being. The Farmer's Dog has been clinically proven through multiple third-party studies to be more digestible than the average extruded kibble.",
        link: digestibilityCoreMetricFile,
        thumbnailSources: [
          DigestibilityThumbnailWebp,
          DigestibilityThumbnailJpg,
          DigestibilityThumbnailPng,
        ],
        urlParam: 'digestibility',
      },
      {
        title:
          'Urinary Health: Understanding Urinary Relative Supersaturation (RSS)',
        description:
          "Relative supersaturation (RSS) is considered the gold standard for risk assessment of crystal formation (struvite and oxalate) in urine for dogs. We've done RSS studies on all our formulas so you can have peace of mind with your patients.",
        link: urinaryHealthFile,
        thumbnailSources: [
          UrinarySaturationThumbnailWebp,
          UrinarySaturationThumbnailJpg,
          UrinarySaturationThumbnailPng,
        ],
        urlParam: 'urinary-health',
      },
      {
        title: 'Decoding Canine DCM: The Truth Behind the Headlines',
        description:
          'In order to address the most persistent misunderstandings, we’ve compiled statements from the FDA along with the latest peer-reviewed research, and commentary from board-certified nutritionists.',
        link: decodingCanineDCMFile,
        thumbnailSources: [
          DcmThumbmnailWebp,
          DcmThumbmnailJpg,
          DcmThumbmnailPng,
        ],
        urlParam: 'decoding-canine-dcm',
      },
    ],
  },
];
