import { Button, Grid, GridItem, Text } from '@farmersdog/corgi-x';
import { HeroList } from './HeroList';
import { Nav } from './Nav';

import styles from './Hero.module.css';
import { trackLandingPageClickedRegisterForVetTeamPortalHero } from '../../../analytics/events';
import { PATH_SIGNUP } from '../../../constants';

const title = (
  <>
    Your Partners <br /> in Dog Health
  </>
);

const description = (
  <>
    Register to access our formulas, ingredients, veterinary guide, discounts,
    and nutrition research.
  </>
);

interface ResponsiveHeroProps {
  isMobile?: boolean;
  onLinkClick?: () => void;
}

function ResponsiveHero({ isMobile, onLinkClick }: ResponsiveHeroProps) {
  return (
    <Grid
      bottomSpacing={{
        xs: 'xxl',
        lg: 'none',
      }}
      justifyContent="center"
      className={styles.heroContainer}
    >
      <GridItem lg={6}>
        <div className={styles.textContainer}>
          <Text
            bold
            as="h1"
            color="white"
            topSpacing={{
              xs: 'xxl',
              lg: 'none',
            }}
            bottomSpacing="none"
            variant={isMobile ? 'heading-40' : 'heading-72'}
          >
            {title}
          </Text>
          <Text
            as="h3"
            color="white"
            variant={isMobile ? 'heading-22' : 'heading-28'}
          >
            {description}
          </Text>

          <Button
            className={styles.registerButton}
            type="link"
            to={PATH_SIGNUP}
            onClick={() => {
              trackLandingPageClickedRegisterForVetTeamPortalHero();
            }}
          >
            Join Vet Team Portal
          </Button>
        </div>
      </GridItem>
      <GridItem lg={6} justifyContent="center" className={styles.listContainer}>
        <HeroList isMobile={isMobile} />
      </GridItem>
      <Nav onLinkClick={onLinkClick} />
    </Grid>
  );
}

interface HeroProps {
  onLinkClick?: () => void;
}

export function Hero({ onLinkClick }: HeroProps) {
  return (
    <>
      <div className={styles.mobile}>
        <ResponsiveHero isMobile onLinkClick={onLinkClick} />
      </div>
      <div className={styles.desktop}>
        <ResponsiveHero onLinkClick={onLinkClick} />
      </div>
    </>
  );
}
