import type { GraphQLFormattedError } from 'graphql';
import { isErrorWithName, isErrorWithNameAndMessage } from '../../../utils';
import { ApolloError } from '@apollo/client';

export const errorCodes = {
  USER_ALREADY_EXISTS: 'UsernameExistsException',
  INVALID_PASSWORD: 'InvalidPasswordException',
  USER_NOT_FOUND: 'UserNotFoundException',
  NOT_AUTHORIZED: 'NotAuthorizedException',
  EXPIRED_CODE: 'ExpiredCodeException',
  INVALID_PARAM: 'InvalidParameterException',
  NETWORK_ERROR: 'NetworkError',
} as const;

export const newPasswordErrors = {
  NOT_AUTHORIZED_EXPIRED_TEMPORARY_PASSWORD:
    'Temporary password has expired and must be reset by an administrator.',
  ALREADY_SET_NEW_PASSWORD: 'alreadySetNewPassword',
  VET_DOES_NOT_EXIST_ERROR: 'Vet does not exist',
} as const;

export const errorMessages = new Map<string, string>([
  [errorCodes.USER_ALREADY_EXISTS, 'Looks like you’re already signed up.'],
  [
    errorCodes.INVALID_PASSWORD,
    'Password does not meet the minimum requirement of 6 characters.',
  ],
  [
    errorCodes.USER_NOT_FOUND,
    'Sorry, but the email or password you entered was incorrect.',
  ],
  [
    errorCodes.NOT_AUTHORIZED,
    'Sorry, but the email or password you entered was incorrect.',
  ],
  [
    errorCodes.EXPIRED_CODE,
    'Invalid code provided, please request a code again.',
  ],
]);

const errorMessagesResetPassword = new Map<string, string>([
  [errorCodes.USER_NOT_FOUND, 'The email you entered is invalid.'],
  [errorCodes.EXPIRED_CODE, 'The code you entered is invalid'],
]);

export const newPasswordErrorMessages = new Map<string, string>([
  [
    newPasswordErrors.NOT_AUTHORIZED_EXPIRED_TEMPORARY_PASSWORD,
    "Your temporary code is expired, but we've emailed you a new one",
  ],
]);

export const isForgotPasswordAlreadyRequestedError = (error: unknown) => {
  return (
    isErrorWithNameAndMessage(error) &&
    error.name === errorCodes.NOT_AUTHORIZED &&
    error.message === 'User password cannot be reset in the current state.'
  );
};

export const defaultErrorMessage = 'An unknown error has occurred';

export function getErrorMessage(
  error: unknown,
  isInResetPasswordScreen = false
) {
  if (isErrorWithName(error)) {
    if (isInResetPasswordScreen) {
      return errorMessagesResetPassword.get(error.name) ?? defaultErrorMessage;
    }

    if (error instanceof ApolloError) {
      const errorWithNameInExtensions =
        error.graphQLErrors.find(findNameInExtensions);
      if (errorWithNameInExtensions) {
        return (
          errorMessages.get(
            errorWithNameInExtensions.extensions?.name as string
          ) ?? defaultErrorMessage
        );
      }
    }

    return errorMessages.get(error.name) ?? defaultErrorMessage;
  }

  return defaultErrorMessage;
}

const findNameInExtensions = (error: GraphQLFormattedError) => {
  return error.extensions?.name;
};
