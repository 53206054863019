import { Modal } from '@farmersdog/corgi';
import styles from './RecommendToClient.module.css';
import { LightboxId, useGlobalLightbox } from '../../../hooks';
import { Button, Text } from '@farmersdog/corgi-x';
import referralHandout from '../../../../static/pdf/ReferralHandout.pdf';
import {
  trackDownloadableInfo,
  trackRecommendToClientCTA,
} from '../../../analytics/events';

export const RecommendToClient = () => {
  const modalProps = useGlobalLightbox({
    id: LightboxId.RecommendToClient,
  });

  const { labelProps } = modalProps;

  const onRecommendToClient = () => {
    modalProps.open();
    trackRecommendToClientCTA();
  };

  return (
    <>
      <Modal {...modalProps}>
        <Text
          bold
          as="h3"
          topSpacing="none"
          bottomSpacing="sm"
          color="blueberry-3"
          variant="heading-28"
          className={styles.title}
          {...labelProps}
        >
          Recommend to Client
        </Text>
        <Text as="p" topSpacing="none">
          Thank you for your interest! We are working with vet pros to build an
          effortless online solution for creating custom plans for your clients
          - coming soon!{' '}
        </Text>
        <Text as="p" topSpacing="none" bottomSpacing="md">
          In the meantime, you can use our <strong>referral handout.</strong>{' '}
          It’s great for printing or digital sharing.
        </Text>
        <Button
          target="_blank"
          variant={'primary-mini-kale-experimental'}
          type="anchor"
          href={referralHandout}
          onClick={() =>
            trackDownloadableInfo({ title: 'Referral Handout One-Pager' })
          }
          aria-label="View Vet Pro Client Referral Handout PDF"
        >
          View Handout
        </Button>
      </Modal>
      <Button
        className={styles.recommendClient}
        variant="primary-mini-kale-experimental"
        onClick={onRecommendToClient}
      >
        Recommend to Client
      </Button>
    </>
  );
};
