import type { SignInOutput } from 'aws-amplify/auth';

// Cognito & Amplify
export interface CognitoAttributes {
  email: string;
  password: string;
  persistSession?: boolean;
}

export interface CognitoAttributesResetPassword extends CognitoAttributes {
  code: string;
}
export interface CognitoAttributesSendCode {
  email: string;
}

export interface CognitoAuthenticatedUserResult {
  token: string;
  id: string;
  email: string;
  needsNewPassword?: boolean;
  hasAlreadySetNewPassword?: boolean;
}

export interface CognitoLogInSetNewPassword {
  email: string;
  newPassword: string;
  temporaryPassword: string;
}

// Context
export type AuthContextProps = {
  isLoading?: boolean;
  isAuthenticated?: boolean;
  isUpdatingPassword: boolean;
  assertAuth(): Promise<void>;
  login({ email, password, persistSession }: CognitoAttributes): Promise<void>;
  loginSetNewPassword({
    email,
    newPassword,
    temporaryPassword,
  }: CognitoLogInSetNewPassword): Promise<
    CognitoAuthenticatedUserResult | SignInOutput
  >;
  signup({ email, password }: CognitoAttributes): Promise<void>;
  forgotPassword({ email }: CognitoAttributesSendCode): Promise<void>;
  updatePassword({ email }: CognitoAttributesSendCode): Promise<void>;
  resetPassword({
    code,
    email,
    password,
  }: CognitoAttributesResetPassword): Promise<void>;
  logout(): Promise<void>;
  deleteUser(): Promise<void>;
};

export function isCognitoAuthenticatedUserResult(
  result: unknown
): result is CognitoAuthenticatedUserResult {
  return (
    Object.prototype.hasOwnProperty.call(result, 'id') &&
    Object.prototype.hasOwnProperty.call(result, 'email') &&
    Object.prototype.hasOwnProperty.call(result, 'token')
  );
}
