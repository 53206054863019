import {
  Button,
  Fieldset,
  Grid,
  GridItem,
  RadioButton,
  Text,
} from '@farmersdog/corgi-x';
import { AccountPage } from '../../components/AccountPage';

import * as pagePaths from '../../constants/pagePaths';

import type { ResponsiveProps } from '../../constants';
import { useState, useEffect } from 'react';
import { ContactUsForm } from '../../components/ContactUsFormNew';
import { Card } from '../../components';
import { ExternalFormLink } from '../../components/ContactUsFormNew/ExternalFormLink';
import { usePublicPageFeatures } from '../../abTesting/PublicPageFeatures';
import { EmailSection, EmailSectionLegacy } from './ContactUsEmailSection';
import { useQueryParameters } from '../../hooks';
import { CONTACT_REASON_QUERY_PARAM } from '../../components/ContactForAlternativeFormulas';

import styles from './ContactUs.module.css';

export const radioButtonValues = {
  lunchAndLearn: 'lunch_and_learn',
  universityVisit: 'university_visit',
  partnership: 'partnership',
  question: 'question',
  other: 'other',
};

export const contactReasons = {
  lunchAndLearn: 'I would like to request education/lunch & learn for my staff',
  universityVisit:
    'I would like to request education for my university / students',
  partnership: 'I would like to partner with TFD',
  question: 'I have a question about your formulas',
  other: 'Other',
};

const radioButtons = [
  {
    name: 'Question Button',
    label: contactReasons.question,
    value: radioButtonValues.question,
  },
  {
    name: 'Lunch and Learn Button',
    label: contactReasons.lunchAndLearn,
    value: radioButtonValues.lunchAndLearn,
  },
  {
    name: 'University Visit Button',
    label: contactReasons.universityVisit,
    value: radioButtonValues.universityVisit,
  },
  {
    name: 'Partnership Button',
    label: contactReasons.partnership,
    value: radioButtonValues.partnership,
  },
  {
    name: 'Other Button',
    label: contactReasons.other,
    value: radioButtonValues.other,
  },
];

export const ContactUsResponsive = ({ isMobile }: ResponsiveProps) => {
  const query = useQueryParameters();
  const contactReasonQueryParam = query.get(CONTACT_REASON_QUERY_PARAM);

  const [selectedRadioOption, setSelectedRadioOption] = useState('');
  const [selectedContactReason, setSelectedContactReason] = useState('');
  const { showPortalUXOverhaul } = usePublicPageFeatures();

  const cardHeaderContent = showPortalUXOverhaul
    ? 'Select Your Reason for Contact'
    : 'Contact Us';

  const cardSubHeaderContent = showPortalUXOverhaul
    ? 'Then complete the form, and we’ll get back to you shortly!'
    : 'Select your reason for contact, then complete the form, and we’ll get back to you shortly!';

  const handleRadioClick = (value: string, contactReason: string) => {
    setSelectedRadioOption(value);
    setSelectedContactReason(contactReason);
  };

  useEffect(() => {
    if (
      contactReasonQueryParam &&
      Object.values(radioButtonValues).includes(contactReasonQueryParam)
    ) {
      const targetRadio = radioButtons.find(
        ({ value }) => value === contactReasonQueryParam
      );
      if (targetRadio) {
        setSelectedRadioOption(targetRadio.value);
        setSelectedContactReason(targetRadio.label);
      }
    }
  }, [query, contactReasonQueryParam]);

  const renderDynamicForm = () => {
    const showLunchAndLearnRequestFormLink = [
      radioButtonValues.lunchAndLearn,
      radioButtonValues.universityVisit,
    ].some(value => value === selectedRadioOption);

    const showContactUsForm = [
      radioButtonValues.partnership,
      radioButtonValues.question,
      radioButtonValues.other,
    ].some(value => value === selectedRadioOption);

    if (showLunchAndLearnRequestFormLink) {
      return <ExternalFormLink reasonSelection={selectedContactReason} />;
    }

    if (showContactUsForm) {
      return <ContactUsForm reasonSelection={selectedContactReason} />;
    }

    return null;
  };
  return (
    <AccountPage>
      {showPortalUXOverhaul && (
        <div className={styles.title}>
          <Text
            as="h2"
            variant={'heading-40'}
            bold
            color="blueberry-3"
            topSpacing="none"
            bottomSpacing="sm"
          >
            Contact Us
          </Text>

          <Text>
            Our team of dedicated veterinary and nutrition-science professionals
            will get back to you.{' '}
            <Button
              variant="plain-text"
              type="link"
              to={pagePaths.PATH_OUR_TEAM}
              color="kale"
              underline
              size="md"
            >
              Meet Our Team
            </Button>
          </Text>
        </div>
      )}
      <Card>
        <Grid>
          <GridItem>
            <Text
              as="h2"
              variant={
                isMobile || showPortalUXOverhaul ? 'heading-28' : 'heading-40'
              }
              bold
              color="blueberry-3"
              topSpacing="none"
              bottomSpacing="none"
            >
              {cardHeaderContent}
            </Text>
            <Text
              as="p"
              variant="article-16"
              color="charcoal-3"
              topSpacing="sm"
              bottomSpacing="sm"
            >
              {cardSubHeaderContent}
            </Text>
          </GridItem>
          <Grid bottomSpacing={'sm'}>
            <GridItem>
              <Fieldset legend={''}>
                {radioButtons.map(({ name, label, value }) => (
                  <RadioButton
                    key={value}
                    name={name}
                    label={label}
                    value={value}
                    onChange={() => handleRadioClick(value, label)}
                    checked={selectedRadioOption === value}
                  />
                ))}
              </Fieldset>
            </GridItem>
          </Grid>
        </Grid>

        {renderDynamicForm()}
      </Card>

      {showPortalUXOverhaul ? <EmailSection /> : <EmailSectionLegacy />}
    </AccountPage>
  );
};
