// resources
export const downloadable_info_click_on_download_link =
  'Downloadable Info - Clicked on download link';
export const downloadable_vet_referral_handout_on_download_link =
  'Vet Referral Handout Downloaded';
export const resources_page_toggled_accordion =
  'Vet Portal Resources Page - Toggle Accordion';
export const seen_pdf_preview = 'Vet Portal - Seen PDF Preview';

// auth
export const forgot_password_requested_reset =
  'Forgot Password - Requested Reset';
export const signup = 'Vet Portal - Signup';
export const set_up_new_password_to_log_in =
  'Vet Portal - Set up new password to log in';
export const set_new_password_with_expired_temp_password =
  'Vet Portal - Set New Password with Expired Temp Password';
export const forgot_password_requested_in_temp_password_state =
  'Vet Portal - Forgot Password Request in Temp Password State';
export const login_in_temp_password_state =
  'Vet Portal - Login in Temp Password State';
export const set_new_password_after_successful_login =
  'Vet Portal - Set New Password After Successful Login';

// contact support
export const clicked_contact_support = 'Vet Portal - Contact Support Clicked';

// errors
export const show_error_message = 'Vet Portal - Show error message';

// profile
export const profile_updated = 'Vet Portal - Profile Updated';

// events and recordings
export const events_and_recordings_page_clicked_lunch_and_learn_request =
  'Vet Portal - Events and Recordings Page - Clicked Request Lunch & Learn';
export const events_and_recordings_page_clicked_university_visit_request =
  'Vet Portal - Events and Recordings Page - Clicked Request University Visit';
export const webinars_page_clicked_share_button =
  'Vet Portal Webinar Page - Clicked Share Button';
export const webinars_page_clicked_copy_link_button =
  'Vet Portal Webinar Page - Clicked Copy Link Button';

// FAQs
export const faq_review_clicked = 'Vet Portal - Review FAQs Clicked';
export const faq_question_clicked = 'Vet Portal - FAQ Question Clicked';
export const faq_activate_discount_clicked =
  'Vet Portal - FAQ Activate Discount Clicked';
export const faq_navbar_clicked = 'Vet Portal - FAQ Navbar Clicked';

// landing page
export const landing_page_clicked_hero_link =
  'Vet Portal Landing Page - Clicked Jumplink:Hero';
export const landing_page_clicked_sticky_nav_link =
  'Vet Portal Landing Page - Clicked Jumplink:Sticky Nav';
export const landing_page_clicked_download_nutrition_guide =
  'Vet Portal Landing Page - Clicked Download Nutrition Guide';
export const landing_page_clicked_download_veterinary_guide =
  'Vet Portal Landing Page - Clicked Download Veterinary Guide';
export const landing_page_clicked_view_clinical_recommendations =
  'Vet Portal Landing Page - Clicked View Clinical Recommendations';
export const landing_page_clicked_logging_in_to_our_portal =
  'Vet Portal Landing Page - Clicked Login:Meet Our Team';
export const landing_page_clicked_explore_studies_and_resources =
  'Vet Portal Landing Page - Clicked Explore Studies & Resources';
export const landing_page_clicked_register_for_vet_team_portal_hero =
  'Vet Portal Landing Page - Clicked Register:Hero';
export const landing_page_clicked_register_for_vet_team_portal_have_more_questions =
  'Vet Portal Landing Page - Clicked Register:Have More Questions';
export const landing_page_clicked_register_for_vet_team_portal_sticky_navbar =
  'Vet Portal Landing Page - Clicked Register:Sticky Navbar';
export const landing_page_clicked_lunch_and_learn_request =
  'Vet Portal Landing Page - Clicked Request Lunch & Learn';
export const landing_page_clicked_university_visit_request =
  'Vet Portal Landing Page - Clicked Request University Visit';

// contact us
export const contact_us_clicked_lunch_and_learn_request =
  'Vet Portal Contact Us Page - Clicked Request Lunch & Learn';
export const contact_us_clicked_university_visit_request =
  'Vet Portal Contact Us Page - Clicked Request University Visit';

// banners
export const terms_changes_notice_dismissed =
  'Terms Changes Notice - Dismissed';
export const dismissed_customer_account_log_in_notice =
  'Vet Portal - Dismissed Customer Account Log In Notice';
export const clicked_customer_account_log_in =
  'Vet Portal - Clicked Customer Account Log In';

// discount
export const submitted_discount_request =
  'Vet Portal - Submitted Discount Request';
export const clicked_discount_eligibility =
  'Vet Portal - Clicked Discount Eligibility';
export const clicked_activate_discount =
  'Vet Portal - Clicked Activate Discount';

// account dashboard
export const homepage_clicked_share_your_experience_email_link =
  'Vet Portal Homepage - Clicked Share Your Experience Email Link';

// formulas
export const clicked_core_tenet = 'Vet Portal - Clicked Core Tenet';
export const clicked_browse_formulas = 'Vet Portal - Clicked Browse Formulas';
export const clicked_see_formulas_details =
  'Vet Portal - Clicked See Formulas Details';
export const selected_indication_filter =
  'Vet Portal - Selected Indication Filter';
export const clicked_contact_for_alternative_formulas =
  'Vet Portal - Clicked Contact Us for Alternative Formulas';
export const clicked_nutrition_mix_details =
  'Vet Portal - Clicked Nutrition Mix Details';
export const clicked_recommend_to_client =
  'Vet Portal - Clicked Recommend to Client';
export const toggled_formula_table = 'Vet Portal - Toggle Formula Table';
