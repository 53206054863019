import { Grid } from '@farmersdog/corgi-x';
import styles from './Education.module.css';
import { EducationItem } from './EducationItem/EducationItem';
import { getEducationItems } from './educationData';
import { TitleSection } from '../TitleSection';
import { usePublicPageFeatures } from '../../../abTesting/PublicPageFeatures';

export const Education = () => {
  const { showAllFormulasDiscovery } = usePublicPageFeatures();
  return (
    <>
      <TitleSection title="Learn about our science and nutrition" />
      <Grid className={styles.educationItemsContainer}>
        {getEducationItems({
          showVeterinaryGuide: Boolean(showAllFormulasDiscovery),
        }).map(educationItem => (
          <EducationItem key={educationItem.title} {...educationItem} />
        ))}
      </Grid>
    </>
  );
};
