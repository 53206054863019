import type { MemberCardProps } from '../../../components/MemberCard';

import ryanWebp from '../assets/ryan.webp';
import ryanJp2 from '../assets/ryan.jp2';
import ryanPng from '../assets/ryan.png';

import josephWebp from '../assets/joseph.webp';
import josephJp2 from '../assets/joseph.jp2';
import josephPng from '../assets/joseph.png';

import brittanyWebp from '../assets/brittany.webp';
import brittanyJp2 from '../assets/brittany.jp2';
import brittanyPng from '../assets/brittany.png';

import brandonWebp from '../assets/brandon.webp';
import brandonJp2 from '../assets/brandon.jp2';
import brandonPng from '../assets/brandon.png';

import raeWebp from '../assets/rae.webp';
import raeJp2 from '../assets/rae.jp2';
import raePng from '../assets/rae.png';

import maggieWebp from '../assets/maggie.webp';
import maggieJp2 from '../assets/maggie.jp2';
import maggiePng from '../assets/maggie.png';

import caitlinWebp from '../assets/caitlin.webp';
import caitlinJp2 from '../assets/caitlin.jp2';
import caitlinPng from '../assets/caitlin.png';

import reneeWebp from '../assets/renee.webp';
import reneeJp2 from '../assets/renee.jp2';
import reneePng from '../assets/renee.png';

import brittaniPng from '../assets/brittani.png';
import brittaniJp2 from '../assets/brittani.jp2';
import brittaniWebp from '../assets/brittani.webp';

import taylorPng from '../assets/taylor.png';
import taylorJp2 from '../assets/taylor.jp2';
import taylorWebp from '../assets/taylor.webp';

const ryanProfileSources = [ryanWebp, ryanJp2, ryanPng];
const josephProfileSources = [josephWebp, josephJp2, josephPng];
const brittanyProfileSources = [brittanyWebp, brittanyJp2, brittanyPng];
const brandonProfileSources = [brandonWebp, brandonJp2, brandonPng];
const raeProfileSources = [raeWebp, raeJp2, raePng];
const maggieProfileSources = [maggieWebp, maggieJp2, maggiePng];
const caitlinProfileSources = [caitlinWebp, caitlinJp2, caitlinPng];
const reneeProfileSources = [reneeWebp, reneeJp2, reneePng];
const brittaniProfileSources = [brittaniWebp, brittaniJp2, brittaniPng];
const taylorProfileSources = [taylorWebp, taylorJp2, taylorPng];

export const brandonMemberProfile = {
  id: 1,
  name: 'Brandon Stapleton',
  education: 'DVM, BS',
  position: 'Veterinary Team',
  sources: brandonProfileSources,
  alt: 'Brandon profile picture',
  howTheyHelpDogsTitle: 'How he helps dogs',
  howTheyHelpDogsText:
    'Dr. Brandon Stapleton graduated from Auburn CVM and practiced in an AAHA-accredited hospital in Indiana for about ten years, where he served as Medical Director and Managing Doctor. From there, he moved into the animal health and nutrition industry with Hill’s, spending most of his tenure there building various teams including those focused on education and digital services to vets. He then joined The Farmer’s Dog to lead their veterinary and professional health organizations.',
  howTheyHelpDogsTextLines: 5,
  whyTheyLoveTFDTitle: 'Why he loves TFD',
  whyTheyLoveTFDText:
    'I have thoroughly enjoyed my time at The Farmer’s Dog for three major reasons: 1) Our unyielding focus on dog health 2) Our science-first, science-always approach to any and everything that we do 3) A positive culture that opens the door for great ideas and input to come from anywhere, and never settles for “how we’ve always done it”.',
  meetTheirPetsTitle: 'Meet his pets',
  meetTheirPetsText:
    'Tori, a 15 year-old DSH, who would benefit from a portioned fresh food option for cats!',
  text: "Dr. Brandon Stapleton graduated from Auburn CVM and practiced in an AAHA-accredited hospital in Indiana for about ten years, where he served as Medical Director and Managing Doctor. From there, he moved into the animal health and nutrition industry with Hill's, spending most of his tenure there building various teams including those focused on education and digital services to vets. He then joined The Farmer's Dog to lead their veterinary and professional health organizations.",
};

export const ryanMemberProfile = {
  id: 2,
  name: 'Ryan Yamka',
  education: 'PhD, MS, FACN, PAS, Dipl ACAS',
  position: (
    <>
      Research & Formulation, <br />
      Board-Certified Animal Nutritionist
    </>
  ),
  sources: ryanProfileSources,
  alt: 'Ryan profile picture',
  howTheyHelpDogsTitle: 'How he helps dogs',
  howTheyHelpDogsText:
    'Dr. Ryan Yamka has over two decades of experience as a board-certified companion animal nutritionist in the pet food industry. He earned his bachelor’s degrees in animal science and biology from Virginia Polytechnic Institute and State University, followed by a masters degree and doctorate in animal science from UKY and an MBA from Baker University. He started his career at Hill’s Pet Nutrition in research and product development and has since developed and launched 250+ nutrition products. He has dedicated his career to enhancing the health and well-being of dogs through scientifically formulated nutrition. In addition to his full time role at The Farmer’s Dog, Dr. Yamka also serves as an advisory board member for Bond Pet Foods. He has published numerous manuscripts, abstracts and book chapters (including in Small Animal Clinical Nutrition, Edition 5), and has 50+ patents granted.',
  whyTheyLoveTFDTitle: 'Why he loves TFD',
  whyTheyLoveTFDText:
    'I am passionate about The Farmer’s Dog for several compelling reasons: 1) Our commitment to advancing dog health through innovative nutritional solutions 2) Our rigorous adherence to scientific principles in every aspect of our work 3) A dynamic and inclusive culture that fosters creativity, collaboration, and continuous improvement.',
  meetTheirPetsTitle: 'Meet his pets',
  meetTheirPetsText:
    'Lexi, Luna and Mango are all rescues.  Libby is a Boston terrier COVID dog. Her name is Liberty, and she was born July 4, 2020.',
  text: 'Dr. Yamka earned his bachelor’s degrees in animal science and biology from Virginia Polytechnic Institute and State University, followed by a masters degree and doctorate in animal science from UKY and an MBA from Baker University. While he is currently the Head of Research and Development for The Farmer’s Dog, he also serves as an advisory board member for Bond Pet Foods and as Senior Strategist, Animal Nutrition for Do Good Foods.',
};

export const raeMemberProfile = {
  id: 3,
  name: 'Rae Sires',
  education: 'DVM, DACVIM (Nutrition)',
  position: (
    <>
      Research & Formulation, <br />
      Board-Certified Veterinary Nutritionist®
    </>
  ),
  sources: raeProfileSources,
  alt: 'Rae profile picture',
  howTheyHelpDogsTitle: 'How she helps dogs',
  howTheyHelpDogsText:
    'Dr. Rae Sires graduated from Iowa State University College of Veterinary Medicine and immediately moved to Dallas, Texas to complete a 1-year internship focused on small animal emergency and critical care. She completed her residency training in small animal clinical nutrition at UC Davis and became a diplomate of the American College of Veterinary Internal Medicine (Nutrition) in 2021. Dr. Sires joined a small animal general practice after her residency and has worked as a relief veterinarian in 15 different hospitals across Kansas, Missouri, and Texas. She is an active educator with the Veterinary Information Network (VIN) and also serves on the ACVIM General Exam Item Review Committee.',
  whyTheyLoveTFDTitle: 'Why she loves TFD',
  whyTheyLoveTFDText:
    'The Farmer’s Dog is the first workplace that I have experienced that encourages all of its employees to challenge the status quo to ensure the entire company is always actively pursuing what is truly best for dogs!',
  meetTheirPetsTitle: 'Meet her pets',
  meetTheirPetsText:
    'Tony Stark - 10 y/o DSH; Cheeseburger - 3 y/o DSH; Happy - 1 y/o Golden Retriever; Gilly - 10 y/o chinchilla',
  text: 'Dr. Rae Sires graduated from Iowa State University College of Veterinary Medicine. She completed her residency training in small animal clinical nutrition at UC Davis and became a diplomate of the American College of Veterinary Internal Medicine (Nutrition) in 2021. She joined a small animal general practice after her residency and worked as a relief veterinarian across Kansas, Missouri, and Texas. She is an active educator with the Veterinary Information Network (VIN).',
};

export const josephMemberProfile = {
  id: 4,
  name: 'Joseph Wakshlag',
  education: 'DVM, PhD, DACVIM (Nutrition)',
  position: (
    <>
      Research & Formulation, <br />
      Board-Certified Veterinary Nutritionist®
    </>
  ),
  sources: josephProfileSources,
  alt: 'Joe profile picture',
  howTheyHelpDogsTitle: 'How he helps dogs',
  howTheyHelpDogsText:
    'Dr. Joe Wakshlag received his DVM from Cornell University College of Veterinary Medicine in 1998. His passion for nutrition led him to complete a residency in Veterinary Nutrition at the same time as receiving a PhD in pharmacology in 2005. In 2006 he became an Assistant Professor of Nutrition at the College of Veterinary Medicine. During this time he rose through the ranks to become a Full Professor in 2018 while also becoming a founding member of the American College of Veterinary Sports Medicine and Rehabilitation, not only serving on the Board from 2010-2014, but also helped lead the American College of Veterinary Nutrition during a similar time frame.\n\nDr. Joe has been service Chief of Service for the Nutrition Department at Cornell since 2007 and was Chief of Service of the Sports Medicine and Rehabilitation department from 2014-2018. He has been very active in benchtop and clinical studies at Cornell in the areas of canine obesity, cancer cell biology, nutraceuticals, sporting dog metabolism and nutrient requirements. He has authored over a dozen book chapters and well over 100 peer reviewed publications.',
  whyTheyLoveTFDTitle: 'Why he loves TFD',
  whyTheyLoveTFDText:
    'As the original formulator for The Farmer’s Dog almost 10 years ago, I am continually impressed with many facets of The Farmer’s Dog. 1) our commitment to a high quality gently cooked diet with exceptional quality control measures, 2) our customer service which continues to amaze me with nutritional support for owners that the entire industry is lacking and 3) clinical study endeavors to fully understand the benefits of fresh food to back up all of the client stories The Farmer’s Dog receives regarding how feeding The Farmer’s Dog transforms dogs lives for the better.',
  meetTheirPetsTitle: 'Meet his pets',
  meetTheirPetsText:
    'After running sled dogs who were part of his family for 15 years, he has downsized to a single 4 year old domestic shorthair cat named Kitty (not very original name, we know!) due to travel commitments.',
  text: "Dr. Wakshlag received his DVM in 1998 and became board certified in veterinary nutrition and canine sports medicine and rehabilitation, in 2006 and 2010 respectively. In addition to being the Veterinary Nutritionist for The Farmer's Dog, he is currently a Professor of Veterinary Nutrition at Cornell with over 15 years of research experience in canine obesity, cancer cell biology, sporting dogs and pain management.",
};

export const maggieMemberProfile = {
  id: 5,
  name: 'Maggie Canning',
  education: 'DVM',
  position: 'Veterinary Team',
  sources: maggieProfileSources,
  alt: 'Maggie profile picture',
  howTheyHelpDogsTitle: 'How she helps dogs',
  howTheyHelpDogsText:
    'Dr. Maggie Canning is a graduate of Auburn University College of Veterinary Medicine. She practiced small animal medicine in NC, TX, PA, and NJ, worked with a telemedicine startup, spoke at major veterinary conferences, and served as a field representative for Nationwide Pet Insurance. She then spent four years in IDEXX’s software organization building data and software solutions to advance patient care while continuing to practice as a relief veterinarian. She has also served on an AVMA committee and co-founded multiple veterinary organizations.',
  whyTheyLoveTFDTitle: 'Why she loves TFD',
  whyTheyLoveTFDText:
    'I cherish our dedication to science - from research with universities to partnerships with industry leaders, we care a lot about evidence-based medicine. Everyone at The Farmer’s Dog is led by heart and science, ensuring that compassion and rigorous research guide every decision we make. We are constantly learning through testing hypotheses, and we never lose sight of the people and dogs we’re serving.',
  meetTheirPetsTitle: 'Meet her pets',
  meetTheirPetsText:
    'My sweetest vet school pup, Cooper, a 12 year old Lab/Chesapeake Bay Retriever who taught me how special dogs are.\n\nAnd how could we have a two vet household (my husband is a boarded dermatologist), without a food allergic Golden Retriever, Chewie (who does great on our pork recipe!).',
  text: "Dr. Maggie Canning is a graduate of Auburn University College of Veterinary Medicine. She practiced small animal medicine in NC, TX, PA, and NJ, worked with a telemedicine startup, spoke at major veterinary conferences, and served as a field representative for Nationwide Pet Insurance. She then spent four years in IDEXX's software organization building data and software solutions to advance patient care while continuing to practice as a relief veterinarian. She has also served on an AVMA committee and co-founded multiple veterinary organizations.",
};

export const brittanyMemberProfile = {
  id: 6,
  name: 'Brittany Lechlinski',
  education: 'CVT',
  position: 'Veterinary Team',
  sources: brittanyProfileSources,
  alt: 'Associate profile picture',
  howTheyHelpDogsTitle: 'How she helps dogs',
  howTheyHelpDogsText:
    'After adopting her best friend 10 years ago, Brittany became a dedicated animal welfare advocate. She began her journey by managing a boarding facility for 3 years while working towards her veterinary technician certification through PennFoster College.\n\nBrittany then transitioned to various roles in non-profit shelters and clinics, focusing on rescue, rehabilitation, and rehoming animals, as well as addressing overpopulation at a high-volume spay-neuter clinic. Since joining The Farmer’s Dog in 2020, she has continued her work supporting and advocating for all animals and vet professionals!',
  whyTheyLoveTFDTitle: 'Why she loves TFD',
  whyTheyLoveTFDText:
    "Working at The Farmer's Dog is incredibly fulfilling because I get to contribute to the well-being of dogs every single day. The company's commitment to creating high-quality, nutritious food aligns with my passion for animal health, and being part of a dedicated team that truly cares about pets (and the humans in their lives!) makes each day rewarding.",
  meetTheirPetsTitle: 'Meet her pets',
  meetTheirPetsText:
    'Myla is a 10 year old pug/chihuahua mix who not only controls my life, but is the unofficial vet team mascot here at The Farmer’s Dog. Rest assured, if she’s not harassing you for food, she’s silently judging you.',
  text: 'After adopting her best friend, Myla, in 2013, Brittany immersed herself in the veterinary field and has worked in various shelters and non-profit vet clinics. She joined The Farmer’s Dog during the summer of 2020, and is excited to connect with a community devoted to helping dogs live longer, healthier lives!',
};

export const caitlinMemberProfile = {
  id: 7,
  name: 'Caitlin Hering',
  education: 'VMD',
  position: 'Veterinary Team',
  sources: caitlinProfileSources,
  alt: 'Associate profile picture',
  howTheyHelpDogsTitle: 'How she helps dogs',
  howTheyHelpDogsText:
    'Dr. Caitlin Hering completed her doctorate in veterinary medicine at The University of Pennsylvania, followed by a rotating internship in Emergency, Internal Medicine, and Surgery at The Schwarzman Animal Medical Center in Manhattan. Before joining The Farmer’s Dog, Dr. Hering practiced small animal emergency, urgent care and primary care. She also helped start up a nonprofit foster-based rescue for dogs, Rain or Shine Rescue, where she remains on the Board of Directors.',
  whyTheyLoveTFDTitle: 'Why she loves TFD',
  whyTheyLoveTFDText:
    'As a veterinarian, I’m uncompromising when it comes to making sure my recommendations align with my responsibility to protect the health of my patients. At The Farmer’s Dog I’m proud to be a part of a team that shares this purpose by continuously learning through research, engaging experts and leaders, and inviting feedback to ensure we’re building a product rooted in science, and doing so thoughtfully with both dog lovers and veterinary professionals in mind.',
  meetTheirPetsTitle: 'Meet her pets',
  meetTheirPetsText:
    'The two dog loves of my life were both rescued island mixes from Antigua, Scooter and Simba, who both had their individual struggles that led me to dedicate my life to bettering the lives of dogs and the people who care for them.',
};

export const reneeMemberProfile = {
  id: 8,
  name: 'Renee Streeter',
  education: 'DVM, DACVIM (Nutrition)',
  position: (
    <>
      Research & Formulation, <br />
      Board-Certified Veterinary Nutritionist®
    </>
  ),
  sources: reneeProfileSources,
  alt: 'Renee profile picture',
  howTheyHelpDogsTitle: 'How she helps dogs',
  howTheyHelpDogsText:
    "Dr. Renee Streeter graduated from Ross University School of Veterinary Medicine. Her residency in clinical nutrition was completed at Cornell University's College of Veterinary Medicine. Thereafter she practiced general practice and clinical nutrition at a large general practice while growing her consulting business which was later merged with a large consulting group for the pet food industry where she helped companies with their pet food, treat and supplement formations, research and education. She then joined the Farmer's Dog to lead their Animal Nutritionists in product creation.",
  whyTheyLoveTFDTitle: 'Why she loves TFD',
  whyTheyLoveTFDText:
    "The Farmer's Dog continues to grow through its commitment to research which they apply to their formulations. The positive culture and work environment inspires hard work and collaboration allowing for the most innovating and evolving processes.",
  meetTheirPetsTitle: 'Meet her pets',
  meetTheirPetsText:
    'The goofiest of 7 year old Boxers, Fiona. The sweetest 1 year old, Boxer-mix, Lyra. The most talkative of grey tabbies, Rosalind. 5 fluffy chickens, Henrietta, Arrow, Rosemary, Basil and Ezra and one little yellow beta-fish named, Sun.',
};

export const jonesMemberProfile = {
  id: 9,
  name: 'Brittani Jones',
  education: 'DVM, DACVS',
  position: 'Veterinary Team',
  sources: brittaniProfileSources,
  alt: 'Brittani profile picture',
  howTheyHelpDogsTitle: 'How she helps dogs',
  howTheyHelpDogsText: (
    <>
      Dr. Brittani Jones earned her DVM from The Ohio State University College
      of Veterinary Medicine and completed a one-year internship at the
      Schwarzman Animal Medical Center in NYC, focusing on surgery, emergency,
      and internal medicine. She then completed a residency in Small Animal
      Surgery at Michigan State University, becoming a Diplomate of the American
      College of Veterinary Surgeons in 2020.
      <p>
        Dr. Jones gained extensive surgical experience at a specialty and
        emergency hospital in California, where she also mentored veterinary
        interns and students. Her research included topics like feed intake in
        broiler chicks, upper airway disease in brachycephalic dogs, and
        chemotherapy efficacy in cancer cells. She has authored peer-reviewed
        publications and presented at scientific conferences.
      </p>
      Driven by a passion for bridging science and communication, Dr. Jones
      joined The Farmer&apos;s Dog as Scientific Communications Manager, where
      she leverages her clinical and academic experience to promote
      science-backed, transparent pet nutrition.
    </>
  ),
  whyTheyLoveTFDTitle: 'Why she loves TFD',
  whyTheyLoveTFDText:
    "I am thrilled and proud to be part of a team dedicated to challenging the status quo and improving the lives of pets through science-backed innovation. I deeply admire The Farmer's Dog for its transparency in food production. I love that the company is committed to partnering with veterinarians and empowering pet owners to make informed decisions about pet nutrition.",
  meetTheirPetsTitle: 'Meet her pets',
  meetTheirPetsText:
    'I have two wonderful pets who bring so much joy to my life. My 11-year-old Golden Retriever, Bronson, has been by my side since veterinary school. He’s the sweetest, most loyal companion, and I truly treasure his gentle nature. My 7-year-old mixed breed, we suspect a charming mix of pit bull, boxer, and Catahoula, is the perfect balance of a cuddle bug and an escape artist—always keeping me on my toes with his playful antics.',
};

export const taylorMemberProfile = {
  id: 10,
  name: 'Taylor Root',
  education: 'VMD',
  position: 'Veterinary Team',
  sources: taylorProfileSources,
  alt: 'Taylor profile picture',
  howTheyHelpDogsTitle: 'How she helps dogs',
  howTheyHelpDogsText:
    'Dr. Taylor Root received her Doctorate of Veterinary Medicine from the University of Pennsylvania. She then spent multiple years as a small animal general practitioner in private practice in PA. Her practice included medicine and surgery and she prided herself on building deep relationships with her patients and also the people who love them.',
  whyTheyLoveTFDTitle: 'Why she loves TFD',
  whyTheyLoveTFDText:
    'As a veterinarian it is so important to me to be working with a company where every person is striving towards the common goal of the betterment of animal health. At The Farmer’s Dog, there is a concerted effort to use research, science, and compassion to make a difference across the industry and to support other veterinarians in the process.',
  meetTheirPetsTitle: 'Meet her pets',
  meetTheirPetsText:
    'My family of pets includes two cats, a retired blood donor kitty named Willy and a one-eyed tuxedo cat named Duckie, as well as a large goofball of a rescue dog named Finn who brings lots of smiles and hair to all who meet him.',
};

export const teamMembersData: MemberCardProps[] = [
  brandonMemberProfile,
  ryanMemberProfile,
  raeMemberProfile,
  josephMemberProfile,
  reneeMemberProfile,
  jonesMemberProfile,
  taylorMemberProfile,
  maggieMemberProfile,
  caitlinMemberProfile,
  brittanyMemberProfile,
];
