import { Button, Divider, Grid, GridItem, Text } from '@farmersdog/corgi-x';
import styles from './IndicationsFilter.module.css';
import classNames from 'classnames';
import { mapIndicationsToCategory } from './utils/mapIndicationsToCategory';
import { formatIndicationDisplayName } from './utils/formatIndicationDisplayName';
import type { Indication } from './types';

export interface IndicationsFilterProps {
  indicationsToFilter: Indication[];
  selectedFilters: Indication[];
  setSelectedFilters: (filters: Indication[]) => void;
  isMobile?: boolean;
}

export const IndicationsFilter = ({
  indicationsToFilter,
  selectedFilters,
  setSelectedFilters,
  isMobile = false,
}: IndicationsFilterProps) => {
  const handleFilterClick = (indication: Indication) => {
    if (isSelected(indication)) {
      const newSelectedFilters = selectedFilters.filter(
        (filter: Indication) => filter !== indication
      );
      setSelectedFilters(newSelectedFilters);
    } else {
      const newSelectedFilters = [...selectedFilters, indication];
      setSelectedFilters(newSelectedFilters);
    }
  };

  const isSelected = (indication: Indication) => {
    return selectedFilters.includes(indication);
  };
  const mappedIndications = mapIndicationsToCategory(indicationsToFilter);
  const categoryHeadingClass = classNames({
    [styles.categoryHeading]: !isMobile,
  });
  return (
    <Grid gap={'md'}>
      {Object.keys(mappedIndications).map(category => {
        return (
          <GridItem
            key={category}
            lg={2}
            role="region"
            aria-label={`${category} filters`}
          >
            <Grid flexDirection={'column'}>
              <GridItem lg={12}>
                <Text
                  variant="heading-16"
                  bold
                  className={categoryHeadingClass}
                  as="div"
                >
                  {category}
                </Text>
              </GridItem>
              <GridItem lg={12} className={styles.dividerContainer}>
                <Divider color="Charcoal1" width={1} spacing={0} />
              </GridItem>
              <GridItem lg={12}>
                <Grid rowGap={'sm'}>
                  <ul className={styles.filterList}>
                    {mappedIndications[category].map(indication => {
                      return (
                        <li key={indication.displayName}>
                          <GridItem xs={12}>
                            <Button
                              variant="special-kale-experimental"
                              className={classNames(styles.button, {
                                [styles.selectedFilter]: isSelected(indication),
                                [styles.unselectedFilter]:
                                  !isSelected(indication),
                              })}
                              onClick={() => handleFilterClick(indication)}
                              aria-label={
                                isSelected(indication)
                                  ? `Remove ${indication.displayName} filter`
                                  : `Add ${indication.displayName} filter`
                              }
                            >
                              <Text
                                className={styles.filterText}
                                color={
                                  isSelected(indication) ? 'white' : 'kale-2'
                                }
                                variant="heading-16"
                              >
                                {formatIndicationDisplayName(
                                  indication.displayName
                                )}
                              </Text>
                            </Button>
                          </GridItem>
                        </li>
                      );
                    })}
                  </ul>
                </Grid>
              </GridItem>
            </Grid>
          </GridItem>
        );
      })}
    </Grid>
  );
};
