import {
  AccordionPanel,
  type AccordionPanelProps,
  Grid,
} from '@farmersdog/corgi-x';
import { type NutritionData } from '../../../../graphql/types';
import { transformData } from '../utils/transformData';
import { NutritionTable } from '../../NutritionTable';

type CustomAccordionPanelProps = Pick<
  AccordionPanelProps,
  'expanded' | 'heading' | 'id' | 'onToggle' | 'theme'
>;

interface DataPanelProps extends CustomAccordionPanelProps {
  nutritionData: NutritionData[];
  className?: string;
}

export const DataPanel = ({
  nutritionData,
  className = '',
  ...panelProps
}: DataPanelProps) => {
  const data = transformData(nutritionData);

  return (
    <AccordionPanel {...panelProps}>
      <Grid className={className}>
        {Object.keys(data).map(key => (
          <NutritionTable rows={data[key]} key={key} />
        ))}
      </Grid>
    </AccordionPanel>
  );
};
