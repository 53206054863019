import type {
  ClinicalNutrition,
  ClinicalFormula,
} from '../../../graphql/types';
import { Ingredients } from '../Ingredients/Ingredients';
import { Title } from '../Title';
import { FormulaType } from '../../../constants';
import { Grid } from '@farmersdog/corgi-x';
import { TypicalNutritionals } from '../TypicalNutritionals';
import { GuaranteedAnalysis } from '../GuaranteedAnalysis';
import { Information } from '../Information';
import { RecommendToClient } from '../RecommendToClient';
import { getHasTypicalNutritionalsContent } from '../utils/getHasTypicalNutritionalsContent';

export const Treat = ({
  basicInfo,
  clinicalNutrition,
}: Pick<ClinicalFormula, 'basicInfo' | 'clinicalNutrition'>) => {
  const hasTypicalNutritionalsContent = getHasTypicalNutritionalsContent({
    basicInfo,
    clinicalNutrition,
  });

  return (
    <>
      <Title>{basicInfo?.displayName ?? ''}</Title>
      <Grid rowGap="md">
        {basicInfo!.mainIngredients && (
          <Ingredients
            ingredients={
              basicInfo!.fullIngredients ?? basicInfo!.mainIngredients
            }
            formulaType={FormulaType.Treats}
          />
        )}
        <Information variant="treat" />
      </Grid>
      <Grid topSpacing="lg" bottomSpacing="xxl">
        <RecommendToClient />
      </Grid>
      {basicInfo?.guaranteedAnalysis && (
        <GuaranteedAnalysis analysis={basicInfo.guaranteedAnalysis} />
      )}
      {hasTypicalNutritionalsContent && (
        <Grid topSpacing="xxl">
          <TypicalNutritionals
            calculatedCalorieContent={basicInfo?.calculatedCalorieContent ?? ''}
            clinicalNutrition={clinicalNutrition as ClinicalNutrition}
          />
        </Grid>
      )}
    </>
  );
};
