import { StatusMessage, Text } from '@farmersdog/corgi-x';
import classNames from 'classnames';

import styles from './FormError.module.css';

export interface FormErrorProps {
  message: React.ReactNode;
}

export function FormError({ message }: FormErrorProps) {
  return (
    <StatusMessage
      politeness="assertive"
      visuallyHidden={!message}
      className={classNames({ [styles.container]: message })}
    >
      <Text variant="heading-16" className={styles.message}>
        {message}
      </Text>
    </StatusMessage>
  );
}
