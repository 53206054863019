import { Button, Callout, Text } from '@farmersdog/corgi-x';
import { FormulaCard } from '../FormulaCard';
import { ContactForAlternativeFormulas } from '../ContactForAlternativeFormulas';
import { useScreenSizeContext } from '../../context/ScreenSizeContext';
import { FormulaType } from '../../constants';
import type { ClinicalFormula } from '../../graphql/types';
import styles from './FilteredFormulas.module.css';
import { CircleAlert } from '@farmersdog/corgi-x/icons';
import type { Indication } from '../../pages/Formulas/IndicationsFilter/types';
import { FiltersActions } from './FiltersActions';
import { trackContactForAlternativeFormulas } from '../../analytics/events';
import { PATH_CONTACT_US } from '../../constants/pagePaths';

interface EmptyCalloutProps {
  message: string;
  formulaType: 'fresh' | 'diy' | 'treats';
}

const EmptyCallout = ({ message, formulaType }: EmptyCalloutProps) => (
  <Callout
    className={styles.noMatchCallout}
    color="Blueberry3"
    variant="neutral"
    icon={<CircleAlert size={20} />}
  >
    <Text
      variant="heading-16"
      color="charcoal-3"
      as="p"
      topSpacing="none"
      bottomSpacing="none"
    >
      {message} {'Try adjusting your filters or '}
      <Button
        type="link"
        variant="plain-text"
        underline
        to={PATH_CONTACT_US}
        onClick={() => trackContactForAlternativeFormulas({ formulaType })}
        weight="normal"
        color="kale"
      >
        contact our vets
      </Button>
      .
    </Text>
  </Callout>
);

interface FilteredFormulasProps {
  formulas: ClinicalFormula[] | undefined;
  selectedFilters: Indication[];
  setSelectedFilters: (filters: Indication[]) => void;
}

export const freshHeadingId = 'fresh-section';
const treatsHeadingId = 'treats-section';
export const diyHeadingId = 'diy-section';

export function FilteredFormulas({
  formulas,
  selectedFilters,
  setSelectedFilters,
}: FilteredFormulasProps) {
  const { isMobile } = useScreenSizeContext();
  if (!formulas) {
    return null;
  }

  const freshFormulas = formulas.filter(
    formula => formula && formula.type === FormulaType.Fresh
  );

  const treatsFormulas = formulas.filter(
    formula => formula && formula.type === FormulaType.Treats
  );

  const diyFormulas = formulas.filter(
    formula => formula && formula.type === FormulaType.DIY
  );

  return (
    <div>
      <section aria-labelledby={freshHeadingId}>
        <Text
          color="Blueberry3"
          variant={isMobile ? 'heading-22' : 'heading-28'}
          as="h3"
          bold
          bottomSpacing="sm"
          id={freshHeadingId}
        >
          Freshly Cooked Meals
        </Text>
        <Text
          color="charcoal-3"
          variant="heading-16"
          as="p"
          topSpacing="none"
          bottomSpacing="md"
        >
          We prepare, cook, and freeze meals in custom portions, then deliver
          them in eco-friendly, refrigerated boxes.
        </Text>
        <FiltersActions
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
        />
        {!freshFormulas.length && (
          <EmptyCallout
            formulaType="fresh"
            message="We couldn’t find any freshly cooked meals that match your filters."
          />
        )}
        <div className={styles.formulaCardsContainer}>
          {freshFormulas.map(freshFormula => {
            return (
              <div
                key={freshFormula?.name}
                className={styles.formulaCardContainer}
              >
                <FormulaCard formula={freshFormula} />
              </div>
            );
          })}
        </div>
      </section>
      <section aria-labelledby={diyHeadingId}>
        <Text
          color="Blueberry3"
          variant={isMobile ? 'heading-22' : 'heading-28'}
          as="h3"
          bold
          id={diyHeadingId}
          topSpacing={'xxl'}
          bottomSpacing="sm"
        >
          Nutrient Mix Recipes
        </Text>
        <Text
          color="charcoal-3"
          variant="heading-16"
          as="p"
          topSpacing="none"
          bottomSpacing="md"
        >
          We ship the nutrient mix to your clients, who access recipes in their
          account. They shop for the other ingredients, cook, then add the mix
          before serving or storing.
        </Text>
        <FiltersActions
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
        />
        {!diyFormulas.length && (
          <EmptyCallout
            formulaType="diy"
            message="We couldn’t find any nutrient mix recipes that match your filters."
          />
        )}
        <div className={styles.formulaCardsContainer}>
          {diyFormulas.map(diyFormula => {
            return (
              <div
                key={diyFormula?.name}
                className={styles.formulaCardContainer}
              >
                <FormulaCard formula={diyFormula} />
              </div>
            );
          })}
        </div>
      </section>
      <section aria-labelledby={treatsHeadingId}>
        <Text
          color="Blueberry3"
          variant={isMobile ? 'heading-22' : 'heading-28'}
          as="h3"
          bold
          id={treatsHeadingId}
          topSpacing={'xxl'}
          bottomSpacing="sm"
        >
          Limited-Ingredient Treats
        </Text>
        <Text
          color="charcoal-3"
          variant="heading-16"
          as="p"
          topSpacing="none"
          bottomSpacing="md"
        >
          Our low-calorie Snap Sticks have precise portioning guidelines that
          can be tailored to each dog&apos;s caloric needs.
        </Text>
        <FiltersActions
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
        />
        {!treatsFormulas.length && (
          <EmptyCallout
            formulaType="treats"
            message="We couldn’t find any treats that match your filters."
          />
        )}
        <div className={styles.formulaCardsContainer}>
          {treatsFormulas.map(treatFormula => {
            return (
              <div
                key={treatFormula?.name}
                className={styles.formulaCardContainer}
              >
                <FormulaCard formula={treatFormula} />
              </div>
            );
          })}
        </div>
      </section>

      <div className={styles.contactContainer}>
        <ContactForAlternativeFormulas formulaType="all" />
      </div>
    </div>
  );
}
