import { useCallback, useEffect, useState } from 'react';
import cognitoTokenStorage from '../utils/cognito-token-storage';
import { getTokenExpiration } from '../utils';
import { browserOnly } from '@farmersdog/utils';
import { useHistory, useRouteMatch } from 'react-router';
import { useClient } from '../services/apollo';
import { PATH_APP, PATH_LOGIN } from '../constants';
import { isBefore } from 'date-fns';

interface useCheckAuthProps {
  isLoading: boolean;
  onLogOut: () => Promise<void>;
}

export const useCheckAuth = ({ isLoading, onLogOut }: useCheckAuthProps) => {
  const [expiration, setExpiration] = useState<Date>();
  const [hasFocus, setHasFocus] = useState(true);
  const isLoggedInPage = useRouteMatch(PATH_APP);
  const apolloClient = useClient();
  const history = useHistory();

  const updateToken = useCallback(() => {
    const token = cognitoTokenStorage.get();
    const expires = getTokenExpiration(token ?? '');
    setExpiration(expires);
  }, [setExpiration]);

  useEffect(() => {
    updateToken();
    return browserOnly((window, document) => {
      const handleVisibilityChange = () => {
        setHasFocus(!document.hidden);
      };

      const handleWindowBlur = () => {
        setHasFocus(false);
      };

      const handleWindowFocus = () => {
        setHasFocus(true);
      };

      // Document event for when the tab losses focus
      document.addEventListener('visibilitychange', handleVisibilityChange);

      // Window events for when browser losses focus
      window.addEventListener('blur', handleWindowBlur);

      window.addEventListener('focus', handleWindowFocus);

      return () => {
        document.removeEventListener(
          'visibilitychange',
          handleVisibilityChange
        );
        window.removeEventListener('blur', handleWindowBlur);
        window.removeEventListener('focus', handleWindowFocus);
      };
    });
  }, [updateToken]);

  const checkToken = useCallback(async () => {
    if (!hasFocus || !expiration || isLoading) return;

    const hasExpired = isBefore(expiration, new Date());

    if (!hasExpired) return;

    if (isLoggedInPage) {
      setExpiration(undefined);
      await onLogOut();

      await apolloClient.clearStore();

      history.push(PATH_LOGIN);
    }
  }, [
    hasFocus,
    expiration,
    isLoading,
    onLogOut,
    isLoggedInPage,
    apolloClient,
    history,
  ]);

  useEffect(() => {
    void checkToken();
  }, [checkToken]);

  return { updateToken };
};
