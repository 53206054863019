import { track } from '../track';
import { set_new_password_with_expired_temp_password } from './eventNames';

/**
 * Send a tracking event for when a user attempts to set up a new password using an expired temporary password after conference sign up
 */

export interface TrackSetNewPasswordWithExpiredTempPasswordArgs {
  email: string;
}

export function trackSetNewPasswordWithExpiredTempPassword({
  email,
}: TrackSetNewPasswordWithExpiredTempPasswordArgs) {
  track({
    eventName: set_new_password_with_expired_temp_password,
    eventProps: {
      email,
    },
  });
}
