import { Text, Button } from '@farmersdog/corgi-x';
import {
  freshFormulaAssets,
  treatsFormulaAssets,
  diyFormulaAssets,
} from './formulaAssetsMap';
import { Picture } from '../Picture';
import { formatDisplayName } from './formatDisplayName';
import { FormulaType } from '../../constants';
import * as pagePaths from '../../constants/pagePaths';

import styles from './FormulaCard.module.css';
import type { ClinicalFormula } from '../../graphql/types';
import { trackClickedSeeFormulasDetails } from '../../analytics/events';
import { Pill } from '../Pill';

const FORMULA_SPECIFICS: Record<string, { title: string }> = {
  fresh: {
    title: 'Fresh',
  },
  treats: {
    title: 'Treats',
  },
  diy: {
    title: 'Nutrient Mix',
  },
};

const MAX_NUM_OF_INDICATIONS_TO_SHOW = 3;

interface FormulaCardProps {
  formula: ClinicalFormula | null;
}
export function FormulaCard({ formula }: FormulaCardProps) {
  if (!formula) {
    return null;
  }
  // We don't show contra indications here
  const clinicalIndications =
    formula.indications?.filter(
      indication => indication?.indicationType === 'clinical'
    ) ?? [];

  const numOfClinicalIndications = clinicalIndications.length ?? 0;

  const assets = getFormulaAssets(formula);

  const type = formula.type;
  const { formattedDisplayName, appendix } = formatDisplayName(formula);
  const encodedName = encodeURIComponent(formula.name ?? '');

  const onSeeDetails = (formulaName: string) => {
    trackClickedSeeFormulasDetails({ formulaName, view: 'tile' });
  };

  const ingredients =
    formula.type === 'treats'
      ? formula.basicInfo?.fullIngredients
      : formula.basicInfo?.mainIngredients;

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.titleAndImage}>
          <div className={styles.title}>
            <Text color="Charcoal2" variant="heading-16" as="h4" bold>
              {FORMULA_SPECIFICS[type].title} Formula
            </Text>
            <Text color="Charcoal3" variant="heading-22" as="h5">
              <b>
                {formattedDisplayName}
                {appendix ? ` for ${appendix}` : ''}
              </b>
            </Text>
          </div>
          <div className={styles.image}>
            {assets && (
              <Picture
                sources={assets.images}
                alt={assets.alt}
                width="100"
                height="100"
              />
            )}
          </div>
        </div>
        {numOfClinicalIndications > 0 && (
          <>
            <Text variant="heading-16">Recommended for:</Text>
            <div className={styles.indications}>
              <ul className={styles.indicationsList}>
                {clinicalIndications
                  .slice(0, MAX_NUM_OF_INDICATIONS_TO_SHOW)
                  .map(indication => {
                    if (!indication) return null;

                    return (
                      <li key={indication.displayName}>
                        <Pill>{indication.displayName}</Pill>
                      </li>
                    );
                  })}
                {numOfClinicalIndications > MAX_NUM_OF_INDICATIONS_TO_SHOW && (
                  <Text color="Charcoal3" variant="heading-12" as="li">
                    & more
                  </Text>
                )}
              </ul>
            </div>
          </>
        )}
        <div className={styles.ingredients}>
          <Text color="Charcoal3" variant="heading-12" bold>
            Ingredients:{' '}
          </Text>
          <Text color="Charcoal3" variant="heading-12">
            {ingredients}
          </Text>
        </div>
      </div>
      <div>
        <Button
          type="link"
          onClick={() => onSeeDetails(formula.name ?? '')}
          to={`${pagePaths.PATH_FORMULAS_DETAILS}?formula=${encodedName}`}
          variant="primary-mini-kale-experimental"
          className={styles.seeDetails}
          aria-label={`See details for ${formattedDisplayName}`}
        >
          See Details
        </Button>
      </div>
    </div>
  );
}

function getFormulaAssetsType(formulaType: string) {
  switch (formulaType) {
    case FormulaType.Fresh:
      return freshFormulaAssets;
    case FormulaType.Treats:
      return treatsFormulaAssets;
    case FormulaType.DIY:
      return diyFormulaAssets;
    default:
      return freshFormulaAssets;
  }
}

function getFormulaAssets(formula: ClinicalFormula) {
  if (!formula.basicInfo || !formula.basicInfo.displayName) {
    return null;
  }

  const formulaAssetsType = getFormulaAssetsType(formula.type);

  const assets =
    formula.type === FormulaType.DIY
      ? formulaAssetsType[FormulaType.DIY]
      : formulaAssetsType[formula.basicInfo.displayName];

  if (!assets) {
    return null;
  }

  return {
    images: assets.images,
    alt: assets.alt,
  };
}
