import { Button, Grid, GridItem, Text } from '@farmersdog/corgi-x';
import { Modal } from '@farmersdog/corgi';
import { useScreenSizeContext } from '../../../context/ScreenSizeContext';
import { Picture } from '../../../components';
import { TopSectionOverviewSources } from '../assets/topSectionOverview/topSectionOverviewSources';
import {
  CORE_TENETS,
  CoreTenet,
  CoreTenetIdentifier,
  type CoreTenetType,
} from '../../../components/CoreTenet/CoreTenet';
import { useCoreTenetModal } from '../../../hooks';
import {
  trackClickedBrowseFormulas,
  trackClickedCoreTenets,
} from '../../../analytics/events';
import * as pagePaths from '../../../constants/pagePaths';

import styles from './TopSection.module.css';
import { BROWSE_FORMULAS_ID } from '../Formulas';

export const MainSection = () => {
  const { isMobile } = useScreenSizeContext();

  const {
    onClickCoreTenet: onOpenCoreTenetModal,
    onCloseTenetModal,
    rootProps,
    labelProps,
    selectedTenet,
  } = useCoreTenetModal();

  const onClickTenet = (coreTenet: CoreTenetType) => {
    trackClickedCoreTenets({ title: coreTenet.title });
    onOpenCoreTenetModal(coreTenet);
  };

  const largeFormulasOverview = (
    <GridItem className={styles.topOverviewImageContainer} xs={12} lg={5}>
      <Picture
        sources={[
          TopSectionOverviewSources.TopSectionOverviewWebp,
          TopSectionOverviewSources.TopSectionOverviewJpg,
        ]}
        alt="Formulas Overview"
      />
    </GridItem>
  );

  return (
    <Grid topSpacing="xxl" bottomSpacing="xl">
      <GridItem xs={12} lg={7}>
        <Grid>
          <GridItem>
            <Text
              variant="heading-40"
              bold
              color="blueberry-3"
              as="h1"
              topSpacing="none"
              className={styles.heading}
              bottomSpacing={isMobile ? 'lg' : 'md'}
            >
              <span className={styles.orangeUnderline}>Browse formulas</span>{' '}
              and indications
            </Text>
          </GridItem>

          <GridItem>
            <Grid rowGap="md">
              <GridItem>
                <Text
                  variant="heading-22"
                  bold
                  color="blueberry-2"
                  bottomSpacing="none"
                  topSpacing="none"
                >
                  All our food is:
                </Text>
              </GridItem>
              {CORE_TENETS.map(coreTenet => {
                const { title, icon } = coreTenet;
                return (
                  <GridItem key={title}>
                    <CoreTenet
                      shouldShowIcon
                      icon={icon}
                      title={title}
                      onClick={() => onClickTenet(coreTenet)}
                      headingLevel="h2"
                    />
                  </GridItem>
                );
              })}
            </Grid>
          </GridItem>
          <Button
            variant="primary-mini-kale-experimental"
            className={styles.browseIndicationsButton}
            type="anchor"
            href={`#${BROWSE_FORMULAS_ID}`}
            onClick={trackClickedBrowseFormulas}
          >
            Start Browsing by Indications
          </Button>
        </Grid>
      </GridItem>

      {!isMobile && largeFormulasOverview}
      {/* @ts-expect-error Types are incorrect */}
      <Modal
        {...rootProps}
        className={isMobile ? styles.tenetModal : undefined}
        close={onCloseTenetModal}
      >
        {selectedTenet && (
          <div className={styles.tenetContainer}>
            <div className={styles.tenetIcon}>{selectedTenet.icon}</div>
            <Text
              bold
              as="h3"
              {...labelProps}
              bottomSpacing="sm"
              color="blueberry-3"
              variant="heading-28"
              className={styles.modalHeading}
            >
              {selectedTenet.title}
            </Text>
            <Text
              as="p"
              bottomSpacing="md"
              color="charcoal-3"
              variant="heading-16"
              className={styles.modalHeading}
            >
              {selectedTenet.description}
            </Text>
            {selectedTenet.id === CoreTenetIdentifier.STAFF && (
              <Button
                onClick={onCloseTenetModal}
                variant="plain-text"
                type="link"
                to={pagePaths.PATH_OUR_TEAM}
                color="kale"
                underline
              >
                Meet Our Team
              </Button>
            )}
          </div>
        )}
      </Modal>
    </Grid>
  );
};
