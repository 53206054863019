import { useHistory } from 'react-router';
import { Logger } from '@farmersdog/logger';
import { Button, Callout } from '@farmersdog/corgi-x';
import { useFormError } from '../FormError';
import { FormFields } from '../FormFields';
import { useFormikWithFocusOnError, useQueryParameters } from '../../hooks';
import { useAuthContext } from '../../context';
import {
  getErrorMessage,
  defaultErrorMessage,
  newPasswordErrorMessages,
  newPasswordErrors,
  errorMessages,
  errorCodes,
} from '../../services/auth/utils/errors';
import { handleUnexpectedAuthError } from '../../services/auth/utils/handleUnexpectedAuthError';
import { isCognitoAuthenticatedUserResult } from '../../context/constants/types';
import * as pagePaths from '../../constants';

import {
  loginSetNewPasswordFormFields as formFields,
  LoginSetNewPasswordFormFields,
} from './loginSetNewPasswordFormFields';
import { loginSetNewPasswordFormInitialValues as initialValues } from './loginSetNewPasswordFormInitialValues';
import { loginSetNewPasswordFormValidationSchema as validationSchema } from './loginSetNewPasswordFormValidationSchema';

import styles from './LoginSetNewPasswordForm.module.css';
import { isErrorWithNameAndMessage } from '../../utils';
import { useState } from 'react';
import { CircleAlert } from '@farmersdog/corgi-x/icons';
import { useResendTempCredentials } from '../../graphql/hooks';

import { isErrorWithMessage } from '../../utils/is-error-with-message';
import {
  trackSetNewPasswordAfterSuccessfulLogin,
  trackSetUpNewPasswordToLogIn,
  trackSetNewPasswordWithExpiredTempPassword,
} from '../../analytics/events';
import type { useFormik } from 'formik';
import { PasswordAlreadySetMessage } from './PasswordAlreadySetMessage/PasswordAlreadySetMessage';
import { handleTempPasswordError } from '../../services/auth/utils/handleTempPasswordError';

interface HandleAuthenticationErrorArgs {
  error: unknown;
  email: string;
  logger: Logger;
  setCustomMessage: (value: string | JSX.Element) => void;
  setFormErrorMessage: (value: string | JSX.Element) => void;
  setFieldValue: ReturnType<typeof useFormik>['setFieldValue'];
  resendTempCredentialsMutation: ReturnType<
    typeof useResendTempCredentials
  >['resendTempCredentialsMutation'];
}

interface HandleExpiredPasswordErrorArgs
  extends Omit<HandleAuthenticationErrorArgs, 'error'> {
  message: string;
}

export function LoginSetNewPasswordForm() {
  const history = useHistory();
  const queryParam = useQueryParameters();
  const [customMessage, setCustomMessage] = useState<
    React.ReactElement | null | string
  >(null);
  const email = queryParam.get('email');

  const { FormError, clearFormErrorMessage, setFormErrorMessage } =
    useFormError();
  const { loginSetNewPassword, logout, isAuthenticated } = useAuthContext();

  const { resendTempCredentialsMutation } = useResendTempCredentials();

  const { isSubmitting, ...formik } = useFormikWithFocusOnError({
    initialValues: { ...initialValues, email: email ?? initialValues.email },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      const logger = new Logger('auth:loginSetNewPassword');

      clearFormErrorMessage();
      setCustomMessage(null);
      try {
        if (!loginSetNewPassword) {
          throw new Error('No login function provided');
        }
        if (!logout) {
          throw new Error('No logout function provided');
        }
        if (isAuthenticated) {
          await logout();
        }
        const result = await loginSetNewPassword({
          email: values.email,
          temporaryPassword: values.temporaryCode,
          newPassword: values.password,
        });

        if (isCognitoAuthenticatedUserResult(result)) {
          trackSetUpNewPasswordToLogIn({ email: values.email });
          history.push(pagePaths.PATH_DASHBOARD);
        } else {
          throw new Error('Login action did not return expected results', {
            cause: result,
          });
        }
      } catch (error: unknown) {
        const isAuthenticationError = await handleAuthenticationError({
          error,
          email: values.email,
          logger,
          setCustomMessage,
          setFormErrorMessage,
          resendTempCredentialsMutation,
          setFieldValue: formik.setFieldValue,
        });
        if (isAuthenticationError) {
          return;
        }

        // default errors
        const message = getErrorMessage(error);
        setFormErrorMessage(message);

        // default error message indicates an unexpected error
        if (message === defaultErrorMessage) {
          handleUnexpectedAuthError({ error, logger });
        }
      }
      setSubmitting(false);
    },
  });

  return (
    <div className={styles.formContainer}>
      <form onSubmit={formik.handleSubmit}>
        <div className={styles.inputSection}>
          {/* @ts-expect-error https://github.com/jaredpalmer/formik/issues/2023 */}
          <FormFields formik={formik} fieldData={formFields} />
        </div>
        <div className={styles.messagesContainer}>
          <FormError />
        </div>
        <div className={styles.buttonContainer}>
          <Button
            disabled={isSubmitting}
            variant="primary-mini-kale-experimental"
            className={styles.submitButton}
            type="submit"
          >
            Log In
          </Button>
        </div>
        {customMessage && (
          <Callout icon={<CircleAlert />} className={styles.errorCallout}>
            {customMessage}
          </Callout>
        )}
      </form>
    </div>
  );
}

async function clearCodeAndPassword(
  setter: ReturnType<typeof useFormik>['setFieldValue']
) {
  await setter(LoginSetNewPasswordFormFields.temporaryCode, '');
  await setter(LoginSetNewPasswordFormFields.password, '');
}

const handleAuthenticationError = async ({
  email: userEmail,
  error,
  logger,
  setCustomMessage,
  setFormErrorMessage,
  resendTempCredentialsMutation,
  setFieldValue,
}: HandleAuthenticationErrorArgs): Promise<boolean> => {
  if (!isErrorWithMessage(error)) {
    return false;
  }
  if (error.message === newPasswordErrors.ALREADY_SET_NEW_PASSWORD) {
    setCustomMessage(PasswordAlreadySetMessage);
    handleTempPasswordError({ context: 'already set new password' });
    await clearCodeAndPassword(setFieldValue);
    trackSetNewPasswordAfterSuccessfulLogin({ email: userEmail });
    return true;
  }

  if (
    isErrorWithNameAndMessage(error) &&
    error.message ===
      newPasswordErrors.NOT_AUTHORIZED_EXPIRED_TEMPORARY_PASSWORD
  ) {
    const errorMessage =
      newPasswordErrorMessages.get(error.message) ?? defaultErrorMessage;

    await handleExpiredPasswordError({
      email: userEmail,
      message: errorMessage,
      logger,
      setCustomMessage,
      setFormErrorMessage,
      setFieldValue,
      resendTempCredentialsMutation,
    });
    return true;
  }
  return false;
};

const handleExpiredPasswordError = async ({
  message,
  email: emailToResendTempCredentials,
  logger,
  setCustomMessage,
  setFormErrorMessage,
  resendTempCredentialsMutation,
  setFieldValue,
}: HandleExpiredPasswordErrorArgs) => {
  trackSetNewPasswordWithExpiredTempPassword({
    email: emailToResendTempCredentials,
  });

  try {
    const result = await resendTempCredentialsMutation({
      variables: {
        email: emailToResendTempCredentials,
      },
    });

    if (result) {
      handleTempPasswordError({
        context: 'temp credentials expired and sent new',
      });
      setCustomMessage(message);
    } else {
      setFormErrorMessage(defaultErrorMessage);
      handleUnexpectedAuthError({ error: defaultErrorMessage, logger });
    }
  } catch (error) {
    // if the user does not exist we need to show the user/password don't match message and log the default error
    if (
      isErrorWithMessage(error) &&
      error.message === newPasswordErrors.VET_DOES_NOT_EXIST_ERROR
    ) {
      logger.error(error.message);
      const errorMessage = errorMessages.get(errorCodes.USER_NOT_FOUND) ?? '';
      setFormErrorMessage(errorMessage);
    } else {
      setFormErrorMessage(defaultErrorMessage);
      handleUnexpectedAuthError({ error: defaultErrorMessage, logger });
    }
  } finally {
    await clearCodeAndPassword(setFieldValue);
  }
};
