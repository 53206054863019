import { FormulaType } from '../../../../constants';
import StethoscopeSvg from '../../../../assets/icons/stethoscope.svg?react';
import ClientComplianceSvg from './clientCompliance.svg?react';
import SafeCookingSvg from './safeCooking.svg?react';
import MicrobialTestingSvg from './microbialTesting.svg?react';

import LimitedIngredientsSvg from './limitedIngredients.svg?react';
import SimpleComplianceSvg from './simpleCompliance.svg?react';
import BoxWithPacksSvg from './boxwithpacks.svg?react';
import SnapSticksSvg from './snapstick.svg?react';

import InstructionsSvg from './instructions.svg?react';
import TherapeuticSvg from './therapeutic.svg?react';

export const data = {
  [FormulaType.DIY]: {
    title: 'Why vets love our nutrient mix',
    items: [
      {
        title: 'Created by Vets',
        icon: StethoscopeSvg,
        description:
          'Our DIY Nutrient Mix is developed by on-staff, board-certified nutritionists to make home-cooked recipes complete and balanced according to AAFCO standards.',
      },
      {
        title: 'Clear Instructions',
        icon: InstructionsSvg,
        description:
          'We coach clients through portioning and key food safety instructions including proper ingredient selection, preparation, and storage.',
      },
      {
        title: 'Therapeutic Indications',
        icon: TherapeuticSvg,
        description:
          'We have a range of recipes to suit dogs with specific disease conditions and nutritional needs.',
      },
    ],
  },
  [FormulaType.Fresh]: {
    title: 'Why vets love our fresh food',
    items: [
      {
        title: 'Created by Vets',
        icon: StethoscopeSvg,
        description:
          'All of our ready-to-eat formulas are developed by on-staff, board-certified nutritionists to be AAFCO complete and balanced. They also develop the calculations that provide tailored calorie targets for every dog who eats our food.',
      },
      {
        title: 'Client Compliance',
        icon: ClientComplianceSvg,
        description:
          'Pre-portioning food eliminates guesswork and promotes compliance. Portions are easily adjustable based on your guidance, and calorie content is clearly labeled on each pack.',
      },
      {
        title: 'Therapeutic Needs',
        icon: SafeCookingSvg,
        description:
          'A range of dietary solutions for specific disease conditions that allows you to sanction and oversee complete and balanced home-cooking for healthy pets.',
      },
      {
        title: 'Microbial Testing',
        icon: MicrobialTestingSvg,
        description:
          'Before any food leaves our manufacturing facilities, we conduct in-house nutritional confirmation and third-party microbial testing that must show appropriate nutritional content and no evidence of microbial contamination.',
      },
    ],
  },
  [FormulaType.Treats]: {
    title: 'Why vets love our treats',
    items: [
      {
        title: 'Limited Ingredients',
        icon: LimitedIngredientsSvg,
        description:
          'Snap Sticks are made with the same single-source proteins as our ready-to-eat formulas. They contain only human-grade meat, vitamin-rich whole fruits, and a little sea salt, which provides some of the electrolytes that dogs need daily.',
      },
      {
        title: 'Simple Compliance',
        icon: SimpleComplianceSvg,
        description:
          'We clearly communicate treat allowances to help clients keep their dogs in healthy body condition. Protein options make accommodating your patients’ preferences and dietary restrictions easy.',
      },
      {
        title: 'Home Delivery',
        icon: BoxWithPacksSvg,
        description:
          'Snap Sticks ship with our ready-to-eat meals for a seamless experience. ',
      },
      {
        title: 'All-Purpose Treating',
        icon: SnapSticksSvg,
        description:
          'Snap Sticks are highly palatable, easy to break into low-calorie bites, shelf-stable, and conveniently packaged in resealable bags.',
      },
    ],
  },
};
