import { Text, Button, Grid } from '@farmersdog/corgi-x';
import VeterinaryGuide from '../../../../static/pdf/VeterinaryGuide.pdf';
import ViewPdfIcon from '../assets/view-pdf.svg?react';
import { trackDownloadableInfo } from '../../../analytics/events';

import styles from './TopSection.module.css';
import { useScreenSizeContext } from '../../../context/ScreenSizeContext';

export const ViewVeterinaryGuide = () => {
  const { isMobile } = useScreenSizeContext();

  return (
    <Grid
      gap="xs"
      bottomSpacing="xxl"
      flexDirection={isMobile ? 'column' : 'row'}
      alignItems={isMobile ? 'flex-start' : 'center'}
      justifyContent="center"
    >
      <Text vSpacing="none" color="charcoal-3" bold as="p">
        Looking for more details about our production methods, science, and
        safety?
      </Text>
      <Button
        variant="plain-text"
        color="kale"
        underline
        type="anchor"
        href={VeterinaryGuide}
        onClick={() =>
          trackDownloadableInfo({ title: '2025 Veterinary Guide' })
        }
        target="_blank"
        className={styles.viewVeterinaryGuideButton}
      >
        View Veterinary Guide
        <ViewPdfIcon />
      </Button>
    </Grid>
  );
};
