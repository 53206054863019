import { useState } from 'react';
import {
  Text,
  PageWidth,
  ActivityIndicator,
  Grid,
  Callout,
} from '@farmersdog/corgi-x';
import { useClinicalFormulas } from '../../graphql/hooks';
import { AccountPage } from '../../components/AccountPage';
import { useScreenSizeContext } from '../../context/ScreenSizeContext';

import { FilteredFormulas } from '../../components/FilteredFormulas/FilteredFormulas';
import type { Indication } from './IndicationsFilter/types';
import type { ClinicalFormula } from '../../graphql/types';
import styles from './Formulas.module.css';
import { TopSection } from './TopSection/TopSection';
import { useFilteredFormulas } from '../../hooks/useFilteredFormulas';
import { usePublicPageFeatures } from '../../abTesting/PublicPageFeatures';
import { Redirect } from 'react-router-dom';
import { PATH_DASHBOARD } from '../../constants';
import { trackIndicationFiltersSelected } from '../../analytics/events';
import {
  IndicationsFilter,
  IndicationsFilterSmallScreenModal,
} from './IndicationsFilter';
import { CircleAlert } from '@farmersdog/corgi-x/icons';
import { indicationFilters } from './IndicationsFilter/utils/indicationCategoriesMap';
import { useEnsureIndicationFiltersMatch } from './IndicationsFilter/utils/hooks/useEnsureIndicationFiltersMatch';

export const BROWSE_FORMULAS_ID = 'browse-by-clinical-indications';

export const Formulas = () => {
  const {
    showFormulasDiscoveryPagesOnly,
    showAllFormulasDiscovery,
    formulasDiscoverySplitLoaded,
  } = usePublicPageFeatures();

  const { data: responseData, error } = useClinicalFormulas();

  const data = responseData?.clinicalFormulas;

  const [selectedFilters, setSelectedFilters] = useState<Indication[]>([]);

  const filteredFormulas = useFilteredFormulas({
    formulas: data?.formulas ? (data.formulas as ClinicalFormula[]) : undefined,
    selectedFilters,
  });

  const onFilter = (filters: Indication[]) => {
    const deselected = selectedFilters
      .reduce<Indication[]>((previous: Indication[], current) => {
        const foundIndication = filters.find(
          indication => indication.displayName === current.displayName
        );
        if (foundIndication) {
          return previous;
        }
        return [...previous, current];
      }, [])
      .map(indication => indication.displayName);

    trackIndicationFiltersSelected({
      selected: filters.map(filter => filter.displayName),
      deselected,
    });
    setSelectedFilters(filters);
  };

  const indicationsToFilter =
    (data?.indications?.filter(
      indication => indication?.indicationType === 'clinical'
    ) as Indication[]) || [];

  useEnsureIndicationFiltersMatch({
    filtersToDisplay: indicationFilters,
    indicationsData: indicationsToFilter.map(
      indication => indication.displayName
    ),
  });

  const { isMobile } = useScreenSizeContext();

  if (!formulasDiscoverySplitLoaded) {
    return (
      <AccountPage fullWidth backgroundOverride="white">
        <Grid alignItems="center" justifyContent="center">
          <ActivityIndicator mode="dark" />
        </Grid>
      </AccountPage>
    );
  }

  if (!showFormulasDiscoveryPagesOnly && !showAllFormulasDiscovery) {
    return <Redirect to={PATH_DASHBOARD} />;
  }

  return (
    <AccountPage fullWidth skipPageWidthConstraint backgroundOverride="white">
      <TopSection />
      <PageWidth>
        <Text
          variant={isMobile ? 'heading-28' : 'heading-40'}
          topSpacing="xxl"
          bottomSpacing="lg"
          bold
          color="Blueberry3"
          as="h2"
          id={BROWSE_FORMULAS_ID}
        >
          Browse by clinical & therapeutic indications
        </Text>
        {error && (
          <Callout
            variant="negative"
            icon={<CircleAlert />}
            className={styles.errorCallout}
          >
            Sorry! Results are not loading at this time, please try again later.
          </Callout>
        )}
        {!error && (
          <>
            {isMobile && (
              <IndicationsFilterSmallScreenModal
                indicationsToFilter={indicationsToFilter}
                selectedFilters={selectedFilters}
                setSelectedFilters={onFilter}
              />
            )}
            {!isMobile && (
              <IndicationsFilter
                indicationsToFilter={indicationsToFilter}
                selectedFilters={selectedFilters}
                setSelectedFilters={onFilter}
              />
            )}

            <div className={styles.filteredFormulas}>
              <FilteredFormulas
                formulas={filteredFormulas}
                setSelectedFilters={onFilter}
                selectedFilters={selectedFilters}
              />
            </div>
          </>
        )}
      </PageWidth>
    </AccountPage>
  );
};
