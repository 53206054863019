import { Grid, Text } from '@farmersdog/corgi-x';
import styles from './Pill.module.css';

interface PillProps {
  step: number;
  title: string;
}
export const Pill = ({ step, title }: PillProps) => {
  return (
    <Grid className={styles.container} spacing="none">
      <Grid className={styles.pill}>
        <Text variant="heading-12" color="White" bold>
          {step}
        </Text>
      </Grid>
      <Text
        variant="heading-12"
        color="Charcoal3"
        bold
        leftSpacing="xs"
        className={styles.title}
      >
        {title}
      </Text>
    </Grid>
  );
};
