import { Grid, GridItem, Text } from '@farmersdog/corgi-x';
import { FormulaType } from '../../../constants';
import styles from './WhyVetsLove.module.css';
import { data } from './assets';
import classNames from 'classnames';

export const WhyVetsLove = ({ formulaType }: { formulaType: FormulaType }) => {
  const formulaInformation = data[formulaType];
  const containerClassName = classNames(styles.container, {
    [styles.diyContainer]: formulaType === FormulaType.DIY,
  });
  return (
    <div>
      <Text
        variant="heading-28"
        color="Blueberry3"
        bold
        as="h2"
        topSpacing="none"
        bottomSpacing="lg"
      >
        {formulaInformation.title}
      </Text>
      <Grid className={containerClassName}>
        {formulaInformation.items.map(({ description, icon: Icon, title }) => (
          <Grid className={styles.section} key={title}>
            <GridItem className={styles.icon}>{<Icon />}</GridItem>
            <GridItem>
              <Text
                variant="heading-22"
                color="Blueberry3"
                bold
                as="h3"
                spacing="none"
              >
                {title}
              </Text>
              <Text as="p" topSpacing="sm" bottomSpacing="none">
                {description}
              </Text>
            </GridItem>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};
