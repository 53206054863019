import { useMemo } from 'react';
import { Item } from './Item';
import styles from './GuaranteedAnalysis.module.css';
import { Grid, Text } from '@farmersdog/corgi-x';
import { transformData } from './utils/transformData';
interface GuaranteedAnalysisProps {
  analysis: string;
}

export const GuaranteedAnalysis = ({ analysis }: GuaranteedAnalysisProps) => {
  const analysisItems = useMemo(() => transformData(analysis), [analysis]);

  return (
    <Grid flexDirection="column">
      <Text variant="heading-22" bold color="Charcoal3" as="h2" spacing="none">
        Guaranteed analysis (as fed)
      </Text>
      <ul className={styles.container}>
        {analysisItems.map(({ description, percentage, type }) => (
          <Item
            key={description}
            description={description}
            percentage={percentage ?? ''}
            type={type ?? ''}
          />
        ))}
      </ul>
    </Grid>
  );
};
