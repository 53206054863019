import { Grid, GridItem, Text } from '@farmersdog/corgi-x';
import type { ResponsiveProps } from '../../../constants';
import { PdfSection } from './PdfSection/PdfSection';
import { ExportArticleSection } from './ExpertArticleSection/ExpertArticleSection';
import { VeterinaryGuideCard } from './VeterinaryGuideCard/VeterinaryGuideCard';
import { ResourcePreview } from './ResourcePreview/ResourcePreview';
import { usePdfRedirects } from '../../../abTesting/PublicPageFeatures/featureHooks/usePdfRedirects';
import { usePublicPageFeatures } from '../../../abTesting/PublicPageFeatures';
import { NutritionGuideCard } from './NutritionGuideCard/NutritionGuideCard';

export const ResourcesContent = ({ isMobile }: ResponsiveProps) => {
  const { isPdfRedirectsActive } = usePdfRedirects();
  const { showAllFormulasDiscovery } = usePublicPageFeatures();

  return (
    <Grid gap={'md'}>
      <GridItem>
        <Text
          as="h2"
          variant={'heading-40'}
          bold
          color="blueberry-3"
          topSpacing="none"
          bottomSpacing="sm"
        >
          Resources and Research
        </Text>
      </GridItem>

      <GridItem>
        <Text
          as="h3"
          variant="heading-28"
          bold
          color="blueberry-3"
          topSpacing="none"
          bottomSpacing="sm"
        >
          From The Farmer&apos;s Dog
        </Text>

        <Text
          as="div"
          variant="heading-16"
          color="charcoal-3"
          bottomSpacing="md"
        >
          All of our science-backed recipes were developed by board-certified
          nutritionists based on decades of research and clinical practices, so
          you can recommend them with confidence.
        </Text>
      </GridItem>

      {isPdfRedirectsActive && <ResourcePreview isMobile={isMobile} />}

      <GridItem>
        {showAllFormulasDiscovery ? (
          <VeterinaryGuideCard isMobile={isMobile} />
        ) : (
          <NutritionGuideCard isMobile={isMobile} />
        )}
      </GridItem>

      <GridItem>
        <PdfSection />
      </GridItem>

      <GridItem>
        <Text
          as="h3"
          variant="heading-28"
          bold
          color="blueberry-3"
          topSpacing="sm"
          bottomSpacing="sm"
        >
          From other experts
        </Text>

        <Text
          as="p"
          variant="heading-16"
          topSpacing="none"
          bottomSpacing="sm"
          color="charcoal-3"
        >
          We know there’s endless information available on pet food and
          health—here are some tools and published nutrition research curated by
          our experts as a great starting place.
        </Text>
      </GridItem>

      <GridItem>
        <ExportArticleSection />
      </GridItem>
    </Grid>
  );
};
