import styles from './HeroImages.module.css';
import { Picture } from '../../../components';
import {
  BEEFSources,
  CHICKEN_OATS_COLLARDSSources,
  CHICKENLSources,
  LOW_FAT_BEEF_AND_SWEET_POTATOSources,
  LOW_FAT_CHICKEN_AND_RICESources,
  LOW_FAT_TILAPIA_AND_SWEET_POTATOSources,
  PORKSources,
  SNPS_CHKNV2Sources,
  SNPS_PORKSources,
  SNPS_TKYV2Sources,
  TILAPIA_RICE_AND_VEGETABLESSources,
  TURKEY_AND_VEGETABLESSources,
  TURKEYSources,
  DIYDefaultSources,
  FreshDefaultSources,
  TreatsDefaultSources,
} from './assets';

const sources = {
  // fresh
  TURKEYK: TURKEYSources,
  TURKEY: TURKEYSources,

  CHICKEN_CHIA: CHICKENLSources,
  CHICKENL: CHICKENLSources,

  CHICKEN_OATS_COLLARDS: CHICKEN_OATS_COLLARDSSources,
  CHICKEN_OATS: CHICKEN_OATS_COLLARDSSources,

  BEEF: BEEFSources,

  PORK: PORKSources,

  // treats
  SNPS_CHKNV2: SNPS_CHKNV2Sources,
  SNPS_PORK: SNPS_PORKSources,
  SNPS_TKYV2: SNPS_TKYV2Sources,

  // diy
  'turkey-and-vegetables': TURKEY_AND_VEGETABLESSources,
  'tilapia-rice-and-vegetables': TILAPIA_RICE_AND_VEGETABLESSources,
  'low-fat-tilapia-&-sweet-potato': LOW_FAT_TILAPIA_AND_SWEET_POTATOSources,
  'low-fat-chicken-&-rice': LOW_FAT_CHICKEN_AND_RICESources,
  'low-fat-beef-&-sweet-potato': LOW_FAT_BEEF_AND_SWEET_POTATOSources,
  // this formula uses the same images as low-fat-beef-&-sweet-potato
  'beef-and-carrots': LOW_FAT_BEEF_AND_SWEET_POTATOSources,
};

const defaultSourcesByType = {
  fresh: FreshDefaultSources,
  diy: DIYDefaultSources,
  treats: TreatsDefaultSources,
};

type Source = typeof sources;

interface HeroImagesProps {
  formula: string;
  displayName: string;
  type: string;
}

export const HeroImages = ({ formula, displayName, type }: HeroImagesProps) => {
  const mappedSources = sources[formula as keyof Source];
  const defaultSources =
    defaultSourcesByType[type as keyof typeof defaultSourcesByType];

  const { top, left, right } = mappedSources || defaultSources;

  const { topImageAltText, leftImageAltText, rightImageAltText } = getAltTexts({
    type,
    displayName,
  });
  return (
    <div className={styles.container}>
      <Picture
        sources={top}
        alt={topImageAltText}
        className={styles.topImage}
      />
      <Picture
        sources={left}
        alt={leftImageAltText}
        className={styles.leftImage}
      />
      <Picture
        sources={right}
        alt={rightImageAltText}
        className={styles.rightImage}
      />
    </div>
  );
};

function getAltTexts({ type, displayName }: Omit<HeroImagesProps, 'formula'>) {
  switch (type) {
    case 'fresh': {
      return {
        topImageAltText: `${displayName} delivered in a box`,
        leftImageAltText: `Bowl of ${displayName}`,
        rightImageAltText: `Bowl of ${displayName}`,
      };
    }
    case 'treats': {
      return {
        topImageAltText: `Package of ${displayName}`,
        leftImageAltText: `${displayName} "snapped" into tidbits`,
        rightImageAltText: `Dog enjoying ${displayName}`,
      };
    }
    case 'diy': {
      return {
        topImageAltText: `Packets of DIY nutrient mix`,
        leftImageAltText: `Box of DIY nutrient mix`,
        rightImageAltText: `${displayName} with DIY nutrient mix`,
      };
    }
    default: {
      return {
        topImageAltText: displayName,
        leftImageAltText: displayName,
        rightImageAltText: displayName,
      };
    }
  }
}
