import { type NutritionData } from '../../../../../graphql/types';

export const transformData = (nutritionData: NutritionData[]) =>
  nutritionData.map(({ displayValue, name, unit }) => {
    let newDisplayValue = displayValue;
    if (unit !== '%') {
      newDisplayValue = newDisplayValue
        .split(',')
        .filter(Boolean)
        .map(display => display.replaceAll(' ', ''))
        .join(', ');
    }
    return [name, `${newDisplayValue}${unit ?? ''}`] as string[];
  });
