import VeterinaryGuideThumbnailPng from '../assets/VeterinaryGuideThumbnail.png';
import VeterinaryGuideThumbnailJpg from '../assets/VeterinaryGuideThumbnail.jpg';
import VeterinaryGuideThumbnailWebp from '../assets/VeterinaryGuideThumbnail.webp';
import veterinaryGuideFile from '../../../../../static/pdf/VeterinaryGuide.pdf';
import type { Pdf } from '../PdfSection/pdfSectionData';

export const veterinaryGuideSectionData: Pdf = {
  title: '2025 Veterinary Guide',
  description:
    'An introduction to The Farmer’s Dog: who we are, how our fresh food works, and the nutrition, safety, and research behind it.',
  link: veterinaryGuideFile,
  thumbnailSources: [
    VeterinaryGuideThumbnailWebp,
    VeterinaryGuideThumbnailJpg,
    VeterinaryGuideThumbnailPng,
  ],
  urlParam: 'veterinary-guide',
};
