import { Accordion, Grid, Text } from '@farmersdog/corgi-x';
import type { NutritionData, ClinicalNutrition } from '../../../graphql/types';
import styles from './MoreDetails.module.css';
import { onToggleTable } from '../utils';
import { DataPanel } from './DataPanel';

interface MoreDetailsProps {
  clinicalNutrition?: ClinicalNutrition | null;
}

export const MoreDetails = ({ clinicalNutrition }: MoreDetailsProps) => {
  const essentialVitamins = clinicalNutrition?.essentialVitamins &&
    clinicalNutrition?.essentialVitamins.length > 0 && (
      <DataPanel
        id="essential-vitamins"
        expanded
        className={styles.panel}
        onToggle={(isExpanded: boolean) =>
          onToggleTable({ isExpanded, title: 'essential vitamins' })
        }
        heading={
          <Text variant="heading-22">
            <strong>Essential Vitamins</strong> per 1000 kcal
          </Text>
        }
        nutritionData={clinicalNutrition.essentialVitamins as NutritionData[]}
      />
    );

  const essentialMinerals = clinicalNutrition?.essentialMinerals &&
    clinicalNutrition?.essentialMinerals.length > 0 && (
      <DataPanel
        id="essential-minerals"
        expanded
        className={styles.panel}
        onToggle={(isExpanded: boolean) =>
          onToggleTable({ isExpanded, title: 'essential minerals' })
        }
        heading={
          <Text variant="heading-22">
            <strong>Essential Minerals</strong> per 1000 kcal
          </Text>
        }
        nutritionData={clinicalNutrition.essentialMinerals as NutritionData[]}
      />
    );

  const essentialFattyAcids = clinicalNutrition?.aminoAcids &&
    clinicalNutrition?.aminoAcids.length > 0 && (
      <DataPanel
        id="essential-fatty-acids"
        expanded
        onToggle={(isExpanded: boolean) =>
          onToggleTable({ isExpanded, title: 'essential fatty acids' })
        }
        heading={
          <Text variant="heading-22">
            <strong>Essential Fatty Acids</strong> per 1000 kcal
          </Text>
        }
        nutritionData={clinicalNutrition.essentialFattyAcids as NutritionData[]}
      />
    );

  const aminoAcids = clinicalNutrition?.aminoAcids &&
    clinicalNutrition?.aminoAcids.length > 0 && (
      <DataPanel
        id="amino-acids"
        expanded
        onToggle={(isExpanded: boolean) =>
          onToggleTable({ isExpanded, title: 'amino acids' })
        }
        heading={
          <Text variant="heading-22">
            <strong>Amino Acids</strong> per 1000 kcal
          </Text>
        }
        nutritionData={clinicalNutrition.aminoAcids as NutritionData[]}
      />
    );

  const hasNutritionValues =
    essentialVitamins || essentialMinerals || essentialFattyAcids || aminoAcids;

  if (!hasNutritionValues) {
    return null;
  }

  return (
    <Grid flexDirection="column">
      <Text
        variant="heading-28"
        color="Blueberry3"
        bold
        as="h2"
        topSpacing="none"
        bottomSpacing="lg"
      >
        Typical nutritionals
      </Text>
      <Accordion className={styles.accordion}>
        <>
          {essentialVitamins}
          {essentialMinerals}
          {essentialFattyAcids}
          {aminoAcids}
        </>
      </Accordion>
    </Grid>
  );
};
